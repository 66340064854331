import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { addRebrandData, onDeleteRebrand } from '../../../Redux/Actions/RebrandingAction';
import SweetAlert from '../../CommonComponent/SweetAlert';

import { PiUpload } from 'react-icons/pi'

const Rebranding = () => {

  const dispatch = useDispatch();
  const rebrandData = useSelector(state => state.rebrand);

  const [rebrand, setRebrand] = useState(rebrandData);
  const [loader, setLoader] = useState(false);
  const [fileData, setFileData] = useState('');
  const [name, setName] = useState('');

  const [sweet, setSweet] = useState({
    enable: false,
    confirmButtonName: "Remove",
  })

  const onCancelDelete = () => {
    setSweet({
      ...sweet,
      enable: false,
      confirmButtonName: "Remove"
    })
  }




  const performDelete = () => {
    let data = { id: rebrand.data.id }
    setSweet({
      ...sweet,
      confirmButtonName: "Removing..."
    })
    dispatch(onDeleteRebrand(data, setSweet))
  }

  const onInputFile = (e) => {
    setFileData(e.target.files[0]);
  }

  const onFormSubmit = (e) => {
    e.preventDefault();

    if (fileData.type === 'image/jpg' || fileData.type === 'image/png' || fileData.type === 'image/jpeg' || fileData === 'image/JPEG') {
      if (fileData.size < 5000000) {
        setLoader(true);
        let formData = new FormData();
        formData.append('name', name);
        formData.append('logo', fileData);
        dispatch(addRebrandData(formData, setLoader, setFileData, setName));

      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Max uploaded size for image is 5MB!',
          confirmButtonColor: "#d03e3e"
        })
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'You have selected invalid file type!',
        confirmButtonColor: "#d03e3e"
      })
    }
  }

  useEffect(() => {
    setRebrand(rebrandData);
  }, [rebrandData])

  return (
    <>
      <div className="upgrade-content">
        <h3 className='pb-4'>Rebranding</h3>
        <form className="" method="post" onSubmit={(e) => onFormSubmit(e)}>

          <div className='upload-box large'>
            <PiUpload />
            <p>
              {fileData?.name ? fileData.name : <> Upload your brand logo (JPG, PNG, JPEG upto 5MB is allowed) <br /> (210x34) Pixels recommended</>}
            </p>
            <input
              type="file"
              id="customFile"
              name="file"
              onChange={(e) => onInputFile(e)}
            />
          </div>

          <div className='common-input-wrap'>
            <label htmlFor="">Brand Name</label>
            <input
              className="common-input"
              type="text"
              name="firstName"
              placeholder="Enter your brand Name"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className='common-input-wrap'>
            <button
              type="submit"
              className="site-link me-1 mb-1"
              disabled={loader}
            >
              <span>{loader ? <> Saving <i className="fa fa-spinner fa-spin mr-2" />  </> : 'Save'}</span>

            </button>
            {
              rebrand.data !== false ?
                <button
                  type="button"
                  onClick={() => setSweet({
                    ...sweet,
                    enable: true
                  })}
                  className="site-link"
                // style={{ display: "inline" }}
                >
                  <span>
                    Remove Rebranding
                  </span>
                </button>
                : ''
            }
          </div>

        </form>
      </div>
      <SweetAlert
        show={sweet.enable}
        message={"Are you sure you want to remove this rebranding?"}
        confirmButtonColor={""}
        cancelButtonColor={""}
        confirmButtonName={sweet.confirmButtonName}
        cancelButtonName={"Cancel"}
        handleClose={onCancelDelete}
        performDelete={performDelete}
      />

    </>
  )

}

export default Rebranding;