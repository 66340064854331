import React, { useState } from 'react';
import TitleBar from '../../title-bar/TitleBar';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import { onCreateImage } from '../../../Redux/Actions/ImageAction';
import { useDispatch } from 'react-redux';
import { fetchData, onFetchPlatform } from '../../../Redux/Actions/CommonActions';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Platform from '../CreateVideo/Platform';
import { useLocation } from 'react-router-dom/dist';
import queryString from "query-string"
import { setAlert } from '../../../Redux/Actions/AlertActions';

const CreateImage = () => {
    
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const type = queryString.parse(location.search).type
    const [loader, setLoader] = useState(false)
    const [country, setCountry] = useState([])
    const [language, setLanguage] = useState([])
    const [platform, setPlatform] = useState(false)
    const [create, setCreate] = useState({
        campaignName: "",
        keyword: "",
        noOfImages: "",
        platform: "",
        imageType: "library",
        country: "",
        language: ""
    })


    const handleChange = (e) => {
        let { name, value, checked } = e.target
        if (name === "imageType") {
            value = checked ? "ai" : "library"
        }
        if (name === "language") {
            let count = language.find(({ name }) => name === value)
            if (count) {
                setCreate({
                    ...create,
                    language: value,
                    langCode: count.code
                })
            }
        } else {
            setCreate({
                ...create,
                [name]: value
            })
        }

    }

    const handleCheck = () => {
        if (create.noOfImages > 5) {
            setCreate({
                ...create,
                noOfImages: 5
            })
            dispatch(setAlert(`You can create maximum 5 ${type}!`, "danger"))
        }

        if (create.noOfImages <= 0) {
            setCreate({
                ...create,
                noOfImages: 1
            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        let data = {
            ...create,
            type: type === "posts" ? "image" : type,

        }
        dispatch(onCreateImage(data, setLoader, navigate, type))
    }

    const fetchImgPlatform = () => {
        dispatch(onFetchPlatform("fetch-image-platform", {}, setPlatform))
    }

    const fetchLanguage = () => {
        dispatch(fetchData("fetch-google-language", {}, setLanguage, false, false, false, "shortDataLg"))
    }
    const fetchCountries = () => {
        dispatch(fetchData("countries", {}, setCountry, false, false, false, "shortDataCt"))
    }

    useEffect(() => {
        fetchImgPlatform()
        // fetchInspiration()
        fetchLanguage()
        fetchCountries()
    }, [])

    return (
        <>
            <TitleBar title="Home" />
            <Header />

            <section className='page-wrap'>
                <div className='container'>
                    <h1 className='page-title text-capitalize'>Create {type}</h1>

                    <form className='cont-box' onSubmit={handleSubmit}>
                        <div className='common-input-wrap mt-0'>
                            <label htmlFor="">Campaign Name</label>
                            <input
                                className='common-input'
                                type="text"
                                name='campaignName'
                                value={create.campaignName}
                                placeholder='Add Campaign Name'
                                onChange={handleChange}
                                required
                            />
                        </div>


                        <div className='common-input-wrap'>
                            <label htmlFor="">Shortly describe what the {type} is about</label>
                            <input
                                className='common-input'
                                type="text"
                                value={create.keyword}
                                name='keyword'
                                placeholder={`Describe The ${type}`}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        {type === "posts" ?
                            <div className='common-input-wrap'>
                                <label htmlFor="">Use AI generated images in the post.</label>
                                <div className='switch-label'>
                                    <label className="switch">
                                        <input
                                            className='input solid'
                                            name="imageType"
                                            type="checkbox"
                                            checked={create.imageType === "library" ? false : true}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <span className="sliderN round"></span>
                                    </label>

                                </div>

                            </div>
                            : ""}

                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Number of <span className='text-capitalize'>{type} </span></label>
                                    <input
                                        className='common-input'
                                        type="number"
                                        name='noOfImages'
                                        min={1}
                                        max={5}
                                        value={create.noOfImages}
                                        onChange={handleChange}
                                        required
                                        onBlur={handleCheck}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Platform</label>
                                    <select
                                        name="platform"
                                        className='common-input'
                                        onChange={handleChange}
                                        value={create.platform}
                                        required
                                    >
                                        <option value="">Select Platform</option>
                                        {platform ?
                                            <>
                                                <Platform
                                                    data={platform.Facebook}
                                                />
                                                <Platform
                                                    data={platform.Instagram}
                                                />
                                                <Platform
                                                    data={platform.LinkedIn}
                                                />
                                                <Platform
                                                    data={platform.Pinterest}
                                                />
                                                <Platform
                                                    data={platform.Twitter}
                                                />

                                            </>
                                            : ""}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Country</label>
                                    <select
                                        className='common-input'
                                        type="text"
                                        value={create.country}
                                        name='country'
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value={""}>Select Country</option>
                                        {country.length > 0 ?
                                            country.map((curElem, index) => {
                                                return (
                                                    <option
                                                        key={index}
                                                        value={curElem.country_name}
                                                    >
                                                        {curElem.country_name}
                                                    </option>
                                                )
                                            })
                                            : ""}
                                    </select>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Languages</label>
                                    <select
                                        className='common-input'
                                        type="text"
                                        value={create.language}
                                        name='language'
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value={""}>Select Language</option>
                                        {language.length > 0 ?
                                            language.map((curElem, index) => {
                                                return (
                                                    <option key={index} value={curElem.name}>{curElem.name}</option>
                                                )
                                            })
                                            : ""}
                                    </select>

                                </div>
                            </div>
                        </div>




                        <div className='client-btn'>
                            <button className='site-link ms-0' type='submit'><span>{loader ? <>Creating <i className="fa fa-spinner fa-spin mx-1" /></> : "Create Post"}</span></button>
                        </div>
                    </form>

                </div>
            </section>
            <Footer />
        </>
    )

}

export default CreateImage;