import { commonAxios } from "../../Global/CommonAxios";
import { setAlert } from "./AlertActions";

export const addSchedulerContent = (data, fetchData, hancleClose, setLoader, setActive, setScheduleStream, auth) => (dispatch, getState) => {
    commonAxios("save-scheduler", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                fetchData()
                hancleClose()
                setScheduleStream(
                    {
                        user_id: auth.user.id,
                        user_client_id: null,
                        content_stream_id: null,
                        social_accounts: [],
                        schedule_date: "",
                        schedule_time: "",
                        hours: "01",
                        minutes: "00",
                        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        re_purpose: 0,
                        schedule_type: ""
                    }
                )
                setActive({
                    first: true,
                    second: false,
                    third: false,
                    forth: false
                })

            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"));
            setLoader(false)
        })
}

export const onUpdateSchedule = (data, handleClose, fetchData, setLoader) => (dispatch, getState) => {
    commonAxios("save-scheduler", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchData()
                handleClose()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
}   