import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { forgetPassword } from '../../../Redux/Actions/AuthActions';
import { useDispatch } from 'react-redux';

import Footer from '../../footer/Footer';

const ForgotPassword = () => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [email, setEmail] = useState("")

    const handleSubmit = (e) => {
        e.preventDefault()
        let data = {
            email: email
        }
        setLoader(true)
        dispatch(forgetPassword(data, setLoader))
    }
    return (
        <>
            <div className="login-wrap">
                <div className="login-head">
                    <div className="login-logo"><img alt='' src={require('../../../assets/images/logo.png')} /></div>
                </div>

                <div className="login-left">
                    <div className='welcome-txt'>
                        <h2>Welcome Back!</h2>
                        <p>Reignite your social media optimisation journey with AI SOCIALS. Your gateway to enhanced engagement and brand impact awaits. Let's get started!</p>
                    </div>
                </div>
                <div className="login-right">
                    <form className="login-right-main" onSubmit={handleSubmit}>
                        <h2>Enter Your Email Address</h2>
                        <div className='common-input-wrap'>
                            <label htmlFor="">Email Address</label>
                            <input
                                className='common-input'
                                type="email"
                                name="email"
                                value={email}
                                placeholder='Email Address'
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className='common-input-wrap'>
                            <button className='site-link full' type='submit'><span>{loader ? <>Submitting <i className="fa fa-spinner fa-spin mx-1" /></> : "Submit"}</span></button>
                        </div>
                        <div className='common-input-wrap'>
                            <p><Link to="/login">Remember Login?</Link></p>
                        </div>
                    </form>
                </div>
            </div>

            <div className='loginFooter'>
                <Footer></Footer>
            </div>
        </>
    )

}

export default ForgotPassword;