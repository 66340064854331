import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';

import TitleBar from '../../title-bar/TitleBar';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import queryString from "query-string"
import { useEffect } from 'react';
import TrainingNav from './TrainingNav';

const SupportArticle = () => {
    let location = useLocation()
    const id = queryString.parse(location.search).id;
    const art_id = queryString.parse(location.search).art_id;
    const articleData = useSelector(state => state.help.article);

    const [filterArticle, setFilterArticle] = useState(false)

    useEffect(() => {
        if (articleData.length > 0) {
            setFilterArticle(articleData.filter(article => article.id === id)[0])
        }
    }, [articleData])

    return (
        <>
            <TitleBar title="Add Account" />
            <Header />

            <section className='page-wrap'>
                <div className='container'>
                    {/* <h1 className='page-title'>Upgrade</h1> */}

                    <Tab.Container id="left-tabs-example" defaultActiveKey="tab-0">

                        <div className='upgrade-container'>
                            <div className='upgrade-left'>
                                <TrainingNav
                                    article={articleData}
                                    articleId={art_id}
                                />
                            </div>
                            <div className='upgrade-right'>
                                <div className='upgrade-right-in '>
                                    <Tab.Content>
                                        {filterArticle ?
                                            filterArticle.items.map((item, index) => {
                                                return (
                                                    item.id === +art_id ?
                                                        <React.Fragment key={index}>
                                                            <div className="tabepaneIn-main" key={index}>
                                                                <div className="tabepaneIn-cont tabepaneIn-cont-alt">
                                                                    <div className="text-dark"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: item.content
                                                                        }}>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                        : ''
                                                )
                                            })
                                            :
                                            <div className="spinner" style={{ width: '100%', height: "900px" }}>
                                                <div className="spinner_tag" style={{ position: "absolute", top: '50%', left: '50%', margin: "0 auto" }}>
                                                    <i className="fa fa-spinner fa-spin mr-2" style={{ color: "#1478d3", fontSize: "30px" }} />
                                                </div>
                                            </div>
                                        }
                                    </Tab.Content>
                                </div>
                            </div>
                        </div>

                    </Tab.Container>
                </div>
            </section>

            <Footer />
        </>
    )

}

export default SupportArticle;