import React, { useState } from 'react';
import TitleBar from '../../title-bar/TitleBar';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import { onCreateMeme } from '../../../Redux/Actions/ImageAction';
import { useDispatch } from 'react-redux';
import { fetchMedia, onFetchPlatform } from '../../../Redux/Actions/CommonActions';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Platform from '../CreateVideo/Platform';
import { FiSearch } from 'react-icons/fi';
import InfiniteScroll from 'react-infinite-scroll-component';
import { GiCheckMark } from 'react-icons/gi';
import { setAlert } from '../../../Redux/Actions/AlertActions';

const CreateMemes = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loader, setLoader] = useState({
        fetch: false,
        submit: false
    })
    const [error, setError] = useState("")
    const [platform, setPlatform] = useState(false)
    const [memesImages, setMemesImages] = useState([])
    const [create, setCreate] = useState({
        campaignName: "",
        keyword: "",
        platform: "",
        image: "",
        noOfImages: "1",
        country: "India",
        langCode: "en",
        language: "English",
        imageType: "library",
        type: "memes"
    })
    const [search, setSearch] = useState({
        keyword: "",
        page: 1
    })

    const handleChange = (name, value) => {
        setCreate({
            ...create,
            [name]: value
        })
    }
    const handleKeyword = (e) => {
        setSearch({
            ...search,
            keyword: e.target.value
        })
    }

    const handleSearch = () => {
        setMemesImages([])
        setError("")
        fetchMemes()
    }

    const fetchMemes = (page = 1) => {
        let data = { ...search }
        data.page = page
        setSearch({
            ...search,
            page: page
        })
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchMedia("search-meme", data, memesImages, setMemesImages, loader, setLoader, setError))
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        let data = {
            ...create,
            keyword: search.keyword
        }
        if (data.image) {
            setLoader({
                ...loader,
                submit: true
            })
            dispatch(onCreateMeme(data, loader, setLoader, navigate))
        } else {
            dispatch(setAlert("Please search meme and select to continue!", "danger"))
        }
    }

    const fetchImgPlatform = () => {
        dispatch(onFetchPlatform("fetch-image-platform", {}, setPlatform))
    }

    useEffect(() => {
        fetchImgPlatform()
    }, [])

    return (
        <>
            <TitleBar title="Home" />
            <Header />

            <section className='page-wrap'>
                <div className='container'>
                    <h1 className='page-title text-capitalize'>Create Memes</h1>

                    <form className='cont-box' onSubmit={handleSubmit}>
                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className='common-input-wrap mt-0'>
                                    <label htmlFor="">Campaign Name</label>
                                    <input
                                        className='common-input'
                                        type="text"
                                        name='campaignName'
                                        value={create.campaignName}
                                        placeholder='Add Campaign Name'
                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='common-input-wrap mt-0'>
                                    <label htmlFor="">Platform</label>
                                    <select
                                        name="platform"
                                        className='common-input'
                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                        value={create.platform}
                                        required
                                    >
                                        <option value="">Select Platform</option>
                                        {platform ?
                                            <>
                                                <Platform
                                                    data={platform.Facebook}
                                                />
                                                <Platform
                                                    data={platform.Instagram}
                                                />
                                                <Platform
                                                    data={platform.LinkedIn}
                                                />
                                                <Platform
                                                    data={platform.Pinterest}
                                                />
                                                <Platform
                                                    data={platform.Twitter}
                                                />

                                            </>
                                            : ""}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex align-items-center ' >
                            <div className='cont-search with-icon my-4' style={{ width: "100%" }}>
                                <span><FiSearch /></span>
                                <input
                                    className='cont-inp'
                                    type="text"
                                    placeholder='Search'
                                    onChange={handleKeyword}
                                />
                            </div>
                            <button className='site-link ms-1' type='button' onClick={handleSearch}><span>Search</span></button>
                        </div>
                        <div>
                            <InfiniteScroll
                                dataLength={memesImages.length}
                                hasMore={true}
                                next={() => fetchMemes(search.page + 1)}
                                scrollableTarget={`memesScroll`}
                                style={{ overflowX: 'hidden' }}
                            >
                                <ul className='meme-list' id={`memesScroll`} style={{ height: 500, overflow: 'auto' }}>
                                    {
                                        memesImages.length > 0 ?
                                            memesImages.map((curElem, index) => {
                                                return (
                                                    curElem.url ?
                                                        <li key={index}>
                                                            <div
                                                                className='list-images-single'
                                                                style={{ cursor: "pointer", textAlign: "center", height: "100%" }}
                                                                onClick={() => handleChange("image", curElem.url)}
                                                            >
                                                                <img alt="" src={curElem.url} />
                                                                {create.image === curElem.url ?
                                                                    <div className='select-tile-style' style={{ zIndex: 9999 }}>
                                                                        <GiCheckMark />
                                                                    </div> : ""}
                                                            </div>
                                                        </li> : null
                                                )
                                            })
                                            : ""
                                    }
                                    
                                    {
                                        loader.fetch ? <div className='fetch-loader-block' style={{ height: "100%", width: "100%" }}><i className="fa fa-spinner fa-spin fetch-loader" /></div> : ""
                                    }

                                </ul>

                                {
                                        error ? <div className='text-center' style={{ fontSize: 15, padding: "25px 0" }}>
                                            {error}
                                        </div> : ""
                                    }
                            </InfiniteScroll>
                        </div >

                        <div className='client-btn'>
                            <button className='site-link ms-0' type='submit'><span>{loader.submit ? <>Creating <i className="fa fa-spinner fa-spin mx-1" /></> : "Create Meme"}</span></button>
                        </div>
                    </form>
                </div>
            </section>
            <Footer />
        </>
    )

}

export default CreateMemes;