import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import TitleBar from '../../title-bar/TitleBar';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';

import FbTemplates from './FbTemplates';
import VipEventCalendar from './VipEventCalendar';
import ClientContract from './ClientContract';
import ResellerLicense from './ResellerLicense';
import VideoCommercial from './VideoCommercial';
import DfyLeadMagnets from './DfyLeadMagnets';
import AgencyWebsite from './AgencyWebsite';
import Rebranding from './Rebranding';
import VideoFlix from './VideoFlix';
import DfyCourse from './DfyCourse';
import WebHosting from './WebHosting';
import SocialMediaAssets from './SocialMediaAssets';
import BusinessFinder from './BusinessFinder';
import DfyWhitelabelContentStreams from './DfyWhitelabelContentStreams';
import AISocialsBonuses from './AISocialsBonuses';
import AISocialsElite from './AISocialsElite';
import AISocialsBundle from './AISocialsBundle';
import AISocialsMegaBundle from './AISocialsMegaBundle';
import AISocialsUnlimited from './AISocialsUnlimited';
import AISocialsUnlimitedLite from './AISocialsUnlimitedLite';
import AISocialsPlatinum from './AISocialsPlatinum';
import AISocialsEnterprise from './AISocialsEnterprise';
import AISocialsRankReel from './AISocialsRankReel';
import AISocialsKoincart from './AISocialsKoincart';
import AISocialsReputor from './AISocialsReputor';
import { useSelector } from 'react-redux';

function Upgrade() {

    const auth = useSelector(state => state.auth)
    const [membership, setMembership] = useState([])

    useEffect(() => {
        if (auth) {
            if (auth.user) {
                setMembership(auth.user.membership.split("__"))
            }
        }
    }, [auth])

    return (
        <>
            <TitleBar title="Add Account" />
            <Header></Header>

            <section className='page-wrap'>
                <div className='container'>
                    <h1 className='page-title'>Upgrade</h1>

                    <Tab.Container id="left-tabs-example" defaultActiveKey="tab-0">

                        <div className='upgrade-container'>
                            <div className='upgrade-left'>
                                <Nav variant="pills" className="flex-column">

                                    {/* <Nav.Item><Nav.Link eventKey="tab-1">FB Templates</Nav.Link></Nav.Item> */}
                                    {/* <Nav.Item><Nav.Link eventKey="tab-2">VIP Event Calendar</Nav.Link></Nav.Item> */}
                                    {membership.includes("enterprise") || membership.includes("fastpass") || membership.includes("bundle") ? <>
                                        <Nav.Item><Nav.Link eventKey="tab-8">WL Rebranding UI</Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link eventKey="tab-22">AISocials Enterprise</Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link eventKey="tab-4">Reseller License</Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link eventKey="tab-6">DFY Lead Magnets</Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link eventKey="tab-15">AISocials Bonuses</Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link eventKey="tab-26">DFY Whitelabel Content Streams</Nav.Link></Nav.Item>


                                    </> : ""}
                                    <Nav.Item><Nav.Link eventKey="tab-13">Business Finder</Nav.Link></Nav.Item>

                                    {membership.includes("premium") || membership.includes("fastpass") || membership.includes("bundle") ?
                                        <>
                                            <Nav.Item><Nav.Link eventKey="tab-16">AISocials Elite (FE)</Nav.Link></Nav.Item>

                                        </> : ""}

                                    {membership.includes("unlimited") || membership.includes("fastpass") || membership.includes("bundle") ?
                                        <>  <Nav.Item><Nav.Link eventKey="tab-19">AISocials Unlimited</Nav.Link></Nav.Item></>
                                        : ""}

                                    {membership.includes("unlimited-lite") || membership.includes("fastpass") || membership.includes("bundle") ?
                                        <>
                                            <Nav.Item><Nav.Link eventKey="tab-20">AISocials Unlimited Lite</Nav.Link></Nav.Item>
                                        </> : ""
                                    }
                                    {membership.includes("platinum") || membership.includes("fastpass") || membership.includes("bundle") ?
                                        <>
                                            <Nav.Item><Nav.Link eventKey="tab-21">AISocials Platinum</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="tab-11">Web Hosting</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="tab-3">Client Contract</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="tab-7">Agency Website</Nav.Link></Nav.Item>

                                        </>
                                        : ""
                                    }
                                    {membership.includes("renkreel") || membership.includes("fastpass") || membership.includes("bundle") ?
                                        <>
                                            <Nav.Item><Nav.Link eventKey="tab-23">AISocials RankReel</Nav.Link></Nav.Item>
                                        </> : ""
                                    }
                                    {membership.includes("reputor") || membership.includes("fastpass") || membership.includes("bundle") ? <>
                                        <Nav.Item><Nav.Link eventKey="tab-25">AISocials Reputor</Nav.Link></Nav.Item>
                                    </> : ""}
                                    {membership.includes("koincart") || membership.includes("fastpass") || membership.includes("bundle") ? <>
                                        <Nav.Item><Nav.Link eventKey="tab-24">AISocials Koincart</Nav.Link></Nav.Item>
                                    </> : ""}

                                    {membership.includes("fastpass") || membership.includes("bundle") ? <>
                                        <Nav.Item><Nav.Link eventKey="tab-17">AISocials Bundle / Fastpass</Nav.Link></Nav.Item>
                                    </> : ""}
                                    {membership.includes("mega_bundle") ? <>
                                        <Nav.Item><Nav.Link eventKey="tab-18">AISocials MegaBundle</Nav.Link></Nav.Item>
                                    </> : ""}
                                    {/* <Nav.Item><Nav.Link eventKey="tab-5">Video Commercial</Nav.Link></Nav.Item> */}
                                    {/* <Nav.Item><Nav.Link eventKey="tab-9">Video Flix</Nav.Link></Nav.Item> */}
                                    {/* <Nav.Item><Nav.Link eventKey="tab-10">DFY Course</Nav.Link></Nav.Item> */}
                                    {/* <Nav.Item><Nav.Link eventKey="tab-12">Social Media Assets</Nav.Link></Nav.Item> */}
                                    {/* <Nav.Item><Nav.Link eventKey="tab-13">DFY Articles</Nav.Link></Nav.Item> */}



                                </Nav>
                            </div>
                            <div className='upgrade-right'>
                                <div className='upgrade-right-in'>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="tab-0" className='firstTab'>
                                            <img alt='' src={require('../../../assets/images/upgrade.png')} />
                                            <h2 className='pt-5'>Welcome!</h2>
                                            <h3>Choose any option to upgrade</h3>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="tab-1"><FbTemplates /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-2"><VipEventCalendar /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-3"><ClientContract /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-4"><ResellerLicense /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-5"><VideoCommercial /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-6"><DfyLeadMagnets /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-7"><AgencyWebsite /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-8"><Rebranding /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-9"><VideoFlix /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-10"><DfyCourse /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-11"><WebHosting /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-12"><SocialMediaAssets /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-13"><BusinessFinder /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-15"><AISocialsBonuses /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-16"><AISocialsElite /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-17"><AISocialsBundle /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-18"><AISocialsMegaBundle /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-19"><AISocialsUnlimited /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-20"><AISocialsUnlimitedLite /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-21"><AISocialsPlatinum /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-22"><AISocialsEnterprise /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-23"><AISocialsRankReel /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-24"><AISocialsKoincart /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-25"><AISocialsReputor /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-26"><DfyWhitelabelContentStreams /></Tab.Pane>


                                    </Tab.Content>
                                </div>
                            </div>
                        </div>

                    </Tab.Container>


                </div>
            </section>

            <Footer></Footer>
        </>
    )

}

export default Upgrade;