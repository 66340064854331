import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import InstagramImage from "../../../assets/images/Instagram.png";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { saveInstagram } from "../../../Redux/Actions/ClientAction";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";


const InstagramConnect = ({ checkAccount, editClient, fetchClientData, fromSocial }) => {

    const dispatch = useDispatch();

    const [details, setDetails] = useState({
        name: false,
        userId: false,
        accessToken: false,
        openPopup: false,
        buttonText: "Connect",
        email: false,
        pages: [],
    });

    const [dropValue, setDropValue] = useState("Select An Account");
    const [selection, setSelection] = useState({
        type: false,
        id: false,
        accessToken: false,
        name: false,
    });

    const closeConnectModel = () => {
        setDetails({ ...details, openPopup: false });
    };

    const handleChange = (e) => {
        const selectedValue = e.target.value.split("___CR___");
        setSelection({
            type: selectedValue[2],
            id: selectedValue[3],
            accessToken: selectedValue[1],
            name: selectedValue[0],
        });
        setDropValue(e.target.value);
    };


    const handleClick = async () => {
        setDetails({
            ...details,
            buttonText: "Connecting...",
        });
        let data = {
            clientId: editClient.id,
            title: selection.name,
            token: selection.accessToken,
            fb_id: selection.id,
            category: selection.type,
            email: details.email,
        };

        try {
            const res = await axios.get(
                `https://graph.facebook.com/v15.0/${selection.id}?fields=instagram_accounts&access_token=${selection.accessToken}`
            );
            data = {
                ...data,
                insta_id: res.data.instagram_accounts.data[0].id
            }
        } catch (err) {
            console.log(err);
        }

        dispatch(saveInstagram(data, fetchClientData));
        setDetails({
            ...details,
            openPopup: false,
        });
    };

    const responseInstagram = async (response) => {
        try {
            const res = await axios.get(
                `https://graph.facebook.com/me/accounts?type=page&access_token=${response.accessToken}`
            );
            setDetails({
                ...details,
                name: response.name,
                userId: response.userID,
                openPopup: true,
                buttonText: "Connect",
                accessToken: response.accessToken,
                pages: res.data.data,
            });
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <FacebookLogin
                appId="780210132546000" //APP ID NOT CREATED YET
                fields="name,email"
                scope="pages_manage_posts,pages_show_list,publish_video,instagram_basic,instagram_content_publish,publish_to_groups,pages_read_engagement,pages_read_user_content,public_profile,pages_manage_engagement"
                callback={(e) => responseInstagram(e)}
                render={renderProps => (
                    <span style={{ cursor: "pointer" }} className={`${checkAccount === false ? 'hide-button' : ''}`} onClick={renderProps.onClick}>
                        {fromSocial ?
                            <a className='site-link integr_card mt-4' style={{ padding: "2px 20px", fontSize: "14px", background: '#4fc5e1', borderColor: "#4fc5e1" }} > <span> Connect</span> </a>
                            :
                            <img className={`${checkAccount === false ? 'client-img-opacity' : ''}`} src={InstagramImage} alt="instagram-img" />
                        }
                    </span>
                )}
            />

            <Modal className='theme-modal xl' show={details.openPopup} onHide={closeConnectModel} centered scrollable >
                <Modal.Body>
                    <div className='info-tab'>
                        <h3>Select an account</h3>
                        <div className="alert alert-danger" role="alert">
                            Please note that, you can only post into Instagram Business account connected to a Facebook Page. And you should have the administrator rights for that Facebook page.  If a Facebook Page of yours is not listed which means you might not have the administrator rights for that Facebook Page.
                        </div>
                        <div className='row'>
                            <div className='common-input-wrap'>
                                <select
                                    className='common-input'
                                    value={dropValue}
                                    onChange={handleChange}
                                >
                                    <option value="Select an Account" >Select An Account</option>
                                    <optgroup label="Your Facebook Pages">
                                        {details.pages.map((page) => (
                                            <option
                                                key={page.id}
                                                value={
                                                    page.name +
                                                    "___CR___" +
                                                    page.access_token +
                                                    "___CR___page___CR___" +
                                                    page.id
                                                }
                                            >
                                                {page.name}
                                            </option>
                                        ))}
                                    </optgroup>
                                </select>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='site-link bdr' type="button" onClick={(e) => closeConnectModel(e)}><span>Cancel</span></button>
                    <button className='site-link bdr' type="button" onClick={handleClick} disabled={dropValue === "Select An Account" ? true : false}>
                        <span> {details.buttonText}</span>
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default InstagramConnect;