import React from 'react'
import ReportModal from './ReportModal';
import { useState } from 'react';

const ReportClientCard = ({ curElem }) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false)

    return (
        <>
            <div className='col-sm-6 col-xl-4'>
                <div className='client-single'>
                    <div className='client-imgN'><img alt='' src={curElem.profile} /></div>
                    <h3>{curElem.name}</h3>
                    <div className='client-btn text-center'>
                        <button className='site-link' onClick={() => setShow(true)}><span>Create Report</span></button>
                    </div>
                </div>
            </div>
            <ReportModal
                id={curElem.id}
                show={show}
                handleClose={handleClose}
            />
        </>
    )
}

export default ReportClientCard
