import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../../../../../Redux/Actions/CommonActions';
import { onUpdateTemplate } from '../../../../../Redux/Actions/VideoActions';
import { GiCheckMark } from 'react-icons/gi';
import { useNavigate } from 'react-router-dom';
import Variations from './Variations';

const Templates = ({ setPageLoader, setPreLoader }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const campaign = useSelector(state => state.video.campaign)
    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
    const [data, setData] = useState([])
    const [loader, setLoader] = useState({ fetch: false })

    const fetchTemp = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchData("fetch-template", {}, setData, loader, setLoader))
    }

    const handleSelectTemplate = (id) => {
        if (campaign.templateId !== id) {
            let data = {
                videoId: campaign.id,
                newTemplateId: id,
                dimension: campaign.dimension
            }
            dispatch(onUpdateTemplate(data, navigate, setPageLoader, campaign.cId))
        }
    }


    useEffect(() => {
        fetchTemp()
    }, [])

    return (
        <div className='list-images'>
            <h6 className='mb-1'>Templates</h6>
            <ul style={{ height: 400, overflow: 'auto' }}>
                {
                    data.length > 0 ?
                        data.map((curElem, index) => {
                            let imageData = campaign.dimension === "1920x1080" ? curElem.previewImage : campaign.dimension === "1080x1080" ? curElem.previewImage : campaign.dimension === "1080x1920" ? curElem.preview_vertical : ""
                            return (
                                <li key={index}>
                                    <div
                                        className='list-images-single'
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleSelectTemplate(curElem.id)}
                                    >
                                        <img
                                            alt=""
                                            src={imageData}
                                        />
                                        {+campaign.templateId === +curElem.id ?
                                            <div className='select-tile-style'>
                                                <GiCheckMark />
                                            </div>
                                            : ''
                                        }
                                    </div>


                                </li>
                            )
                        })
                        :
                        loader.fetch ? <div className='fetch-loader-block' style={{ height: "100%", width: "100%" }}><i className="fa fa-spinner fa-spin fetch-loader" /></div> : ""

                }


            </ul>
            <h6 className='mb-1 pt-3'>Variations</h6>

            <Variations setPreLoader={setPreLoader} />
        </div >
    );
}

export default Templates;
