import React, { useEffect, useState } from 'react';
import TitleBar from '../../title-bar/TitleBar';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import ScheduleModal from './ScheduleModal';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { fetchData } from '../../../Redux/Actions/CommonActions';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';


const Schedule = () => {
    let today = new Date();
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [show, setShow] = useState(false);
    const [events, setEvents] = useState([]);
    const [clients, setClients] = useState([]);
    const [stream, setStream] = useState([]);

    const [choosenCalender, setChoosenCalender] = useState({
        date: '',
        time: '',
        schedule_type: ''
    });
    const handleClose = () => setShow(false);

    const handleShow = (e) => {
        if (+auth.user.is_client_account !== 1) {
            let temp_type = 'monthly';
            if (e.view.type === 'timeGridWeek') {
                temp_type = 'weekly';
            } else if (e.view.type === 'timeGridDay') {
                temp_type = 'daily';
            }

            let postDate = e.dateStr.split("T")[0];

            if (Date.parse(e.date.toDateString()) >= Date.parse(today.toDateString())) {
                setShow(true)
                setChoosenCalender({
                    ...choosenCalender,
                    date: postDate,
                    time: "",
                    schedule_type: temp_type
                })
            }
        }
    }

    const handleDayRender = (obj) => {
        return (
            <>
                {obj.isPast === false ? <>
                    <i className="fa-custom fas fa-plus-circle"> </i>
                    {obj.dayNumberText}</> : obj.dayNumberText
                }
            </>
        )
    }

    const fetchClients = () => {
        dispatch(fetchData("fetch-app-client", {}, setClients))
    }

    const fetchStreams = () => {
        dispatch(fetchData("fetch-all-content-stream", {}, setStream))
    }

    const fetchCalender = () => {
        dispatch(fetchData("fetch-schedulers", {}, setEvents))
    }

    useEffect(() => {
        fetchClients()
        fetchStreams()
        fetchCalender()
    }, [])

    return (
        <>
            <TitleBar title="Schedule" />
            <Header />

            <section className='page-wrap'>
                <div className='container'>
                    <div className='cont-box text-end p-1 mt-2' style={{ background: "none" }}>
                        <Link className='site-link' to="/schedule/schedule-content"><span>View Schedules</span></Link>
                    </div>
                </div>

                <div className='container'>
                    <div className='cont-box'>
                        <div className="Calendar-outer p_space">
                            <FullCalendar
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                initialView="dayGridMonth"
                                selectable={false}
                                eventTimeFormat={{
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    meridiem: "2-digit",
                                    meridiem: "capitalize",
                                    hour12: true
                                }}
                                headerToolbar={{
                                    left: "prev,next today",
                                    center: "title",
                                    right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek"
                                }}
                                dateClick={(e) => handleShow(e)}
                                dayCellContent={handleDayRender}
                                events={events.map((curElem) => {
                                    let d = curElem.scheduleDate.split(" ")[0]
                                    let h = +curElem.hours > 9 ? curElem.hours : '0' + `${+curElem.hours}`
                                    let m = +curElem.minutes > 9 ? curElem.minutes : '0' + `${curElem.minutes}`

                                    return {
                                        title: curElem.contentStream.title,
                                        date: `${d}T${h}:${m}`
                                    }
                                })
                                }
                            />
                        </div>
                    </div>
                </div>
            </section>

            <ScheduleModal
                show={show}
                handleClose={handleClose}
                clients={clients}
                stream={stream}
                choosenCalender={choosenCalender}
                fetchCalender={fetchCalender}
            />

            <Footer />
        </>
    )

}

export default Schedule;