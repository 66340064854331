import { commonAxios } from "../../Global/CommonAxios";
import { setAlert } from "./AlertActions";

export const addStream = (data, stream, setStream, loader, setLoader, navigate) => (dispatch, getState) => {
    commonAxios("content-stream", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/content-stream/edit-stream?id=${res.data.id}`)
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                create: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                create: false
            })
            console.log(err)
        })
}

export const onFetchEditStream = (data, setStream) => (dispatch, getState) => {
    commonAxios("fetch-content-stream", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setStream(res.data)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            // setLoader({
            //     ...loader,
            //     fetch: false
            // })
        }).catch((err) => {
            // setLoader({
            //     ...loader,
            //     fetch: false
            // })
            console.log(err)
        })
}

export const onUploadStream = (data, uploadStatus, setUploadStatus, successFile, setSuccessFile, setUploadImageCount, files, i, fetchStream) => (dispatch, getState) => {
    setUploadImageCount(files.length);
    const options = {
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            /** set file size, percentage or name in local state */
            setUploadStatus({
                ...uploadStatus,
                percentage: percent,
                total_size: total,
                uploaded_size: loaded,
                file_name: files[i].name,
            });

        },
        headers: {
            'Authorization': getState().auth.token
        }
    }
    commonAxios("stream", data, dispatch, false, options)
        .then((res) => {
            if (res.status) {
                let array = successFile.files;
                array.push(res.data);
                setSuccessFile({ ...successFile, files: array });
                setUploadStatus({ ...uploadStatus, percentage: 0, file_name: null });
                if (+i === +(files.length) - 1) {
                    fetchStream()
                    dispatch(setAlert(res.msg, "success"))
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
                setUploadStatus({
                    ...uploadStatus,
                    percentage: 0
                });
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setUploadStatus({
                ...uploadStatus,
                percentage: 0
            });
            console.log(err)
        })
}

export const onUpdateStream = (data, fetchData, handleClose, setLoader) => (dispatch, getState) => {
    commonAxios("update-stream", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
                handleClose()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
            console.log(err)
        })
}

export const onTextStream = (data, fetchData, setLoader) => (dispatch, getState) => {
    commonAxios("text-stream", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchData()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
            console.log(err)
        })
}

export const updateFileSequence = (data) => (dispatch, getState) => {
    commonAxios("stream-sequence", data, dispatch, getState().auth.token)
        .then((res) => {

        }).catch((err) => {
            console.log(err)
        })

}