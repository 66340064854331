import React, { useState } from 'react';

function FbTemplates() {

    return(
        <>
          FbTemplates
        </>
    )

}

export default FbTemplates;