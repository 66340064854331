import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const ReportModal = ({ id, show, handleClose }) => {

    const navigate = useNavigate()
    const today = new Date();
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const [years, setYears] = useState('');
    const [clientReport, setClientReport] = useState({
        id: null,
        month: today.getMonth() >= 9 ? (today.getMonth() + 1) : '0' + (today.getMonth() + 1),
        year: today.getFullYear()
    })

    const generateReport = (e) => {
        e.preventDefault();
        navigate(`/report/create-report?id=${clientReport.id}&month=${clientReport.month}&year=${clientReport.year}`)
    }

    const onInputChange = (e) => {
        setClientReport({ ...clientReport, [e.target.name]: e.target.value });
    }

    useEffect(() => {
        let years = [];
        for (let i = 2021; i <= today.getFullYear(); i++) {
            years = years.concat(i);
        }
        setYears(years);
    }, []);

    useEffect(() => {
        setClientReport({ ...clientReport, id: id });
    }, [id]);

    return (
        <Modal className='theme-modal xl' show={show} onHide={handleClose} centered scrollable>
            <form onSubmit={generateReport}>
                <Modal.Body>
                    <div className='info-tab'>
                        <h3>Select Year & Month</h3>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Select Year</label>
                                    <select
                                        className='common-input'
                                        name="year"
                                        value={clientReport.year}
                                        onChange={(e) => onInputChange(e)}
                                        required
                                    >
                                        <option value="">Select Year</option>
                                        {
                                            years.length > 0 ?
                                                years.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item} selected={item === today.getFullYear()} >{item}</option>
                                                    )
                                                })
                                                : ''
                                        }
                                    </select>


                                </div>
                            </div>
                            <div className='col-sm-12'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Select Month</label>
                                    <select
                                        className='common-input'
                                        name="month"
                                        value={clientReport.month}
                                        onChange={(e) => onInputChange(e)}
                                        required
                                    >
                                        <option value="">Select Month</option>
                                        {
                                            months.map((item, index) => {
                                                return (
                                                    <>
                                                        <option
                                                            selected={today.getMonth() === index}
                                                            value={`${index >= 9 ? index + 1 : '0' + (index + 1)}`}>
                                                            {item}
                                                        </option>
                                                    </>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className='site-link bdr' onClick={handleClose}><span>Cancel</span></button>
                    <button type="submit" className='site-link'><span>Generate Report</span></button>
                </Modal.Footer>
            </form >
        </Modal>
    )
}

export default ReportModal
