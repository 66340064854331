import React, { useEffect, useState } from 'react';
import TitleBar from '../../title-bar/TitleBar';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from "query-string"
import { onEditScript, onFetchScript } from '../../../Redux/Actions/VideoActions';
const ContentSet = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const id = queryString.parse(location.search).id
    const [pageLoader, setPageLoader] = useState(true)
    const [loader, setLoader] = useState({
        save: false,
        generate: false
    })
    const [videoData, setVideoData] = useState({
        script: {
            script: ""
        },
        name: ""
    })

    const fetchScriptData = () => {
        let data = {
            videoId: id
        }
        dispatch(onFetchScript(data, setVideoData, setPageLoader))
    }

    const handleSubmit = (e, type) => {
        if (e) {
            e.preventDefault()
        }
        if (videoData.script.script) {
            let data = {
                cId: id,
                type: type,
                script: videoData.script.script
            }
            if (type === "save") {
                setLoader({
                    ...loader,
                    save: true
                })
            } else {
                data.type = ""
                setLoader({
                    ...loader,
                    generate: true
                })
            }
            dispatch(onEditScript(data, loader, setLoader, navigate))
        }
    }
    const handleChange = (e) => {
        setVideoData({
            ...videoData,
            script: {
                ...videoData.script,
                script: e.target.value
            }
        })
    }

    useEffect(() => {
        if (id) {
            fetchScriptData()
        }
    }, [id])
    return (
        !pageLoader ?
            <>
                <TitleBar title="Home" />
                < Header />

                <section className='page-wrap'>
                    <div className='container'>
                        <h1 className='page-title'>{videoData.name}</h1>
                        <form className='cont-box' onSubmit={handleSubmit}>

                            <div className='common-input-wrap mt-0'>
                                {/* <label htmlFor="">To separate slides use <span>(. Dot)</span></label> */}
                                <textarea
                                    value={videoData.script.script}
                                    onChange={(e) => handleChange(e)}
                                    className='common-input large'
                                />
                                {/* <label className='text-end pt-3' htmlFor="">Slides:1</label> */}
                            </div>
                            <div className='client-btn'>
                                <button className='site-link bdr ms-0' type='button' onClick={() => handleSubmit(false, "save")}><span>{loader.save ? <>Saving <i className="fa fa-spinner fa-spin mx-1" /></> : "Save Script"}</span></button>
                                <a href="/video-editor"><button className='site-link ms-0' type='submit'><span>{loader.generate ? <>Converting <i className="fa fa-spinner fa-spin mx-1" /></> : "Convert to Video"}</span></button></a>
                            </div>
                        </form>

                    </div>
                </section>

                <Footer />
            </> :

            <div className="loader-sec">
                <div className="loader" />
            </div>
    )

}

export default ContentSet;