import React from 'react'
import { useState } from 'react';
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { onCreateAgency, onUpdateAgency } from '../../../Redux/Actions/ReportAction';
import defaultImage from '../../../assets/images/default.png'
import { BsPlusLg } from 'react-icons/bs';
import Swal from 'sweetalert2';
import { onUploadMedia } from '../../../Redux/Actions/CommonActions';

const AgencyModal = ({ show, handleClose, state, setState, fetchAgency }) => {

    const dispatch = useDispatch();
    const [percent, setPercent] = useState(0)
    const [loader, setLoader] = useState({
        upload: false,
        submit: false,
    });

    const getProfileUrl = (url, type) => {
        setState({ ...state, logo: url })
    }

    const handleChangeProfile = (e) => {
        let allowedExt = ['image/png', 'image/jpg', 'image/jpeg']
        const formData = new FormData()
        if (allowedExt.includes(e.target.files[0].type)) {
            if (e.target.files[0].size < 5000000) {
                formData.append('upload_type', "images")
                formData.append('file', e.target.files[0])
                dispatch(onUploadMedia(formData, getProfileUrl, loader, setLoader, setPercent, true))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Max allowed size for images is 5MB!',
                    confirmButtonColor: "#618FCA"
                })
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You have selected invalid file type!',
                confirmButtonColor: "#618FCA"
            })
        }
    }

    const onInputChange = (e) => {
        const { name, value } = e.target
        setState({ ...state, [name]: value });
    }

    const handleSubmit = (e) => {
        setLoader({ ...loader, submit: true })
        e.preventDefault()
        let data = { ...state }
        if (state.id) {
            dispatch(onUpdateAgency(data, loader, setLoader, fetchAgency, handleClose))
        } else {
            delete data.id
            dispatch(onCreateAgency(data, loader, setLoader, fetchAgency, handleClose))
        }
    }

    return (
        <Modal className='theme-modal xl' show={show} onHide={handleClose} centered scrollable>
            <form onSubmit={handleSubmit} style={{ maxHeight: "830px", overflowY: "auto" }}>
                <Modal.Body>
                    <div className='info-tab'>
                        <h3>Create Agency</h3>
                        <div className='profilePic'>
                            {loader.upload ?
                                <div className='profileLoader'>
                                    <i className="fa fa-spinner fa-spin mx-1" style={{ fontSize: "23px", color: "#618fca" }} />
                                </div>
                                :
                                <>
                                    <img alt='' src={state.logo ? state.logo : defaultImage} />
                                    <div className='profilePic-up'>
                                        <BsPlusLg />
                                        <input type="file" name='logo' onChange={handleChangeProfile} />
                                    </div>
                                </>
                            }
                        </div>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Name</label>
                                    <input
                                        className='common-input'
                                        type="text"
                                        name="name" onChange={(e) => onInputChange(e)}
                                        value={state.name}
                                        placeholder="Enter Agency Name" required
                                    />
                                </div>
                            </div>
                            <div className='col-sm-12'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Email Address</label>
                                    <input
                                        className='common-input'
                                        type="email"
                                        name="email" onChange={(e) => onInputChange(e)}
                                        value={state.email}
                                        placeholder="Enter Email Address" required
                                    />
                                </div>
                            </div>
                            <div className='col-sm-12'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Phone</label>
                                    <input
                                        className='common-input'
                                        type="number"
                                        name="phone" onChange={(e) => onInputChange(e)}
                                        value={state.phone}
                                        placeholder="Enter phone Number" required
                                    />
                                </div>
                            </div>
                            <div className='col-sm-12'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Website</label>
                                    <input
                                        className='common-input'
                                        type="text"
                                        name="siteUrl" onChange={(e) => onInputChange(e)}
                                        value={state.siteUrl}
                                        placeholder="Enter Your Website"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className='site-link bdr' onClick={handleClose}><span>Cancel</span></button>
                    {
                        state.id ?
                            <button type='submit' className='site-link ms-0'><span>{loader.submit ? <>Updating <i className="fa fa-spinner fa-spin mx-1" /></> : "Update"}</span></button>
                            :
                            <button type='submit' className='site-link ms-0'><span>{loader.submit ? <>Creating <i className="fa fa-spinner fa-spin mx-1" /></> : "Create"}</span></button>
                    }
                </Modal.Footer>
            </form >
        </Modal>
    )
}

export default AgencyModal
