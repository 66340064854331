import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { TbTextSize } from 'react-icons/tb';
import { PiLinkBold, PiSelectionBackgroundFill } from 'react-icons/pi';
import { MdAnimation, MdOutlineAudiotrack, MdPermMedia, MdRecordVoiceOver } from 'react-icons/md';
import { BsBookHalf, BsFillCreditCard2FrontFill } from 'react-icons/bs';
import TitleBar from '../../../title-bar/TitleBar';
import Header from '../../../header/Header';
import Script from './Scripts/Script';
import Text from './Text/Text';
import Media from './Media/Media';
import Voiceover from './Vocieover/Voiceover';
import Music from './Music/Music';
import Transition from './Transition/Transition';
import Animation from './Animation/Animation';
import Settings from './Settings/Settings';
import Credits from './Credits/Credits';
import Background from './Background/Background';
import { FiSettings } from 'react-icons/fi';
import Preview from './Preview/Preview';
import { HiTemplate } from 'react-icons/hi';
import Templates from './Templates/Templates';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { onFetchVideo, onRenderVideo, onUnmountEditor } from '../../../../Redux/Actions/VideoActions';
import { useSelector } from 'react-redux';
import Remotion from './Remotion/Remotion';

const Editor = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = queryString.parse(location.search)
    const campaign = useSelector(state => state.video.campaign)
    const [pageLoader, setPageLoader] = useState(true)
    const [preLoader, setPreLoader] = useState(false)
    const [loader, setLoader] = useState(false)
    const [showPreview, setShowPreview] = useState(false)
    const [deactiveButton, setDeactiveButton] = useState(false)

    const handleClosePreviewModal = () => setShowPreview(false)


    const fetchVideos = () => {
        let data = {
            cId: id
        }
        dispatch(onFetchVideo(data, setPageLoader, navigate))
    }

    const handleRenderVideo = () => {
        let data = {
            id: id
        }
        setLoader(true)
        dispatch(onRenderVideo(data, navigate, setLoader));
    };

    useEffect(() => {
        if (campaign) {
            setPageLoader(false)
        }
    }, [campaign])

    useEffect(() => {
        if (id) {
            fetchVideos()
        }
    }, [id])

    useEffect(() => {
        return () => {
            dispatch(onUnmountEditor())
        }
    }, [])

    return (
        !pageLoader ?
            <>
                <TitleBar title="Editor" />
                <Header />

                <section className='page-wrap'>
                    <div className='title-block'>

                    </div>

                    <div className='title-block'>
                        <div className='container'>
                            <div className='title-block-in'>
                                <h1 className='page-title'>Create Videos</h1>
                                <div className='edit-title' style={{ background: "none" }}>
                                    <button
                                        className='site-link bdr me-1'
                                        onClick={() => setShowPreview(true)}
                                        style={{ background: deactiveButton ? "gray" : "" }}
                                        disabled={deactiveButton}
                                    >
                                        <span>Preview</span>
                                    </button>
                                    <button
                                        className='site-link ms-0'
                                        onClick={handleRenderVideo}
                                        style={{ background: deactiveButton ? "gray" : "" }}
                                        disabled={deactiveButton}
                                    >
                                        <span>{loader ? <>Rendering <i className="fa fa-spinner fa-spin mx-1" /></> : "Render"}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className='container'>
                            <div className='editor-wrap'>
                                <div className='editor-left'>
                                    <div className='editor-left-in'>
                                        <div className='editor-tab'>
                                            <Tab.Container id="left-tabs-example" defaultActiveKey="script">
                                                <Nav variant="tab-side-nav" className="flex-column editor-tab-left">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="script"><span><BsBookHalf /></span> Script</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="text"><span><TbTextSize /></span> Text</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="bg"><span><PiSelectionBackgroundFill /></span> Background</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="media"><span><MdPermMedia /></span> Media</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="voice"><span><MdRecordVoiceOver /></span> Voiceover</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="music"><span><MdOutlineAudiotrack /></span> Music</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="transition"><span><PiLinkBold /></span> Transition</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="animation"><span><MdAnimation /></span> Animation</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="template"><span><HiTemplate /></span> Templates</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="setting"><span><FiSettings /></span> Settings</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="credit"><span><BsFillCreditCard2FrontFill /></span> Credits</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>

                                                <Tab.Content>
                                                    <Tab.Pane eventKey="script"><Script /></Tab.Pane>
                                                    <Tab.Pane eventKey="text"><Text /></Tab.Pane>
                                                    <Tab.Pane eventKey="bg"><Background /></Tab.Pane>
                                                    <Tab.Pane eventKey="media"><Media /></Tab.Pane>
                                                    <Tab.Pane eventKey="voice">
                                                        <Voiceover
                                                            setDeactiveButton={setDeactiveButton}
                                                        />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="music"><Music /></Tab.Pane>
                                                    <Tab.Pane eventKey="transition"><Transition /></Tab.Pane>
                                                    <Tab.Pane eventKey="animation"><Animation /></Tab.Pane>
                                                    <Tab.Pane eventKey="template">
                                                        <Templates
                                                            setPreLoader={setPreLoader}
                                                            setPageLoader={setPageLoader}
                                                        />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="setting"><Settings /></Tab.Pane>
                                                    <Tab.Pane eventKey="credit"><Credits /></Tab.Pane>
                                                </Tab.Content>
                                            </Tab.Container>
                                        </div>
                                    </div>
                                </div>
                                <div className='editor-right'>
                                    <div className='editor-area'>
                                        <Preview
                                            preLoader={preLoader}
                                        />
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </section>

                <Remotion
                    show={showPreview}
                    handleClose={handleClosePreviewModal}
                />

                {/* <Footer /> */}
            </>
            :
            <div className="loader-sec">
                <div className="loader" />
            </div>
    )

}

export default Editor;