import React from 'react'

const Platform = ({ data }) => {
    return (
        data.length > 0 ?
            <>
                <optgroup label={data[0].group_name}>
                    {data.map((curElem) => {
                        return (
                            <option value={curElem.id}>{curElem.name}</option>
                        )
                    })
                    }
                </optgroup>
            </>
            : ""


    )
}

export default Platform