import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onFetchLang, onFetchVoice, onGenerateTts, onGenerateTtsAll, updateTtsText, updateTtsUrl } from '../../../../../Redux/Actions/VideoActions';
import Swal from 'sweetalert2';

let ttsAudio = false;
const TextToSpeech = ({ setDeactiveButton }) => {
    const dispatch = useDispatch()
    const campaign = useSelector(state => state.video.campaign)
    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
    const [addAllText, setAddAllText] = useState(false)
    const [pollyLanguages, setPollyLanguages] = useState({ data: [] });
    const [pollyVoices, setPollyVoices] = useState({ data: [] });
    const [engine, setEngine] = useState("")
    const [ttsStatus, setTtsStatus] = useState(true);
    const [ttsText, setTtsText] = useState("")
    const [currentTts, setCurrentTts] = useState('');
    const [loader, setLoader] = useState(false);

    const [langData, setLangData] = useState({
        lang: '',
        voice: '',
        voiceId: "",
    })
    const [generateData, setGenerateData] = useState({
        url: "",
        duration: 0
    })

    const getLanguage = (e) => {
        const vId = pollyLanguages.data.find(({ code }) => code === e.target.value)
        if (vId) {
            fetchPollyVoices(vId.id, e.target.value);
        }
        setTtsStatus(true)
        setLangData({
            ...langData,
            lang: e.target.value
        })
    }

    const getVoices = (e) => {
        const data = pollyVoices.data.find(({ id }) => id === e.target.value)
        setLangData({
            ...langData,
            voice: data.voice_id,
            voiceId: e.target.value
        })
        setTtsStatus(true)
        setEngine(data.type)
    }

    const playTts = (url) => {
        if (ttsAudio !== false) {
            ttsAudio.pause();
        }
        if (currentTts === url) {
            ttsAudio.pause();
            setCurrentTts('')
        } else {
            ttsAudio = new Audio(url);
            ttsAudio.play();

            ttsAudio.onended = function () {
                setCurrentTts('')
            }
            setCurrentTts(url);
        }
    }

    const generateTts = () => {
        if (ttsText !== "" && langData.lang !== "" && langData.voice !== "") {
            setLoader(true);
            let engineVal
            if (engine === "Neural") {
                engineVal = engine.replace("Neural", "neural")
            }
            else {
                engineVal = engine.replace("Standard", "standard")
            }
            let data = {
                text: ttsText,
                language_id: langData.lang,
                voice_id: langData.voice,
                engine: engineVal
            }
            dispatch(onGenerateTts(data, generateData, setGenerateData, setLoader, setTtsStatus))

        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please, add text, language & voice in TTS!',
                confirmButtonColor: "#618FCA"
            })
        }
    }

    const onEditTts = (e) => {
        setTtsText(e.target.value)
        setTtsStatus(true)
        dispatch(updateTtsText(e.target.value, selectedSlideIndex))
    }

    const applyTts = () => {
        dispatch(updateTtsUrl(generateData, langData, selectedSlideIndex))
    }



    const fetchPollyLanguages = () => {
        dispatch(onFetchLang(pollyLanguages, setPollyLanguages))
    }

    const fetchPollyVoices = (value, langCode) => {
        dispatch(onFetchVoice(value, pollyVoices, setPollyVoices, setLangData, langData, selectedSlide, langCode, setEngine))
    }

    const handleGenerateAll = (e) => {
        setDeactiveButton(true)
        setTtsStatus(true)
        if (e.target.checked) {
            let engineVal
            if (engine === "Neural") {
                engineVal = engine.replace("Neural", "neural")
            }
            else {
                engineVal = engine.replace("Standard", "standard")
            }
            let data = {
                language_id: langData.lang,
                voice_id: langData.voice,
                engine: engineVal
            }
            dispatch(onGenerateTtsAll(campaign.slides, data, setDeactiveButton))
        }
    }


    useEffect(() => {
        if (addAllText) {
            setAddAllText(false)
        }
        setTtsStatus(true)
        if (selectedSlideIndex !== -1) {
            let vId = pollyLanguages.data.find(({ code }) => code === selectedSlide.audio.meta.languageId)
            if (vId) {
                fetchPollyVoices(vId.id, selectedSlide.audio.meta.languageId)
            }
            setTtsText(selectedSlide.audio.meta.text)
        }
    }, [selectedSlideIndex, pollyLanguages])

    useEffect(() => {
        fetchPollyLanguages()
    }, [])

    return (
        <>
            <div className='row'>
                <div className='col-4'>
                    <div className='inp-wrap'>
                        <label htmlFor="">Slide No.</label>
                        <input
                            className='input solid'
                            value={+selectedSlideIndex + 1}
                            readOnly
                        />

                    </div>
                </div>
                <div className='col-8'>
                    <div className='inp-wrap'>
                        <label htmlFor="">Language</label>
                        <select
                            onChange={(e) => getLanguage(e)}
                            value={langData.lang}
                            className='input solid'

                        >
                            <option value={""}>Select Language</option>
                            {pollyLanguages.data.length > 0 ?
                                pollyLanguages.data.map((curElem, index) => {
                                    return (
                                        <option key={index} value={curElem.code}>{curElem.name}</option>
                                    )
                                })
                                : ""}
                        </select>
                    </div>
                </div>
                <div className='col-12'>
                    <div className='inp-wrap mt-3'>
                        <label htmlFor="">Voice</label>
                        <select
                            value={langData.voiceId}
                            onChange={(e) => getVoices(e)}
                            className='input solid'
                        >
                            <option value={""}>Select Voice</option>
                            {
                                pollyVoices.data.length > 0 ?
                                    pollyVoices.data.map((item, index) => {

                                        return (
                                            <option key={index} value={item.id}>{item.voice_id} ({item.gender}), {item.type}</option>
                                        )
                                    })
                                    : ''
                            }
                        </select>
                    </div>
                </div>
            </div>
            <div className='inp-wrap mt-3'>
                <textarea
                    className='input solid'
                    onChange={(e) => onEditTts(e)}
                    value={ttsText}
                />
            </div>
            {ttsStatus ? "" :
                <label htmlFor='addTtsAll2' className='d-flex'>
                    <input
                        type="checkbox"
                        id='addTtsAll2'
                        onChange={handleGenerateAll}
                        style={{ width: '40px', height: "19px", }}
                    />
                    <span className='align-self-center' style={{ fontSize: '13px' }}>Use the same language & voice to generate voiceover for all the slides</span>
                </label>
            }
            <div className='row mt-3'>
                <div className='col-6'>
                    {ttsStatus ?
                        <button
                            disabled={loader}
                            onClick={() => generateTts()}
                            className='site-link full sm'
                        >
                            <span>
                                {loader ? <>Generating<i className="fa fa-spinner fa-spin mx-1" /></> : "Generate"}
                            </span>
                        </button> :
                        <button className="site-link full sm"
                            onClick={() => playTts(generateData.url)}
                        >
                            <span className="me-1">
                                <i className={`fas ${currentTts === generateData.url ? 'fa-pause-circle' : 'fa-play-circle'}`} /> Preview
                            </span>

                        </button>

                    }
                </div>
                <div className='col-6'>
                    <button
                        onClick={() => applyTts()}
                        disabled={ttsStatus}
                        style={{ background: ttsStatus ? "gray" : "" }}
                        className='site-link full sm'><span>Use</span></button>
                </div>
            </div>
        </>
    );
}

export default TextToSpeech;
