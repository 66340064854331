import React, { useState } from 'react'
import { onDeleteSocial } from '../../../Redux/Actions/ClientAction';
import SweetAlert from '../../CommonComponent/SweetAlert';
import { useDispatch } from 'react-redux';
import { AiOutlineDelete } from 'react-icons/ai';
import youtube from '../../../assets/images/youtube.png'
import LinkedIn from '../../../assets/images/LinkedIn.png'
import Facebook from '../../../assets/images/Facebook.png'
import Vimeo from '../../../assets/images/vimeo_new.png'
import Telegram from '../../../assets/images/telegram_new.png'
import Reddit from '../../../assets/images/reddit_new.png'
import Wordpress from '../../../assets/images/wordpress-logo.png'
import Medium from '../../../assets/images/medium-logo.png'
import Twitter from '../../../assets/images/twitter-x.png'
import Instagram from '../../../assets/images/Instagram.png'

const ClientTable = ({ socialConnects, fetchClientData }) => {


    const dispatch = useDispatch()

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
    })


    const onDelete = (tId) => {
        setSweet({
            ...sweet,
            enable: true,
            id: tId
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete"
        })
    }

    const performDelete = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting..."
        })
        dispatch(onDeleteSocial(data, fetchClientData, setSweet))
    }

    return (
        <>
            <div className='table-responsive'>
                <table className='table theme-table'>
                    <thead className='text-center'>
                        <tr>
                            <th>No.</th>
                            <th>Social</th>
                            <th>Account</th>
                            <th>Name</th>
                            <th>Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>

                        {socialConnects.length > 0 ?
                            socialConnects.map((curElem, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                            <img style={{ height: "50px", width: "50px" }}
                                                src={curElem.type === "youtube" ? youtube :
                                                    curElem.type === "facebook" ? Facebook :
                                                        curElem.type === "instagram" ? Instagram :
                                                            curElem.type === "twitter" ? Twitter :
                                                                curElem.type === "linkedin" ? LinkedIn :
                                                                    curElem.type === "telegram" ? Telegram :
                                                                        curElem.type === "vimeo" ? Vimeo :
                                                                            curElem.type === "reddit" ? Reddit :
                                                                                curElem.type === "wordpress" ? Wordpress :
                                                                                    curElem.type === "medium" ? Medium :
                                                                                        ""
                                                }
                                                alt="social"
                                            />
                                        </td>
                                        <td>{curElem.type}</td>
                                        <td>{curElem.username}</td>
                                        <td>{curElem.created}</td>
                                        <td><span className='smBtn' onClick={() => onDelete(curElem.id)}><AiOutlineDelete /></span></td>

                                    </tr>
                                )
                            })
                            : <tr className="text-center table-border-remove">
                                <td colSpan={6} style={{ fontSize: 15 }}>
                                    No Social Connected yet!
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            <SweetAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this Social account?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
            />
        </>
    )
}

export default ClientTable