import React, { useState } from 'react';

function VipEventCalendar() {

    return(
        <>
          VipEventCalendar
        </>
    )

}

export default VipEventCalendar;