import React, { useState } from 'react';
import { addTransition, removeTransition } from '../../../../../Redux/Actions/VideoActions';
import { useDispatch, useSelector } from 'react-redux';
import { GiCheckMark } from 'react-icons/gi';

const TransCard = (props) => {
    const dispatch = useDispatch()
    const campaign = useSelector(state => state.video.campaign)
    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")

    const [state, setState] = useState({
        active: false,
        loader: false
    })

    const handleTransition = () => {
        dispatch(addTransition(props.name, selectedSlideIndex))
    }
    const handleRemove = (e) => {
        e.stopPropagation()
        dispatch(removeTransition(selectedSlideIndex))
    }

    const handleActive = () => {
        setState({
            ...state,
            loader: true,
            active: true
        })

    }

    const handleLeave = () => {
        setState({
            ...state,
            loader: false,
            active: false
        })
    }
    return (
        <li>
            <div
                className='list-images-single'
                style={{ cursor: "pointer" }}
                onClick={() => handleTransition()}
                onMouseEnter={() => handleActive()}
                onMouseLeave={() => handleLeave()}
            >
                <img
                    alt=""
                    src={state.active ? props.preview : props.thumbnail}
                />
                {selectedSlide.transitionName === props.name ?
                    <div className='select-tile-style' onClick={handleRemove}>
                        <GiCheckMark />
                    </div>
                    : ''
                }
                <div className='transition-txt text-capitalize fetch-loader-block' style={{ fontWeight: 600, fontSize: 12 }}>
                    {state.loader ?
                        <i className='fa fa-spinner fa-spin fetch-loader' />
                        : <span className='text-light'>{props.name}</span>
                    }
                </div>
            </div>


        </li>
    );
}

export default TransCard;
