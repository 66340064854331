import React, { useEffect, useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { BsPauseFill, BsPlayFill } from 'react-icons/bs';
import SweetAlert from './SweetAlert';
import { onDeleteMedia } from '../../Redux/Actions/CommonActions';
import { useDispatch } from 'react-redux';

let audio = false
const AudioTrack = (props) => {
    const dispatch = useDispatch()
    const [audioDuration, setAudioDuration] = useState(0)
    const [play, setPlay] = useState(false);
    const [loader, setLoader] = useState(false);

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
    })

    const onDelete = (e) => {
        if (e) {
            e.stopPropagation()
        }
        setSweet({
            ...sweet,
            enable: true,
            id: props.id
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete"
        })
    }

    const performDelete = () => {
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting..."
        })
        const formData = new FormData();
        formData.append('id', sweet.id);
        dispatch(onDeleteMedia(formData, props.fetchAudios, setSweet))
    }


    let clearPause;
    const playAudio = (e) => {
        e.stopPropagation()
        if (audioDuration > 0) {
            if (audio !== false) {
                audio.pause();
                setPlay(false);
            }
            setLoader(true);
            audio = new Audio(props.url);
            audio.play()
                .then(r => setLoader(false)).catch(err => console.log(err));
            // props.setCurrentUrl(props.url);
            setPlay(true)
            clearPause = setTimeout(() => {
                pauseAudio()
            }, audioDuration * 1000)
        }
    }

    const pauseAudio = (e) => {
        if (e) {
            e.stopPropagation()
        }
        if (audio !== false) {
            clearTimeout(clearPause);
            audio.pause();
            setPlay(false);
            audio = false;
        }
    }

    const handleSelect = () => {
        props.onSelectAudio(props.url, audioDuration)
        props.setSelectedAudio(props.url)
    }

    useEffect(() => {
        if (props.url) {
            let au = document.createElement('audio');
            au.src = props.url;

            au.addEventListener('loadedmetadata', function () {
                if (au.duration !== Infinity && au.duration !== 0) {
                    setAudioDuration(Number((au.duration).toFixed(1)));
                }
                else {

                    var getDuration = function (url, next) {
                        var _player = new Audio(url);
                        _player.load();
                        _player.currentTime = 24 * 60 * 60; // fake big time
                        _player.volume = 0;
                        _player.muted = false;
                        _player.play();
                        console.log("check run time")
                        _player.addEventListener("durationchange", function (e) {
                            if (this.duration != Infinity) {
                                var duration = this.duration
                                _player.remove();
                                next(duration);
                            };
                        }, false);

                        // Wait for a user gesture (e.g., a click) before loading and playing the audio.
                        document.addEventListener("click", function () {
                            _player.load();
                            _player.currentTime = 24 * 60 * 60; // fake big time
                            _player.volume = 0;
                            _player.muted = false;
                            _player.play();
                        }, { once: true }); // Remove the event listener after it's triggered once.
                    };

                    getDuration(au.src, function (duration) {
                        setAudioDuration(Number((duration).toFixed(1)));
                    });
                }
            }, false);
        }
    }, [props.url])

    useEffect(() => {
        return () => {
            if (audio !== false) {
                audio.pause();
            }
        }
    }, [])
    return (
        <li className={props.selectedAudio === props.url ? `active-music-file` : ""} onClick={handleSelect} style={{ cursor: "pointer" }}>
            <span className='audio-list-left'>
                {play ?
                    <BsPauseFill
                        onClick={(e) => pauseAudio(e)}
                    />
                    :
                    loader ?
                        <i className='fa fa-spinner fa-spin' style={{ fontSize: 15, color: "#618FCA" }} />
                        :
                        <BsPlayFill
                            onClick={(e) => playAudio(e)}
                        />
                }
            </span>

            <span className='audio-txt'>{props.name}</span>
            <span className='audio-dur'>{audioDuration}s</span>
            {props.type === "uploaded" ?
                <span className='del-audio' onClick={(e) => onDelete(e)}><AiOutlineDelete /></span> : ""}

            <SweetAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this audio?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}

            />
        </li>
    );
}

export default AudioTrack;
