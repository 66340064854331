import React from 'react'

const AISocialsKoincart = () => {
    return (
        <>
            <div className="tabepaneIn ">
                <h2 className="tabTitle">AISocials Koincart</h2>
                <div className="tabepaneIn-main">
                    {/* <div className="tabepaneIn-cont"> */}
                    {/* <h3>AISocials Koincart</h3> */}
                    <div className="col-md-12">
                        <div className=" text-center  text-white">
                            <div className="">
                                <a
                                    rel="noreferrer"
                                    href="https://virtualpioneer.lpages.co/aisocialskoincartspecial/"
                                    target="_blank"
                                >
                                    <button className="site-link mt-0">
                                        <span>AISocials Koincart</span>
                                    </button>
                                </a>
                            </div>
                        </div>

                    </div>
                    {/* </div> */}
                </div>
            </div>
        </>
    )
}

export default AISocialsKoincart
