import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import TimezoneSelect from 'react-timezone-select';
import { onUpdateSchedule } from '../../../Redux/Actions/ScheduleActions';

const EditTimeModal = (props) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState({
        hours: "",
        id: "",
        minutes: "",
        re_purpose: "0",
        schedule_date: "",
        social_accounts: null,
        timezone: ""
    })

    const handleChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        dispatch(onUpdateSchedule(data, props.handleClose, props.fetchData, setLoader))
    }

    useEffect(() => {
        if (props.datetimeToEdit) {
            setData({
                ...data,
                hours: props.datetimeToEdit.hours,
                id: props.datetimeToEdit.id,
                minutes: props.datetimeToEdit.minutes,
                re_purpose: props.datetimeToEdit.rePurpose,
                schedule_date: props.datetimeToEdit.scheduleDate.split(" ")[0],
                social_accounts: null,
                timezone: props.datetimeToEdit.timezone
            })
        }
    }, [props])

    return (
        <Modal className='theme-modal xl' show={props.show} onHide={props.handleClose} centered scrollable>
            <form onSubmit={handleSubmit}>

                <Modal.Body>
                    <div className='modal-tab pt-3'  >
                        <h3 className='pb-4'>Select Date & Time</h3>

                        <div className='row pt-3'>
                            <div className='col-sm-12 col-lg-12'>
                                <div className='common-input-wrap mt-0'>
                                    <label htmlFor="">Date</label>
                                    <input
                                        type='date'
                                        className='common-input'
                                        name='schedule_date'
                                        value={data.schedule_date}
                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row pt-3'>
                            <div className='col-sm-6 col-lg-6'>
                                <div className='common-input-wrap mt-0'>
                                    <label htmlFor="">Hours</label>
                                    <input
                                        type='number'
                                        className='common-input'
                                        min={1}
                                        max={24}
                                        name='hours'
                                        value={data.hours}
                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-6 col-lg-6'>
                                <div className='common-input-wrap mt-0'>
                                    <label htmlFor="">Minutes</label>
                                    <input
                                        type='number'
                                        className='common-input'
                                        min={0}
                                        max={59}
                                        name='minutes'
                                        value={data.minutes}
                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='common-input-wrap mb-2'>
                            <label htmlFor="">Timezone</label>
                            <TimezoneSelect
                                value={data.timezone ? data.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone}
                                onChange={(e) => handleChange("timezone", e.value)}
                                className='common-input p-0'
                            />
                        </div>
                        <div className="alert alert-primary" role="alert">
                            Hit "repurpose" to have AiSocials repost the content monthly without having to reschedule again. AiSocials will select your best content from that particular content stream to post each time without scheduling again.
                        </div>
                        <div className='inp-wrap mt-2'>
                            <label htmlFor='re_purpose' className='text-capitalize'>Repurpose</label>
                            <label className="switch">
                                <input
                                    className='input solid'
                                    name="re_purpose"
                                    type="checkbox"
                                    id="re_purpose"
                                    checked={+data.re_purpose === 0 ? false : true}
                                    onChange={(e) => handleChange("re_purpose", e.target.checked ? 1 : 0)}
                                />
                                <span className="sliderN round"></span>
                            </label>

                        </div>

                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <button className='site-link bdr' type='button' onClick={props.handleClose}><span>Cancel</span></button>
                    <button className='site-link' type='submit'><span>{loader ? <>Submiting <i className="fa fa-spinner fa-spin mx-1" /></> : "Submit"}</span></button>
                </Modal.Footer>
            </form>

        </Modal>
    )
}

export default EditTimeModal