import React from "react";
import yt from "../../../assets/images/youtube.png";
import GoogleLogin from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { saveYoutube } from "../../../Redux/Actions/ClientAction";

const Youtube = ({ checkAccount, editClient, fetchClientData, fromSocial }) => {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const responseGoogle = (response) => {
        if (response.code) {
            const data = {
                user_id: auth.user.id,
                code: response.code,
                clientId: editClient.id
            };
            dispatch(saveYoutube(data, fetchClientData));
        }
    }

    return (
        <>
            <GoogleLogin
                clientId="385524880993-kb22h3hvlooun9i0908bsm29bkeafhio.apps.googleusercontent.com"
                render={(renderProps) => (
                    <span style={{ cursor: "pointer" }} className={`${checkAccount === false ? 'hide-button' : ''}`} onClick={renderProps.onClick}>
                        {fromSocial ?
                            <a className='site-link integr_card mt-4' style={{ padding: "2px 20px", fontSize: "14px", background: '#4fc5e1', borderColor: "#4fc5e1" }} > <span> Connect</span> </a>
                            :
                            <img className={`${checkAccount === false ? 'client-img-opacity' : ''}`} src={yt} alt="youtube-img" />
                        }
                    </span>
                )}
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                scope="https://www.googleapis.com/auth/youtube"
                responseType="code"
                accessType="offline"
                prompt="consent"
                cookiePolicy={"single_host_origin"}
            />
        </>
    )

}


export default Youtube;