import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TitleBar from '../../title-bar/TitleBar';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import { useEffect } from 'react';
import { onFetchAgency, onFetchClientReport } from '../../../Redux/Actions/ReportAction';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import Youtube from '../../../assets/images/youtube.png'
import LinkedIn from '../../../assets/images/LinkedIn.png'
import Facebook from '../../../assets/images/Facebook.png'
import Vimeo from '../../../assets/images/vimeo_new.png'
import Telegram from '../../../assets/images/telegram_new.png'
import Reddit from '../../../assets/images/reddit_new.png'
import Wordpress from '../../../assets/images/wordpress-logo.png'
import Medium from '../../../assets/images/medium-logo.png'
import { useSelector } from 'react-redux';

function CreateReport() {

    const location = useLocation()
    const auth = useSelector(state => state.auth);
    const { id, month, year } = queryString.parse(location.search)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [state, setState] = useState({
        social_accounts: [],
        scheduler: [],
        client: {}
    });

    const [agencyData, setAgencyData] = useState({
        id: "",
        logo: "",
        name: "",
        email: "",
        phone: "",
        siteUrl: ""
    })

    const [loader, setLoader] = useState(false);

    const fetchClientReport = () => {
        setLoader(true);
        let data = {
            id: id,
            month: month,
            year: year
        }
        dispatch(onFetchClientReport(data, setState, navigate, setLoader))
    }

    const generatePdf = (e) => {
        let printContents = document.getElementById('generate_pdf').innerHTML;
        let originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;

        window.print();
        document.body.innerHTML = originalContents;

    }

    const fetchAgency = () => {
        let data = {}
        dispatch(onFetchAgency(data, agencyData, setAgencyData))
    }

    useEffect(() => {
        if (id) {
            fetchClientReport()
            fetchAgency()
        }
    }, [])

    return (
        <>
            <TitleBar title="Report" />
            <Header></Header>
            {
                loader ?
                    <div className="loader-sec">
                        <div className="loader" />
                    </div>
                    :
                    <section className='page-wrap'>
                        <div className='container' >
                            <div id='generate_pdf'>
                                <h1 className='page-title my-2'>My Report</h1>
                                <div className='profilePic' style={{ display: "flex", margin: "0", alignItems: "center" }}>
                                    <img src={agencyData.logo ? agencyData.logo : auth.user.profile} className="img-fluid" width="250px" alt="" />
                                    <h1 className='page-title my-2 mx-2'>{agencyData.name ? agencyData.name : auth.user.name}</h1>
                                </div>


                                <div className='cont-box'>
                                    <div className='client-single alt'>
                                        <div className='report-date'>
                                            <h3>{month}-{year}</h3>
                                        </div>
                                        <div className='client-imgN'><img alt='' src={state.client.profile} /></div>
                                        <h3>{state.client.name}</h3>
                                        <p>{state.client.description}</p>
                                        <div className='client-social'>
                                            <ul>
                                                {state.social_accounts.length > 0 ?
                                                    state.social_accounts.map((curElem, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <img style={{ height: "50px", width: "50px" }}
                                                                    src={curElem.type === "youtube" ? Youtube :
                                                                        curElem.type === "facebook" ? Facebook :
                                                                            curElem.type === "linkedin" ? LinkedIn :
                                                                                curElem.type === "telegram" ? Telegram :
                                                                                    curElem.type === "vimeo" ? Vimeo :
                                                                                        curElem.type === "reddit" ? Reddit :
                                                                                            curElem.type === "wordpress" ? Wordpress :
                                                                                                curElem.type === "medium" ? Medium :
                                                                                                    ""
                                                                    }
                                                                    alt="social"
                                                                />
                                                            </li>
                                                        )
                                                    })
                                                    : ""
                                                }
                                            </ul>
                                        </div>
                                    </div>

                                    <div className='table-responsive mt-5 pt-5'>
                                        <table className='table theme-table'>
                                            <thead>
                                                <tr>
                                                    <th>Content Stream</th>
                                                    <th>Schedule Status</th>
                                                    <th>Post URL</th>
                                                    <th>Schedule date and time</th>
                                                    <th>Time Zone</th>
                                                    <th>Scheduler Type</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {state.scheduler.length > 0 ?
                                                    state.scheduler.map((curElem) => {
                                                        console.log(curElem, "a")
                                                        return (
                                                            JSON.parse(curElem.streams).length > 0 ?
                                                                JSON.parse(curElem.streams).map((stream, index) => {
                                                                    console.log(stream, "d")
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{stream.title !== '' ? stream.title : 'N/A'}</td>
                                                                            <td>
                                                                                <span style={{ color: `${stream.status === "1" ? "green" : stream.status === "0" ? "orange" : "red"}` }}>{stream.status === "1" ? "Posted" : stream.status === "0" ? "Scheduled" : "Failed"}</span>
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    stream.socialAccount.length > 0 ?
                                                                                        stream.socialAccount.map((social, indx) => {
                                                                                            return (
                                                                                                <>
                                                                                                    {
                                                                                                        social.post_url !== "" ? <a target="_blank" href={social.post_url}><i className={`fab fa-${social.type}`}></i></a> : ""
                                                                                                    }
                                                                                                </>
                                                                                            )
                                                                                        })
                                                                                        : ''
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {stream.scheduled}
                                                                            </td>
                                                                            <td>
                                                                                {curElem.timezone}
                                                                            </td>
                                                                            <td className="uc-first">
                                                                                {curElem.type}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                                : ""
                                                        )
                                                    }) : <tr align="center"><td colSpan="6">No Data Found</td></tr>
                                                }
                                            </tbody>
                                        </table>

                                        <h5 className="d-flex justify-content-between" style={{ opacity: ".5" }}>
                                            <span>{agencyData.email ? agencyData.email : auth.user.email}</span>
                                            <span>{agencyData.siteUrl ? agencyData.siteUrl : auth.user.name}</span>
                                        </h5>
                                    </div>

                                </div>
                            </div>
                            <div className='client-btn text-end'>
                                <button className='site-link' onClick={generatePdf}><span>Print</span></button>
                            </div>
                        </div>
                    </section>
            }
            <Footer></Footer>
        </>
    )

}

export default CreateReport;