import React, { useEffect, useState } from 'react';
import TitleBar from '../../title-bar/TitleBar';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import { useDispatch } from 'react-redux';
import { fetchData } from '../../../Redux/Actions/CommonActions';
import ReportClientCard from './ReportClientCard';
import AgencyModal from './AgencyModal';
import { onFetchAgency } from '../../../Redux/Actions/ReportAction';
import { useSelector } from 'react-redux';

function Report() {

    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [Clients, setClients] = useState([])
    const [loader, setLoader] = useState({
        fetch: false
    })

    const [state, setState] = useState({
        id: "",
        logo: "",
        name: "",
        email: "",
        phone: "",
        siteUrl: ""
    });

    const [agencyName, setAgencyName] = useState("")
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false)


    const fetchClient = () => {
        let data = {}
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchData("fetch-app-client", data, setClients, loader, setLoader))
    }

    const fetchAgency = () => {
        let data = {}
        dispatch(onFetchAgency(data, state, setState, setAgencyName))
    }

    useEffect(() => {
        fetchClient()
        fetchAgency()
    }, [])

    return (
        <>
            <TitleBar title="Report" />
            <Header></Header>

            <section className='page-wrap'>
                <div className='container'>
                    <h1 className='page-title'>My Report</h1>

                    <div className='content-search'>
                        <div className='row'>
                            <div className='col-xl-2'>
                                {+auth.user.is_client_account === 1 ? "" :
                                    <button className='site-link full bdr' onClick={() => setShow(true)}><span>{agencyName ? agencyName : "+ Add Agency"}</span></button>
                                }
                            </div>
                        </div>
                    </div>

                    <div className='client-list'>
                        <div className='row'>

                            {Clients.length > 0 ?
                                Clients.filter(curElem => {
                                    if (+auth.user.is_client_account) {
                                        return +auth.user.id === +curElem.id
                                    }else {
                                        return curElem
                                    }
                                }).map((curElem, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <ReportClientCard
                                                curElem={curElem}
                                            />
                                        </React.Fragment>
                                    )
                                })
                                : <div className='fetch-loader-block mt-5'>
                                    {loader.fetch ?
                                        <i className="fa fa-spinner fa-spin fetch-loader" />
                                        :
                                        <span style={{ fontSize: 20, color: "#00000" }}>
                                            "You do not have any clients yet!"
                                        </span>
                                    }
                                </div>
                            }
                        </div>
                    </div>

                </div>
            </section>
            <AgencyModal show={show} handleClose={handleClose} state={state} setState={setState} fetchAgency={fetchAgency} />

            <Footer></Footer>
        </>
    )

}

export default Report;