import React, { useEffect, useState } from 'react';
import { Search } from 'react-iconly'
import { Link } from 'react-router-dom';
import TitleBar from '../../title-bar/TitleBar';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import { fetchData } from '../../../Redux/Actions/CommonActions';
import { useDispatch } from 'react-redux';
import ContentStreamRows from './ContentStreamRows';
import { useSelector } from 'react-redux';

const ContentStream = () => {

    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [streams, setStreams] = useState([])
    const [q, setQ] = useState({
        search: "",
        type: "all"
    })
    const [loader, setLoader] = useState({
        fetch: false
    })

    const handleFilter = (e) => {
        const { name, value } = e.target
        setQ({
            ...q,
            [name]: value
        })
    }
    const fetchContentStream = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchData("fetch-all-content-stream", {}, setStreams, loader, setLoader))
    }


    useEffect(() => {
        fetchContentStream()
    }, [])

    return (
        <>
            <TitleBar title="Home" />
            <Header />

            <section className='page-wrap'>
                <div className='container'>
                    <h1 className='page-title'>My Streams</h1>

                    <div className='content-search'>
                        <div className='row'>
                            <div className='col-xl-8'>
                                <div className='input-wrap with-icon'>
                                    <span className='inp-icon'><Search /></span>
                                    <input
                                        className='inp'
                                        type="text"
                                        placeholder='Search'
                                        value={q.search}
                                        name='search'
                                        onChange={handleFilter}
                                    />
                                </div>
                            </div>
                            <div className='col-xl-2'>
                                <div className='input-wrap'>
                                    <select
                                        className='inp'
                                        name="type"
                                        value={q.type}
                                        onChange={handleFilter}
                                    >
                                        <option value="all">All</option>
                                        <option value="image">Image</option>
                                        <option value="video">Video</option>
                                        <option value="text">Text</option>
                                        <option value="formatted">Formatted Text</option>
                                    </select>
                                </div>
                            </div>
                            <div className='col-xl-2'>
                                {+auth.user.is_client_account === 1 ? "" :
                                    <Link className='site-link full bdr' to="/content-stream/add-stream"><span>+ Add Stream</span></Link>
                                }
                            </div>
                        </div>
                    </div>

                    <div className='cont-box-group'>
                        <div className='cont-box'>
                            <div className='table-responsive'>
                                <table className='table theme-table'>
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Thumbnail</th>
                                            <th>Type</th>
                                            <th>Title</th>
                                            <th>Description</th>
                                            <th>Created Date</th>
                                            <th>Tags</th>
                                            <th className='text-end'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {streams.length > 0 ?
                                            streams.filter((curElem) => {
                                                return (curElem.title.toLowerCase().includes(q.search.toLowerCase()) && (curElem.type === q.type || "all" === q.type))
                                            }).map((curElem, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <ContentStreamRows
                                                            index={index}
                                                            image={curElem.stream.thumbnails}
                                                            type={curElem.type}
                                                            title={curElem.title}
                                                            description={curElem.description}
                                                            created={curElem.created}
                                                            tags={curElem.tags}
                                                            id={curElem.id}
                                                            fetchData={fetchContentStream}
                                                        />
                                                    </React.Fragment>
                                                )
                                            })

                                            :
                                            <tr>
                                                <td colSpan={7} className='fetch-loader-block'>
                                                    {loader.fetch ?
                                                        <i className="fa fa-spinner fa-spin fetch-loader" />
                                                        :
                                                        <span style={{ fontSize: 15 }}>
                                                            You have not created any content stream yet. Click on 'Add Stream' to create one!
                                                        </span>
                                                    }
                                                </td>
                                            </tr>

                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <Footer />
        </>
    )

}

export default ContentStream;