import axios from "axios"

export const fetchVideos = (loader, setLoader) => async (dispatch) => {
    await axios({
        method: "POST",
        url: "https://adminserver.reelapps.io/api/fetch-videos",
        data: JSON.stringify({ app_id: 64 }),
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {
        if (res.data.status) {
            dispatch({ type: 'ADD_VIDEO', payload: res.data.data })
        }
        if (loader) {
            setLoader({
                ...loader,
                video: false
            })
        }
    }).catch(err => {
        console.log(err)
        if (loader) {
            setLoader({
                ...loader,
                video: false
            })
        }
    })

}

export const fetchArticles = (loader, setLoader) => async (dispatch) => {
    await axios({
        method: "POST",
        url: "https://adminserver.reelapps.io/api/fetch-article",
        data: JSON.stringify({ app_id: 64 }),
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {
        if (res.data.status) {
            dispatch({ type: 'ADD_ARTICLE', payload: res.data.data })
        }
        if (loader) {
            setLoader({
                ...loader,
                article: false
            })
        }
    }).catch(err => {
        console.log(err)
        if (loader) {
            setLoader({
                ...loader,
                article: false
            })
        }
    })

}