import axios from "axios"
import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"
import { baseURL } from "../../Global/Global"


export const onSearchGoogleLocation = (data, setGoogleLocations) => (dispatch, getState) => {
    commonAxios("geo-locations", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let tempArr = res.data.map((item, index) => {
                    return (
                        { label: item.name, value: item.code }
                    )
                })

                setGoogleLocations(tempArr);
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onSearchYoutubeSocial = (data, setYoutubeToken, youtubeResponse, setYoutubeResponse, setYoutubeLoad, setLoader) => (dispatch, getState) => {
    commonAxios("search-youtube", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setYoutubeToken(res.data.next_page_token);
                let arr = [...youtubeResponse];

                setYoutubeResponse(arr.concat(res.data.videos));
            }
            setYoutubeLoad(false)
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setYoutubeLoad(false)
            setLoader(false)
        })
}

export const onDownloadYoutubeVideo = (data, setLoader) => async (dispatch, getState) => {
    try {
        let options = {
            headers: {
                'Authorization': getState().auth.token
            }
        }
        await axios.get(`${baseURL}download-youtube-video?url=https://www.youtube.com/watch?v=${data.id}`, options).then(res => {
            if (res.data.status === true) {
                window.location = `${baseURL}download-file?url=${res.data.url}`
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setLoader(false);
        })
    } catch (err) {
        console.log(err)
        setLoader(false);
        dispatch(setAlert(err.message, 'danger'));
    }
}

export const onSearchGoogleSocial = (data, setGoogleResponse, setLoader) => (dispatch, getState) => {
    commonAxios("google-trends", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = []
                console.log(res.data.trendingSearchesDays)
                if (res.data.trendingSearchesDays.length > 0) {
                    res.data.trendingSearchesDays.forEach(ele => {
                        arr = [...arr, ...ele.trendingSearches];
                    });
                }
                setGoogleResponse(arr);
            } else {
                setGoogleResponse([])
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
}