import React, { useState } from 'react'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { useDispatch, useSelector } from 'react-redux';
import Library from '../../../../CommonComponent/Library';
import Upload from '../../../CreateImage/EditorTabs/Background/Upload';
import AiImageGenerate from '../../../../CommonComponent/AiImageGenerate';
import { addMediaLayer, updateMediaLayer } from '../../../../../Redux/Actions/VideoActions';

const Media = () => {
  const dispatch = useDispatch()
  const campaign = useSelector(state => state.video.campaign)
  const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
  const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
  const selectedLayer = selectedSlide.layers.find(({ isSelected }) => isSelected === true)
  const selectedLayerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)

  const [checkMedia, setCheckMedia] = useState(false)
  const handleBackground = (val, type) => {
    if (selectedLayerIndex !== -1) {
      if (selectedLayer.type === "image" || selectedLayer.type === "video") {
        if (type === "images") {
          dispatch(updateMediaLayer(val, selectedSlideIndex, selectedLayerIndex, "image"))
        } else if (type === "video") {
          dispatch(updateMediaLayer(val, selectedSlideIndex, selectedLayerIndex, "video"))
        } else if (type === "ui") {
          let data = {
            url: val
          }
          dispatch(updateMediaLayer(data, selectedSlideIndex, selectedLayerIndex, "image"))
        }

      } else {
        if (type === "images") {
          dispatch(addMediaLayer(val, selectedSlideIndex, "image", campaign.dimension))
        } else if (type === "video") {
          dispatch(addMediaLayer(val, selectedSlideIndex, "video", campaign.dimension))
        } else if (type === "ui") {
          let data = {
            url: val
          }
          dispatch(addMediaLayer(data, selectedSlideIndex, "image", campaign.dimension))
        }
      }

    } else {
      if (type === "images") {
        dispatch(addMediaLayer(val, selectedSlideIndex, "image", campaign.dimension))
      } else if (type === "video") {
        dispatch(addMediaLayer(val, selectedSlideIndex, "video", campaign.dimension))
      } else if (type === "ui") {
        let data = {
          url: val
        }
        dispatch(addMediaLayer(data, selectedSlideIndex, "image", campaign.dimension))
      }

    }

  }
  return (
    <>

      <div className='library-cont'>
        <div className='inner-tab'>
          <Tab.Container defaultActiveKey="first" justify>
            <Nav variant="n" className="">
              <Nav.Item>
                <Nav.Link eventKey="first">Images</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">Videos</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="third">Upload</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="forth">AI Image</Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="first">
                <Library
                  handleBackground={handleBackground}
                  sType="images"
                  checkMedia={checkMedia}
                  setCheckMedia={setCheckMedia}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <Library
                  handleBackground={handleBackground}
                  sType="video"
                  checkMedia={checkMedia}
                  setCheckMedia={setCheckMedia}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <Upload
                  handleBackground={handleBackground}
                  checkMedia={checkMedia}
                  setCheckMedia={setCheckMedia}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="forth">
                <AiImageGenerate
                  handleBackground={handleBackground}
                  checkMedia={checkMedia}
                  setCheckMedia={setCheckMedia}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </>
  )
}

export default Media