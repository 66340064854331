import React, { useState } from 'react';
import { PiUpload } from 'react-icons/pi';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { onUploadIR, removeIO, updateIOStatus } from '../../../../../Redux/Actions/VideoActions';
import dummyImage from "../../../../../assets/images/dummyImage.jpg"
import { useEffect } from 'react';
const IntroOutro = (props) => {
    const dispatch = useDispatch()
    const [percent, setPercent] = useState(0)
    const [loader, setLoader] = useState(false)
    const [ir, setIr] = useState("")

    const handleStatus = (e) => {
        dispatch(updateIOStatus(e.target.checked, props.type))
        if (e.target.checked === false) {
            dispatch(removeIO(props.type))
        }
    }

    const onInputVideo = (e) => {
        if (e.target.files.length > 0) {
            if (e.target.files[0].type === "video/mp4") {
                const allowedSize = 20000000;
                if (e.target.files[0].size < allowedSize) {

                    const formData = new FormData();
                    setLoader(true)
                    formData.append('file', e.target.files[0])
                    formData.append('upload_type', props.type)
                    dispatch(onUploadIR(formData, setIr, setLoader, props.type, setPercent))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size for video is 20MB!',
                        confirmButtonColor: "#618FCA"
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `Upload ${props.type.charAt(0).toUpperCase() + props.type.slice(1)} Video!`,
                    confirmButtonColor: "#618FCA"
                })
            }
        }
    }

    useEffect(() => {
        if (props.type === "intro") {
            setIr(props.data.thumbnail)
        }
        if (props.type === "outro") {
            setIr(props.data.thumbnail)
        }
    }, [props])


    return (
        <>
            <div className='inp-wrap d-flex justify-content-between mt-3'>
                <label htmlFor='logo' className='text-capitalize'>{props.type}</label>
                <label className="switch">
                    <input
                        className='input solid'
                        name={props.type}
                        type="checkbox"
                        id={props.type}
                        checked={props.data.enable}
                        onChange={(e) => handleStatus(e)}
                    />
                    <span className="sliderN round"></span>
                </label>
            </div>
            {props.data.enable ?
                <div className='upload-box'>
                    <img style={{ height: 100, width: 100, position: "absolute", left: 5, objectFit: "contain", borderRadius: 10 }} src={ir ? ir : dummyImage} alt='' />
                    <div style={{ position: 'absolute', right: 10 }}>
                        {loader ?
                            <div className='percent-loader-box'>
                                {/* <i className='fa fa-spinner fa-spin fetch-loader' /> */}
                                <span className='loader-percent'>{percent}%</span>
                            </div> :
                            <>
                                <PiUpload />
                                <p className='text-capitalize'>Upload {props.type}</p>
                                <input
                                    type="file"
                                    accept="video/*"
                                    onChange={(e) => onInputVideo(e)}
                                />
                            </>}
                    </div>
                </div> : ""}
        </>
    );
}

export default IntroOutro;
