import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import TextToSpeech from './TextToSpeech';
import { useDispatch, useSelector } from 'react-redux';
import UploadMusic from '../../../../CommonComponent/UploadMusic';
import { setTtsData } from '../../../../../Redux/Actions/VideoActions';
import Recording from './Recording';
import { useEffect } from 'react';

const Voiceover = ({ setDeactiveButton }) => {
  const dispatch = useDispatch()
  const campaign = useSelector(state => state.video.campaign)
  const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
  const [selectedAudio, setSelectedAudio] = useState(false)

  const addVoiceover = (url, duration) => {
    const audio = {
      src: url,
      duration: duration,
      meta: {
        languageId: "",
        text: "",
        translateText: "",
        voiceId: "",
      }
    }
    setSelectedAudio(url);
    dispatch(setTtsData(audio, selectedSlideIndex, true))
  }

  useEffect(() => {
    setSelectedAudio(campaign.slides[selectedSlideIndex].audio.src)
  }, [selectedSlideIndex])

  return (
    <>
      <div className='audio-cont'>
        <div className='inner-tab'>
          <Tab.Container defaultActiveKey="first" justify>
            <Nav variant="n" className="">
              <Nav.Item>
                <Nav.Link eventKey="first">Text to Speech</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">Upload</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="third">Record Voice</Nav.Link>
              </Nav.Item>

            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="first">
                <TextToSpeech
                  selectedAudio={selectedAudio}
                  setSelectedAudio={setSelectedAudio}
                  setDeactiveButton={setDeactiveButton}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <UploadMusic
                  type="voiceover"
                  onSelectAudio={addVoiceover}
                  selectedAudio={selectedAudio}
                  setSelectedAudio={setSelectedAudio}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <Recording
                  onSelectAudio={addVoiceover}
                  selectedAudio={selectedAudio}
                  setSelectedAudio={setSelectedAudio}
                />
              </Tab.Pane>

            </Tab.Content>
          </Tab.Container>
        </div>
      </div>

    </>
  )

}

export default Voiceover;