import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { onPushContentStream } from '../../../Redux/Actions/MyContentAction';

const PushModal = ({ curElem, show, type, handleClose, contentStream }) => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [contentId, setContentId] = useState(-1);
    const handleChange = (e) => {
        setContentId(e.target.value)
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        let data
        if (type === "image") {
            data = {
                content_stream_id: contentId,
                description: curElem.meta ? curElem.meta.replaceAll("?", "") : "",
                thumbnail: curElem.thumbnail,
                title: "",
                url: curElem.thumbnail,
                type: type
            }
            dispatch(onPushContentStream(data, setLoader, handleClose))

        } else if (type === "text") {
            data = {
                content_stream_id: contentId,
                description: curElem.script.replaceAll("?", ""),
                thumbnail: "",
                title: curElem.name,
                text: curElem.text,
                type: type
            }
            dispatch(onPushContentStream(data, setLoader, handleClose))
        }
        else {
            data = {
                content_stream_id: contentId,
                description: "",
                thumbnail: curElem.videoData?.thumbnailUrl ? curElem.videoData.thumbnailUrl : "",
                title: "",
                url: curElem.videoData?.videoUrl ? curElem.videoData.videoUrl : "",
                type: type
            }
            dispatch(onPushContentStream(data, setLoader, handleClose))
        }


    }

    return (
        <Modal className='theme-modal xl' show={show} onHide={handleClose} centered scrollable>
            <form onSubmit={handleSubmit}>
                <Modal.Body>
                    <div className='info-tab'>
                        <h3>Push to Content Stream</h3>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Content Stream</label>
                                    <select
                                        className='common-input'
                                        value={contentId}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select Content Stream</option>
                                        {contentStream.length > 0 ?
                                            contentStream.filter((curElem) => {
                                                return curElem.type === type || (type === "images" && curElem.type === "image");
                                            }).map((curElem, index) => {
                                                return (
                                                    <option key={index} value={curElem.id}>{curElem.title}</option>
                                                );
                                            })
                                            : ""
                                        }

                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type='button' className='site-link bdr' onClick={handleClose}><span>Cancel</span></button>
                    <button type="submit" className='site-link'><span>{loader ? <>Pushing <i className="fa fa-spinner fa-spin mx-1" /></> : "Push"}</span></button>
                </Modal.Footer>
            </form>
        </Modal >
    )
}

export default PushModal