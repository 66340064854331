import axios from "axios"
import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"
import { baseURL } from "../../Global/Global"


export const onCreateClient = (data, state, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("add-client-account", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                setState({ ...state, id: res.data, password: "" })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, submit: false })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({ ...loader, submit: false })
            console.log(err)
        })
}
export const onUpdateClient = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("edit-client-account", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, submit: false })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({ ...loader, submit: false })
            console.log(err)
        })
}

export const onGetClientData = (data, state, setState, loader, setLoader, setSocialConnects) => (dispatch, getState) => {
    commonAxios("fetch-app-client-details", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState({
                    ...state,
                    id: res.data.id,
                    profile: res.data.profile,
                    name: res.data.name,
                    email: res.data.email,
                    password: "",
                    earning: res.data.earning,
                    frequency: res.data.frequency,
                    company: res.data.company,
                    description: res.data.description
                })
                setSocialConnects(res.data.socialAccounts)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, fetch: false })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
            setLoader({ ...loader, fetch: false })
        })
}

export const onDeleteClient = (data, fetchClient, setSweet) => (dispatch, getState) => {
    commonAxios("delete-app-client", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchClient()
                dispatch(setAlert(res.msg, 'success'))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete"
            })
        }).catch((err) => {
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete"
            })
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)

        })
}

export const fetchSocialAccounts = (token) => async (dispatch, getState) => {

    const config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': token
        },
    };

    try {
        const res = await axios.post(`${baseURL}fetch-social-accounts`, {}, config);
        console.log(res);
        if (res.data.status === true) {
            dispatch({ type: 'FETCH_SOCIAL_ACCOUNTS', payload: res.data.data })
        }
    } catch (err) {
        console.log(err);
    }

}

export const saveFacebook = (data, fetchClientData) => (dispatch, getState) => {
    commonAxios("save-facebook", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                fetchClientData()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}

export const saveInstagram = (data, fetchClientData) => (dispatch, getState) => {
    commonAxios("save-instagram", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                fetchClientData()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}


export const saveYoutube = (data, fetchClientData) => async (dispatch, getState) => {
    commonAxios("save-youtube", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                fetchClientData()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}

export const checkNetworkStatus = (key, setCheckStatus, interval, myWindow, fetchClientData) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: `${baseURL}check-network-status`,
        data: JSON.stringify({ key: key }),
        headers: { 'Content-Type': 'application/json', 'Authorization': getState().auth.token }
    }).then(res => {
        if (res.data.status === true) {
            clearInterval(interval);
            setCheckStatus(false);
            dispatch(setAlert(res.data.message, 'success'));
            // dispatch(fetchSocialAccounts(getState().auth.token));
            fetchClientData()
            myWindow.close();
        } else {
            setCheckStatus(false);
        }

    }).catch(error => {
        setCheckStatus(false);
        window.close();
        clearInterval(interval);
    })
}


export const saveTelegram = (data, setShowPopup, setLoader, fetchClientData) => (dispatch, getState) => {

    axios({
        method: 'POST',
        url: `${baseURL}connect-telegram`,
        data: data,
        headers: { 'Content-Type': 'application/json', 'Authorization': getState().auth.token }
    }).then(res => {
        console.log(res);

        if (res.data.status === true) {
            dispatch(setAlert(res.data.message, "success"));
            // dispatch(fetchSocialAccounts(getState().auth.token));
            fetchClientData()
        } else {
            dispatch(setAlert(res.data.message, "danger"));
        }

        setLoader(false);
        setShowPopup(false);

    }).catch(error => {
        console.log(error);

        setLoader(false);
        setShowPopup(false);
    })

}


export const saveWordpress = (data, setShowPopup, setLoader, fetchClientData) => (dispatch, getState) => {

    axios({
        method: 'POST',
        url: `${baseURL}save-wordpress`,
        data: data,
        headers: { 'Content-Type': 'application/json', 'Authorization': getState().auth.token }
    }).then(res => {
        console.log(res);

        if (res.data.status === true) {
            dispatch(setAlert(res.data.message, "success"));
            // dispatch(fetchSocialAccounts(getState().auth.token));
            fetchClientData()
        } else {
            dispatch(setAlert(res.data.message, "danger"));
        }

        setLoader(false);
        setShowPopup(false);

    }).catch(error => {
        console.log(error);

        setLoader(false);
        setShowPopup(false);
    })

}



export const saveMedium = (data, setShowPopup, setLoader, fetchClientData) => (dispatch, getState) => {

    axios({
        method: 'POST',
        url: `${baseURL}save-medium`,
        data: data,
        headers: { 'Content-Type': 'application/json', 'Authorization': getState().auth.token }
    }).then(res => {
        console.log(res);

        if (res.data.status === true) {
            dispatch(setAlert(res.data.message, "success"));
            // dispatch(fetchSocialAccounts(getState().auth.token));
            fetchClientData()
        } else {
            dispatch(setAlert(res.data.message, "danger"));
        }

        setLoader(false);
        setShowPopup(false);

    }).catch(error => {
        console.log(error);

        setLoader(false);
        setShowPopup(false);
    })

}


export const onDeleteSocial = (data, fetchClientData, setSweet) => (dispatch, getState) => {
    commonAxios("delete-app-social", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchClientData()
                dispatch(setAlert(res.msg, 'success'))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete"
            })
        }).catch((err) => {
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete"
            })
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)

        })
}

export const onFetchClient = (data, setState, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-app-client-details", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data.socialAccounts)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            console.log(err)

        })
}