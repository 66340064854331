import React from 'react'
import { useState } from 'react'
import { onGenerateAi } from '../../Redux/Actions/CommonActions'
import { useDispatch } from 'react-redux'
import { GiCheckMark } from 'react-icons/gi'

const AiImageGenerate = ({ handleBackground, checkMedia, setCheckMedia }) => {
    const dispatch = useDispatch()
    const [keyword, setKeyword] = useState("")
    const [image, setImage] = useState("")
    const [loader, setLoader] = useState({
        generate: false
    })
    const handleGenerate = () => {
        let data = {
            keyword: keyword
        }
        if (keyword) {
            setLoader({
                ...keyword,
                generate: true
            })
            dispatch(onGenerateAi(data, setImage, loader, setLoader))
        }
    }

    const handleSelect = () => {
        let data = {
            url: image
        }
        setCheckMedia(image)
        handleBackground(data, "images")
    }

    return (
        <div className='inp-wrap'>
            <label htmlFor="">Describe the Image you want</label>
            <textarea
                placeholder='e.g : A rocket launching at sunset'
                className='input'
                onChange={(e) => setKeyword(e.target.value)}
            />
            <button
                className='site-link'
                onClick={handleGenerate}
            >
                <span>  {loader.generate ? <>Generating <i className="fa fa-spinner fa-spin mx-1" /></> : "Generate"}</span>
            </button>
            {image ?
                <div className='list-images-single mt-3' style={{ height: 150, cursor: 'pointer' }} onClick={handleSelect}>
                    <img src={image} alt='' />
                    {checkMedia === image ?
                        <div className='select-tile-style'>
                            <GiCheckMark />
                        </div> : ""}
                </div> : ""
            }
        </div>
    )
}

export default AiImageGenerate