import React, { useEffect, useState } from 'react';
import TitleBar from '../../title-bar/TitleBar';
import Header from '../../header/Header';
import { Search } from 'react-iconly';
import { Link, useLocation } from 'react-router-dom';
import Footer from '../../footer/Footer';
import { fetchData, onDeleteCampaign } from '../../../Redux/Actions/CommonActions';
import { useDispatch } from 'react-redux';
import { AiOutlineDelete } from 'react-icons/ai';
import queryString from 'query-string';
import { FaEye } from 'react-icons/fa';
import DetailsModal from './DetailsModal';
import SweetAlert from '../../CommonComponent/SweetAlert';
import { useSelector } from 'react-redux';

const ScheduleDetails = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const auth = useSelector(state => state.auth)
    const contentStreamId = queryString.parse(location.search).id
    const [loader, setLoader] = useState({
        fetch: false
    })
    const [q, setQ] = useState({
        search: "",
        type: "all"
    })
    const [schedulesList, setSchedulesList] = useState([])
    const [stream, setStreams] = useState([])
    const [socialInfo, setSocialInfo] = useState([])
    const [details, setDetails] = useState({
        title: "",
        description: "",
        accounts: [],
        repurpose: 1,
        type: ""
    })

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
    })

    const handleDelete = (id) => {
        setSweet({
            ...sweet,
            enable: true,
            id: id
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete"
        })
    }

    const performDelete = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting..."
        })
        dispatch(onDeleteCampaign("delete-stream", data, fetchAllSchedules, setSweet))
    }

    const handleShow = (data) => {
        setSocialInfo(data)
        setShow(true)
    }
    const fetchAllSchedules = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchData("fetch-schedulers", {}, setSchedulesList, loader, setLoader))
    }

    const handleFilter = (e) => {
        const { name, value } = e.target
        setQ({
            ...q,
            [name]: value
        })
    }
    useEffect(() => {
        if (schedulesList.length > 0) {
            let data = schedulesList.find(({ id }) => +id === +contentStreamId)
            if (data) {
                setDetails({
                    ...details,
                    title: data.contentStream.title,
                    description: data.contentStream.description,
                    accounts: data.accounts,
                    repurpose: data.rePurpose,
                    type: data.type
                })
                setStreams(JSON.parse(data.streams))
            }
        }
    }, [schedulesList])
    // 
    useEffect(() => {
        fetchAllSchedules()
    }, [])
    return (
        <>
            <TitleBar title="Schedule Content" />
            <Header />

            <section className='page-wrap'>
                <div className='container'>


                    <h1 className='page-title'>Schedule Details</h1>
                    <div className='input-wrap'>
                        <div className="schedule-details cont-box">
                            <h6><strong>Stream Title: </strong> <span>{details.title}</span></h6>
                            <h6><strong>Stream Description: </strong> {details.description}</h6>
                            <h6><strong>Repurpose: </strong> {+details.repurpose === 1 ? "Yes" : "No"}</h6>
                            <h6>
                                <strong>Accounts: </strong>
                                {details.accounts.length > 0 ?
                                    details.accounts.map((curElem, index) => {
                                        if (curElem.length > 0) {
                                            return (
                                                <span key={index} className='me-1 text-capitalize'>{curElem[0].type}{index === details.accounts.length - 1 ? "." : ","} </span>
                                            )
                                        } else {
                                            return null
                                        }
                                    })
                                    : ""}
                            </h6>
                            <h6 style={{ textTransform: "capitalize" }}>
                                <strong>Type: </strong>
                                {details.type} {+details.repurpose === 1 ? "(Automatically rescheduled after the below mentioned are published )" : ""}
                            </h6>
                        </div>


                    </div>
                    <div className='content-search'>
                        <div className='row'>
                            <div className='col-xl-8'>
                                <div className='input-wrap with-icon'>
                                    <span className='inp-icon'><Search /></span>
                                    <input
                                        className='inp'
                                        type="text"
                                        placeholder='Search'
                                        value={q.search}
                                        name='search'
                                        onChange={handleFilter}
                                    />
                                </div>
                            </div>
                            <div className='col-xl-2'>
                                <div className='input-wrap'>
                                    <select
                                        className='inp'
                                        name="type"
                                        value={q.type}
                                        onChange={handleFilter}
                                    >
                                        <option value="all">All</option>
                                        <option value="0">Scheduled</option>
                                        <option value="1">Posted</option>
                                        <option value="2">Failed</option>

                                    </select>
                                </div>
                            </div>
                            <div className='col-xl-2'>
                                {+auth.user.is_client_account === 1 ? "" :
                                    <Link className='site-link full bdr' to="/schedule"><span>+ Schedule</span></Link>}
                            </div>
                        </div>
                    </div>

                    <div className='cont-box-group'>
                        {/* <h5>Business Results</h5> */}
                        <div className='cont-box'>
                            <div className='table-responsive'>
                                <table className='table theme-table'>
                                    <thead className='text-center'>
                                        <tr>
                                            <th>No.</th>
                                            <th>Preview</th>
                                            <th>Title</th>
                                            <th>Description</th>
                                            <th>Schedule</th>
                                            <th>Status</th>
                                            <th>Post Url</th>
                                            <th>Engagement</th>
                                            <th >Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-center'>
                                        {stream.length > 0 ?
                                            stream.filter((curElem) => {
                                                return (curElem.title.toLowerCase().includes(q.search.toLowerCase()) && (+curElem.done === +q.type || "all" === q.type))
                                            }).map((curElem, index) => {
                                                console.log(curElem)
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            <span className='thumb-image'>
                                                                <img alt='' src={curElem.thumbnails} />
                                                            </span>
                                                            {/* <p>{curElem.client.name}</p> */}
                                                        </td>
                                                        <td>{curElem.title ? curElem.title.length > 20 ? curElem.title.slice(0, 20) + "..." : curElem.title : details.title.length > 20 ? details.title.slice(0, 20) + "..." : details.title}</td>
                                                        <td>{curElem.description ? curElem.description.length > 50 ? curElem.description.slice(0, 50) + "..." : curElem.description : details.description.length > 50 ? details.description.slice(0, 50) + "..." : details.description}</td>
                                                        <td>{curElem.scheduled}</td>
                                                        <td style={{ color: +curElem.done === 0 ? "orange" : +curElem.done === 1 ? "green" : "red" }}>{+curElem.done === 0 ? "Scheduled" : +curElem.done === 1 ? "Posted" : "Failed"}</td>
                                                        <td>
                                                            {curElem.social_networks ? curElem.social_networks.length > 0 ?
                                                                <span className='smBtn edit' title="view post url" onClick={() => handleShow(curElem.social_networks)}>
                                                                    <FaEye />
                                                                </span>
                                                                : "" : ""}

                                                        </td>
                                                        <td>
                                                            {/* <span className='smBtn edit' title="view engagement" onClick={() => handleShow()}>
                                                                <FaEye />
                                                            </span> */}
                                                        </td>
                                                        <td>
                                                            {+auth.user.is_client_account === 1 ? "" :
                                                                <span className='smBtn'
                                                                    onClick={() => handleDelete(curElem.id)}
                                                                ><AiOutlineDelete /></span>}
                                                        </td>
                                                    </tr>
                                                )
                                            })

                                            :
                                            <tr>
                                                <td colSpan={10} className='fetch-loader-block'>
                                                    {loader.fetch ?
                                                        <i className="fa fa-spinner fa-spin fetch-loader" />
                                                        :
                                                        <span style={{ fontSize: 18, color: "#000000" }}>
                                                            You have not created any content stream yet. Click 'Add Stream' to create one!
                                                        </span>
                                                    }
                                                </td>
                                            </tr>

                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <DetailsModal
                show={show}
                handleClose={handleClose}
                data={socialInfo}
            />
            <SweetAlert
                show={sweet.enable}
                message={"Are you sure you want to delete it?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
            />
            <Footer />
        </>
    );
}

export default ScheduleDetails;
