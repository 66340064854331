import React from 'react'
import BackgroundLayer from './BackgroundLayer'
import Logo from './Logo'
import Watermark from './Watermark'
import TextLayer from './TextLayer'
import ShapeLayer from './ShapeLayer'
import ImageLayer from './ImageLayer'
import VideoLayer from './VideoLayer'
import CtaView from './CtaView'
import { useDispatch } from 'react-redux'
import { rePositionLayer, resizeLayer, updateSelectedLayer } from '../../../../../Redux/Actions/VideoActions'

const PreviewScreen = (props) => {
    const dispatch = useDispatch()

    const handleSelectLayer = (index) => {
        const layerIndex = props.selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
        if (index !== layerIndex) {
            dispatch(updateSelectedLayer(index, props.selectedSlideIndex))
        }
    }

    const handleResize = (width, height, x, y, layerIndex) => {
        width = parseInt(width.replace("px", ""))
        height = parseInt(height.replace("px", ""))

        dispatch(resizeLayer(width, height, x, y, props.selectedSlideIndex, layerIndex))
    }

    const handleRePosition = (top, left, layerIndex) => {
        dispatch(rePositionLayer(top, left, props.selectedSlideIndex, layerIndex))
    }

    return (
        <div className="video-container">
            {props.preLoader ?
                <div className='loader-center-temp1'>
                    <i className="fa fa-spinner fa-spin" style={{ fontSize: "50px" }} />
                </div>
                :
                <>
                    <BackgroundLayer
                        background={props.selectedSlide.background}
                        selectedSlideIndex={props.selectedSlideIndex}
                        selectedSlide={props.selectedSlide}
                    />
                    <Logo />
                    <Watermark />
                    {props.promo.enable ?
                        <CtaView
                            data={props.promo}
                        />
                        : ""}

                    {props.selectedSlide.layers.length > 0 ?
                        props.selectedSlide.layers.map((layer, index) => {
                            if (layer.type === "text") {
                                return (
                                    <React.Fragment key={index}>
                                        <TextLayer
                                            layer={layer}
                                            selectedSlide={props.selectedSlide}
                                            selectedSlideIndex={props.selectedSlideIndex}
                                            layerIndex={index}
                                            handleSelectLayer={handleSelectLayer}
                                            handleResize={handleResize}
                                            handleRePosition={handleRePosition}
                                        />
                                    </React.Fragment>
                                )
                            }
                            if (layer.type === "shape") {
                                return (
                                    <React.Fragment key={index}>
                                        <ShapeLayer
                                            layer={layer}
                                            selectedSlide={props.selectedSlide}
                                            selectedSlideIndex={props.selectedSlideIndex}
                                            layerIndex={index}
                                            handleSelectLayer={handleSelectLayer}
                                            handleResize={handleResize}
                                            handleRePosition={handleRePosition}
                                        />
                                    </React.Fragment>
                                )
                            }
                            if (layer.type === "image") {
                                return (
                                    <React.Fragment key={index}>
                                        <ImageLayer
                                            layer={layer}
                                            selectedSlide={props.selectedSlide}
                                            selectedSlideIndex={props.selectedSlideIndex}
                                            layerIndex={index}
                                            handleSelectLayer={handleSelectLayer}
                                            handleResize={handleResize}
                                            handleRePosition={handleRePosition}
                                        />
                                    </React.Fragment>
                                )
                            }
                            if (layer.type === "video") {
                                return (
                                    <React.Fragment key={index}>
                                        <VideoLayer
                                            layer={layer}
                                            selectedSlide={props.selectedSlide}
                                            selectedSlideIndex={props.selectedSlideIndex}
                                            layerIndex={index}
                                            handleSelectLayer={handleSelectLayer}
                                            handleResize={handleResize}
                                            handleRePosition={handleRePosition}
                                        />
                                    </React.Fragment>
                                )
                            }
                        }) :
                        ""
                    }
                </>
            }

        </div>
    )
}

export default PreviewScreen