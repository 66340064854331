import React from 'react'
import { onDownloadYoutubeVideo } from '../../../Redux/Actions/TrendAction'
import { useDispatch } from 'react-redux'
import { useState } from 'react';

const YoutubeCard = ({ curElem }) => {

    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false)
    const [readMore, setReadMore] = useState(false)

    const handleReadMore = () => {
        setReadMore((preData) => !preData)
    }

    const downloadYoutube = () => {
        setLoader(true)
        let data = {
            id: curElem.id
        }
        dispatch(onDownloadYoutubeVideo(data, setLoader))
    }

    return (
        <tr>
            <th style={{ width: "70%" }} scope="row">
                {
                    <section className="">
                        <div className="container pl-0">
                            <div className="row">

                                <div
                                    className="col-lg-12"
                                    style={{ marginBottom: "1rem" }}>
                                    <div
                                        className="youtube-box-content"
                                        style={{
                                            boxShadow: "0px 0px 5px #a2a2a2d1",
                                            backgroundColor: "#eeeeee",
                                            overflow: "hidden"
                                        }}>
                                        <div className="row">
                                            <div
                                                className="col-md-4 pr-0 "
                                                style={{ backgroundColor: "#eee" }}>
                                                <img
                                                    style={{ width: "100%" }}
                                                    src={curElem.thumbnail}
                                                    className="img-fluid" />

                                                <div
                                                    className="row mt-2 youtube-content">
                                                    <div
                                                        className="col-md-12">
                                                        <div
                                                            className="view-visit-download">
                                                            <ul className="list-inline m-0 d-flex justify-content-between">
                                                                <li>
                                                                    <a
                                                                        target="_blank"
                                                                        href={`https://www.youtube.com/watch?v=${curElem.id}`}
                                                                    >
                                                                        <span
                                                                            style={{ fontSize: "10px" }}><i
                                                                                className="fas fa-play-circle"></i></span>Watch
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a style={{ cursor: "pointer" }} onClick={downloadYoutube}>
                                                                        <span>{loader ? <><i className="fas fa-download" />Downloading <i className="fa fa-spinner fa-spin mx-1" /></> : <><i className="fas fa-download" />Download</>}</span>
                                                                    </a>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                            <div
                                                className="col-md-8 pl-0">
                                                <div
                                                    className="youtube-content">
                                                    <h5 className="m-0">{curElem.title}</h5>

                                                    <span
                                                        className="d-block"><a
                                                            target="_blank" href={`https://www.youtube.com/channel/${curElem.channel_id}`}>{curElem.channel_title}</a>- 2 months ago</span>
                                                    <p id="toggleDesc"
                                                        className="youtube-desc" style={{ display: "inline-block", fontWeight: '600' }}>

                                                        {readMore ? curElem.description : curElem.description.slice(0, 200)}
                                                        {
                                                            curElem.description.length > 200 ?
                                                                <button className=" yub_btn" onClick={handleReadMore}>{readMore ? "Read less" : "Read more..."}</button>
                                                                : ""
                                                        }
                                                    </p>

                                                    <div
                                                        className="youtube-tages">
                                                        <ul className="d-flex flex-wrap list-inline">
                                                            {
                                                                curElem.tags ?
                                                                    curElem.tags.length > 0 ?
                                                                        curElem.tags.slice(0, 10).map((item, index) => {
                                                                            return (
                                                                                <li key={index}
                                                                                    style={{
                                                                                        backgroundColor: "#fff",
                                                                                        margin: "3px",
                                                                                        padding: "3px",
                                                                                        color: "#000",
                                                                                        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
                                                                                        fontSize: "10px",
                                                                                        borderRadius: "5px"
                                                                                    }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "10px",
                                                                                            marginRight: "3px"
                                                                                        }}><i
                                                                                            className="fas fa-tag"></i></span>
                                                                                    {item}
                                                                                </li>
                                                                            )
                                                                        })
                                                                        : ''
                                                                    : ''
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                }
            </th>
            <th style={{ width: "50px", fontWeight: "400" }} >{curElem.stats?.views}</th>
            <th style={{ width: "50px", fontWeight: "400" }} >{curElem.stats?.like}</th>
            <th style={{ width: "50px", fontWeight: "400" }} >{curElem.stats?.comments}</th>
            <th style={{ width: "50px", fontWeight: "400" }} >{curElem.stats?.dislike}</th>
        </tr>
    )
}

export default YoutubeCard
