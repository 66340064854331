import React, { useState } from 'react';
import TitleBar from '../../title-bar/TitleBar';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { fetchData, onFetchPlatform } from '../../../Redux/Actions/CommonActions';
import { useNavigate } from 'react-router-dom';
import { onCreateVideo } from '../../../Redux/Actions/VideoActions';
import Platform from './Platform';
import Swal from 'sweetalert2';

const CreateVideo = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [platforms, setPlatforms] = useState(false)
    const [country, setCountry] = useState([])
    const [language, setLanguage] = useState([])
    const [create, setCreate] = useState({
        campaignName: "",
        keyword: "",
        platform: "",
        country: "",
        language: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target

        if (name === "language") {
            const lang = language.find(({ code }) => code === value)

            if (+lang.polly_support === 0) {
                Swal.fire({
                    icon: 'error',
                    title: 'Warning...',
                    text: `We do not have AI voice for this language, thus the video generated will not have any voiceover.`,
                    confirmButtonColor: "#d03e3e"
                })
            }

            setCreate({
                ...create,
                [name]: value
            })
        } else {
            setCreate({
                ...create,
                [name]: value
            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        let data = { ...create }
        let count = country.find(({ country_name }) => country_name === data.country)
        let lang = language.find(({ code }) => code === data.language)
        data = {
            ...data,
            ttsLangCode: `${create.language}-${count.code}`,
            lang: lang.name
        }
        dispatch(onCreateVideo(data, navigate, setLoader))
    }

    const fetchPlatforms = () => {
        let data = {}
        dispatch(onFetchPlatform("fetch-platform", data, setPlatforms))
    }

    const fetchLanguage = () => {
        dispatch(fetchData("fetch-google-language", {}, setLanguage, false, false, false, "shortDataLg"))
    }
    const fetchCountries = () => {
        dispatch(fetchData("countries", {}, setCountry, false, false, false, "shortDataCt"))
    }

    useEffect(() => {
        fetchPlatforms()
        fetchLanguage()
        fetchCountries()
    }, [])

    return (
        <>
            <TitleBar title="Home" />
            <Header />

            <section className='page-wrap'>
                <div className='container'>
                    <h1 className='page-title'>Create Video</h1>
                    <form className='cont-box position-relative' onSubmit={handleSubmit} >
                        <div className='common-input-wrap mt-0'>
                            <label htmlFor="">Campaign Name</label>
                            <input
                                className='common-input'
                                type="text"
                                placeholder='Add Campaign Name'
                                name="campaignName"
                                value={create.campaignName}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className='common-input-wrap'>
                            <label htmlFor="">Shortly Describe The Video Post</label>
                            <input
                                className='common-input'
                                type="text"
                                placeholder='Describe The Video Post'
                                name="keyword"
                                value={create.keyword}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Platform</label>
                                    <select
                                        className='common-input'
                                        name="platform"
                                        value={create.platform}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select Platform</option>
                                        {platforms ?
                                            <>
                                                <Platform
                                                    data={platforms.Facebook}
                                                />
                                                <Platform
                                                    data={platforms.Instagram}
                                                />
                                                <Platform
                                                    data={platforms.LinkedIn}
                                                />
                                                <Platform
                                                    data={platforms.Pinterest}
                                                />
                                                <Platform
                                                    data={platforms.Snapchat}
                                                />
                                                <Platform
                                                    data={platforms.TikTok}
                                                />
                                                <Platform
                                                    data={platforms.Twitter}
                                                />
                                                <Platform
                                                    data={platforms.YouTube}
                                                />
                                            </>
                                            : ""}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Country</label>
                                    <select
                                        className='common-input'
                                        type="text"
                                        value={create.country}
                                        name='country'
                                        placeholder='Describe the image post'
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value={""}>Select Country</option>
                                        {country.length > 0 ?
                                            country.map((curElem, index) => {
                                                return (
                                                    <option
                                                        key={index}
                                                        value={curElem.country_name}
                                                    >
                                                        {curElem.country_name}
                                                    </option>
                                                )
                                            })
                                            : ""}
                                    </select>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Languages</label>
                                    <select
                                        className='common-input'
                                        type="text"
                                        value={create.language}
                                        name='language'
                                        placeholder='Describe the image post'
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value={""}>Select Language</option>
                                        {language.length > 0 ?
                                            language.map((curElem, index) => {
                                                return (
                                                    <option key={index} value={curElem.code}>{curElem.name}</option>
                                                )
                                            })
                                            : ""}
                                    </select>

                                </div>
                            </div>
                        </div>

                        <div className='client-btn'>
                            <button className='site-link ms-0' type='submit'><span>{loader ? <>Creating Script <i className="fa fa-spinner fa-spin mx-1" /></> : "Submit"}</span></button>
                        </div>
                    </form>
                </div>
            </section>

            <Footer />
        </>
    )

}

export default CreateVideo;