import React, { useState } from 'react';
import { BsPlusLg } from 'react-icons/bs'
import TitleBar from '../../title-bar/TitleBar';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import { useDispatch } from 'react-redux';
import { onCreateClient, onGetClientData, onUpdateClient } from '../../../Redux/Actions/ClientAction';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { onUploadMedia } from '../../../Redux/Actions/CommonActions';
import Swal from 'sweetalert2';
import defaultImage from '../../../assets/images/default.png'
import FacebookConnect from './FacebookConnect';
import Youtube from './Youtube';
import LinkedInConnect from './LinkedInConnect';
import VimeoConnect from './VimeoConnect';
import TelegramConnect from './TelegramConnect';
import RedditConnect from './RedditConnect';
import WordpressConnect from './WordpressConnect';
import MediumConnect from './MediumConnect';
import ClientTable from './ClientTable';
import InstagramConnect from './InstagramConnect';
import TwitterConnect from './TwitterConnect';

const AddClient = () => {

    const location = useLocation()
    const dispatch = useDispatch();
    const [loader, setLoader] = useState({
        submit: false,
        upload: false,
        fetch: false,
    })
    const [percent, setPercent] = useState(0)
    const { id } = queryString.parse(location.search)

    const [state, setState] = useState({
        id: "",
        profile: "",
        name: "",
        email: "",
        password: "",
        earning: "",
        frequency: "",
        company: "",
        description: ""
    })

    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    })

    const [socialConnects, setSocialConnects] = useState([]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "password") {
            setPasswordMsg({
                ...passwordMsg,
                validation: false,
                msg: ""
            })
        }
        setState({ ...state, [name]: value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let data = { ...state }
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{6,}$/;

        if (state.id) {
            if (state.password) {
                if (pattern.test(state.password)) {
                    data = {
                        ...data,
                        id: state.id,
                        new_password: data.password
                    }
                    setLoader({ ...loader, submit: true })
                    dispatch(onUpdateClient(data, loader, setLoader))
                } else {
                    setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.", validation: true })
                }
            } else {
                data = {
                    ...data,
                    id: state.id,
                    new_password: data.password
                }
                setLoader({ ...loader, submit: true })
                dispatch(onUpdateClient(data, loader, setLoader))
            }
        }
        else {
            if (pattern.test(state.password)) {
                setLoader({ ...loader, submit: true })
                dispatch(onCreateClient(data, state, setState, loader, setLoader))

            } else {
                setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.", validation: true })
            }
        }







    }


    const getProfileUrl = (url, type) => {
        setState({ ...state, profile: url })
    }

    const handleChangeProfile = (e) => {
        let allowedExt = ['image/png', 'image/jpg', 'image/jpeg']
        const formData = new FormData()
        if (allowedExt.includes(e.target.files[0].type)) {
            if (e.target.files[0].size < 5000000) {
                formData.append('upload_type', "images")
                formData.append('file', e.target.files[0])
                dispatch(onUploadMedia(formData, getProfileUrl, loader, setLoader, setPercent, true))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Max allowed size for images is 5MB!',
                    confirmButtonColor: "#618FCA"
                })
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You have selected invalid file type!',
                confirmButtonColor: "#618FCA"
            })
        }
    }

    const fetchClientData = () => {
        setLoader({ ...loader, fetch: true })
        let data = {
            id: state.id ? state.id : id
        }
        dispatch(onGetClientData(data, state, setState, loader, setLoader, setSocialConnects))
    }

    useEffect(() => {
        if (id) {
            fetchClientData()
        }
    }, [id])

    return (
        <>
            <TitleBar title="Home" />
            <Header />

            {
                loader.fetch ?
                    <div className="loader-sec">
                        <div className="loader" />
                    </div>
                    :
                    <section className='page-wrap'>
                        <div className='container'>
                            <h1 className='page-title'>Add Clients</h1>

                            <div className='cont-box'>
                                <div className='profilePic'>
                                    {loader.upload ?
                                        <div className='profileLoader'>
                                            <i className="fa fa-spinner fa-spin mx-1" style={{ fontSize: "23px", color: "#618fca" }} />
                                        </div>
                                        :
                                        <>
                                            <img alt='' src={state.profile ? state.profile : defaultImage} />
                                            <div className='profilePic-up'>
                                                <BsPlusLg />
                                                <input type="file" name='profile' onChange={handleChangeProfile} />
                                            </div>
                                        </>
                                    }
                                </div>

                                <form onSubmit={handleSubmit}>
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <div className='common-input-wrap'>
                                                <label htmlFor="">Name</label>
                                                <input className='common-input' type="text" name='name' value={state.name} onChange={handleChange} required />
                                            </div>
                                        </div>
                                        <div className='col-lg-3'>
                                            <div className='common-input-wrap'>
                                                <label htmlFor="">Earning $</label>
                                                <input className='common-input'
                                                    type="number"
                                                    name='earning'
                                                    value={state.earning}
                                                    onKeyPress={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                                    onChange={handleChange} required />
                                            </div>
                                        </div>
                                        <div className='col-lg-3'>
                                            <div className='common-input-wrap'>
                                                <label htmlFor="">Frequency</label>
                                                <select className="common-input" id="exampleFormControlSelect1" required name="frequency"
                                                    value={state.frequency}
                                                    onChange={handleChange}
                                                >
                                                    <option value="weekly">Weekly</option>
                                                    <option value="monthly">Monthly</option>
                                                    <option value="yearly">Yearly</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className='common-input-wrap'>
                                                <label htmlFor="">Email</label>
                                                <input className='common-input' type="email" name='email' value={state.email} onChange={handleChange} required />
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className='common-input-wrap'>
                                                <label htmlFor="">Company</label>
                                                <input className='common-input' type="text" name='company' value={state.company} onChange={handleChange} required />
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className='common-input-wrap'>
                                                <label htmlFor="">Password (leave blank for same password)</label>
                                                <input className='common-input' type="password" name='password' onChange={handleChange} required={state.id ? false : true} />
                                                {passwordMsg.validation ?
                                                    <p style={{ fontSize: 13, marginLeft: 5, color: "#DC143C" }}>{passwordMsg.msg}</p> : ""}
                                            </div>
                                            <div className='common-input-wrap mt-3'>
                                                <label htmlFor="">Connect Social Accounts</label>
                                                <div className='client-social mt-2'>
                                                    <ul>
                                                        <li>
                                                            <FacebookConnect checkAccount={state.id ? true : false} editClient={state} fetchClientData={fetchClientData} />
                                                        </li>
                                                        <li>
                                                            <LinkedInConnect checkAccount={state.id ? true : false} editClient={state} fetchClientData={fetchClientData} />
                                                        </li>
                                                        <li>
                                                            <Youtube checkAccount={state.id ? true : false} editClient={state} fetchClientData={fetchClientData} />
                                                        </li>
                                                        <li>
                                                            <VimeoConnect checkAccount={state.id ? true : false} editClient={state} fetchClientData={fetchClientData} />
                                                        </li>
                                                        <li>
                                                            <RedditConnect checkAccount={state.id ? true : false} editClient={state} fetchClientData={fetchClientData} />
                                                        </li>
                                                        <li>
                                                            <TelegramConnect checkAccount={state.id ? true : false} editClient={state} fetchClientData={fetchClientData} />
                                                        </li>
                                                        <li>
                                                            <WordpressConnect checkAccount={state.id ? true : false} editClient={state} fetchClientData={fetchClientData} />
                                                        </li>
                                                        <li>
                                                            <MediumConnect checkAccount={state.id ? true : false} editClient={state} fetchClientData={fetchClientData} />
                                                        </li>
                                                        <li>
                                                            <TwitterConnect checkAccount={false} editClient={state} fetchClientData={fetchClientData} />
                                                        </li>
                                                        <li>
                                                            <InstagramConnect checkAccount={state.id ? true : false} editClient={state} fetchClientData={fetchClientData} />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className='common-input-wrap'>
                                                <label htmlFor="">Description</label>
                                                <textarea className='common-input' name='description' value={state.description} onChange={handleChange} required ></textarea>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='bottom-btn-group'>
                                        <Link to={"/Clients"} type='button' className='site-link bdr mx-2'><span>Back</span></Link>
                                        {state.id ?
                                            <button type='submit' className='site-link ms-0'><span>{loader.submit ? <>Updating <i className="fa fa-spinner fa-spin mx-1" /></> : "Update"}</span></button>
                                            :
                                            <button type='submit' className='site-link ms-0'><span>{loader.submit ? <>Creating <i className="fa fa-spinner fa-spin mx-1" /></> : "Create"}</span></button>
                                        }
                                    </div>
                                </form>

                            </div>
                            <div className='cont-box'>
                                <ClientTable socialConnects={socialConnects} fetchClientData={fetchClientData} />
                            </div>
                        </div>
                    </section >
            }

            <Footer />
        </>
    )

}

export default AddClient;