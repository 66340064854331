import React, { useState } from 'react';
import TitleBar from '../../title-bar/TitleBar';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';


function LodaingScreen() {
    return (
        <>
            <TitleBar title="Home" />
            <Header />

            <section className='page-wrap'>
                <div className='container'>
                    <h1 className='page-title'>Loading Screen</h1>

                    <div className='cont-box loadingWrap' style={{height:'500px'}}>
                        <div className='loadingWrap-cont'>
                           <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                           <p>It's may takes up to 2 minutes, <br /> Please be patient!</p>
                        </div>
                    </div>

                </div>
            </section>


            <Footer />

            <div className='loading-back'></div>
        </>
    )

}

export default LodaingScreen;