import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"

export const onPushContentStream = (data, setLoader, handleClose) => (dispatch, getState) => {
    commonAxios("push-to-content-stream", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
            handleClose()
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
            handleClose()
            console.log(err)
        })
}
export const onFetchStreamList = (data, setContentStream) => (dispatch, getState) => {
    commonAxios("fetch-all-content-stream", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setContentStream(res.data)
            } else {
                dispatch(setAlert(res.msg, "danger"))
                setContentStream([])
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}