import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { useDispatch, useSelector } from 'react-redux';
import UploadMusic from '../../../../CommonComponent/UploadMusic';
import MediaLibrary from './MediaLibrary';
import { addBgMusic } from '../../../../../Redux/Actions/VideoActions';
import { useEffect } from 'react';

const Music = () => {
  const dispatch = useDispatch()
  const campaign = useSelector(state => state.video.campaign)
  const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
  const [selectedAudio, setSelectedAudio] = useState(false)

  const addMusic = (url, duration) => {

    dispatch(addBgMusic(url, selectedSlideIndex, duration))
  }

  useEffect(() => {
    setSelectedAudio(campaign.backgroundAudio.source)
  }, [campaign.backgroundAudio.source])

  return (
    <>
      <div className='audio-cont'>
        <div className='inner-tab'>
          <Tab.Container defaultActiveKey="first" justify>
            <Nav variant="n" className="">
              <Nav.Item>
                <Nav.Link eventKey="first">Library</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">Upload</Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="first">
                <MediaLibrary
                  onSelectAudio={addMusic}
                  selectedAudio={selectedAudio}
                  setSelectedAudio={setSelectedAudio}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <UploadMusic
                  type="music"
                  onSelectAudio={addMusic}
                  selectedAudio={selectedAudio}
                  setSelectedAudio={setSelectedAudio}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>

    </>
  )

}

export default Music;