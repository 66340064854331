import React from 'react'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import TitleBar from '../title-bar/TitleBar'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from "query-string"
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { onCheckStatus } from '../../Redux/Actions/CommonActions'

let interval = false
const LoaderPage = () => {
    
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const { id, type } = queryString.parse(location.search)
    const [status, setStatus] = useState({
        text: "",
        style: {}
    })


    useEffect(() => {
        if (type === "posts" || type === "memes" || type === "quotes") {
            interval = setInterval(() => {
                let data = {
                    imageId: id
                }
                dispatch(onCheckStatus("fetch-status-image", data, status, setStatus, interval, navigate, type))
            }, 5000)
            let data = {
                imageId: id
            }
            dispatch(onCheckStatus("fetch-status-image", data, status, setStatus, interval, navigate, type))
        }
        if (type === "video") {
            interval = setInterval(() => {
                let data = {
                    videoId: id
                }
                dispatch(onCheckStatus("fetch-status-video", data, status, setStatus, interval, navigate, type))
            }, 5000)

            let data = {
                videoId: id
            }
            dispatch(onCheckStatus("fetch-status-video", data, status, setStatus, interval, navigate, type))
        }

        return () => {
            clearInterval(interval)
        }
    }, [])
    return (
        <>
            <TitleBar title="Waiting" />
            <Header />
            <div className='full-loader'>
                <div class="lds-ripple"><div></div><div></div></div>
                <h2 className='pt-3' style={status.style}>{status.text}</h2>
            </div>
            {/* <Footer /> */}

        </>
    )
}

export default LoaderPage