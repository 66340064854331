import React, { useEffect } from 'react'
import { useState } from 'react'
import { GiCheckMark } from 'react-icons/gi'
import { Link } from 'react-router-dom'
import { fetchData } from '../../../Redux/Actions/CommonActions'
import { useDispatch } from 'react-redux'

const ModalStream = (props) => {
    const dispatch = useDispatch()
    const [toggle, setToggle] = useState(false)
    const [images, setImages] = useState([])
    const [quotes, setQuotes] = useState([])
    const [texts, setText] = useState([])
    const [memes, setMemes] = useState([])
    const [videos, setVideos] = useState([])
    const [loader, setLoader] = useState({
        fetch: false,
        selectedIndex: -1,
        type: ""
    })

    const handleToggle = (e) => {
        props.setScheduleStream({
            ...props.scheduleStream,
            content_stream_id: null

        })
        setLoader({
            ...loader,
            selectedIndex: -1,
            type: ""
        })
        setToggle(e.target.checked)
    }

    const handleChooseContent = (curElem, index, type) => {
        setLoader({
            ...loader,
            selectedIndex: index,
            type: type
        })
        props.setScheduleStream({
            ...props.scheduleStream,
            content_stream_id: -1,
            contentData: {
                ...props.scheduleStream.contentData,
                title: curElem.name,
                description: type === "image" || type === "quotes" || type === "memes" ? curElem.meta.replaceAll("?", "") : type === "video" ? curElem.videoData.scene : type === "text" ? curElem.script.replaceAll("?", "") : "",
                thumbnail: type === "image" || type === "quotes" || type === "memes" ? curElem.thumbnail : type === "video" ? curElem.videoData.thumbnailUrl : type === "text" ? curElem.thumbnail : "",
                url: type === "image" || type === "quotes" || type === "memes" ? curElem.url : type === "video" ? curElem.videoData.videoUrl : "",
                script: type === "text" ? curElem.text : "",
                type: type
            }
        })
    }
    const fetchImages = () => {
        let data = {}
        dispatch(fetchData("fetch-image-campaign", data, setImages))
    }

    const fetchQuotes = () => {
        let data = {}
        dispatch(fetchData("fetch-quotes-campaign", data, setQuotes))
    }
    const fetchText = () => {
        let data = {}
        dispatch(fetchData("fetch-text-campaign", data, setText))
    }

    const fetchMemes = () => {
        let data = {}
        dispatch(fetchData("fetch-memes-campaign", data, setMemes))
    }

    const fetchVideos = () => {
        let data = {}
        dispatch(fetchData("fetch-video-campaign", data, setVideos, loader, setLoader))
    }

    useEffect(() => {
        setLoader({
            ...loader,
            fetch: true
        })
        fetchImages()
        fetchVideos()
        fetchQuotes()
        fetchMemes()
        fetchText()
    }, [])

    return (
        <div className='streame-list'>
            <div className='common-input-wrap'>
                <div className='switch-label d-flex justify-content-between '>
                    <h6>Stream</h6>
                    <label className="switch">
                        <input
                            className='input solid'
                            name="imageType"
                            type="checkbox"
                            checked={toggle}
                            onChange={(e) => handleToggle(e)}
                        />
                        <span className="sliderN round"></span>
                    </label>
                    <h6>Content</h6>
                </div>
            </div>
            <hr />

            {toggle ?
                <>
                    <h6>Your Created Videos</h6>
                    <div className="row mb-5">
                        {videos.length > 0 ?
                            videos.filter((curElem) => {
                                return curElem.videoData ? curElem.videoData.videoUrl : null
                            }).map((curElem, index) => {
                                return (
                                    <div className="col-md-2" key={index}>
                                        <div
                                            className='streame-list-single'
                                            onClick={() => handleChooseContent(curElem, index, "video")}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className='streame-list-img' style={{ position: 'relative' }}>
                                                <img alt='' src={curElem.videoData ? curElem.videoData.thumbnailUrl : curElem.thumbnail} />
                                                {index === loader.selectedIndex && loader.type === "video" ?
                                                    <div className='select-tile-style' style={{ zIndex: 9999 }}>
                                                        <GiCheckMark />
                                                    </div> : ""}
                                            </div>
                                            <h6>{curElem.name}</h6>
                                        </div>
                                    </div>
                                )
                            }) :
                            <div className='text-center mt-3' style={{ width: "100%" }}>
                                {loader.fetch ?
                                    <i className="fa fa-spinner fa-spin fetch-loader" />
                                    :
                                    <span style={{ fontSize: 15, color: "#00000" }}>
                                        You have not created any videos yet!
                                    </span>
                                }
                            </div>
                        }
                    </div>

                    <hr />
                    <h6>Your Created Posts</h6>
                    <div className="row mb-5">
                        {images.length > 0 ?
                            images.filter((curElem) => {
                                return curElem.url
                            }).map((curElem, index) => {
                                return (
                                    <div className="col-md-2" key={index}>
                                        <div
                                            className='streame-list-single'
                                            onClick={() => handleChooseContent(curElem, index, "image")}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className='streame-list-img' style={{ position: 'relative' }}>
                                                <img alt='' src={curElem.thumbnail} />
                                                {index === loader.selectedIndex && loader.type === "image" ?
                                                    <div className='select-tile-style' style={{ zIndex: 9999 }}>
                                                        <GiCheckMark />
                                                    </div> : ""}
                                            </div>
                                            <h6>{curElem.name}</h6>
                                        </div>
                                    </div>
                                )
                            }) :
                            <div className='text-center mt-3' style={{ width: "100%" }}>
                                {loader.fetch ?
                                    <i className="fa fa-spinner fa-spin fetch-loader" />
                                    :
                                    <span style={{ fontSize: 15, color: "#00000" }}>
                                        You have not created any images yet!
                                    </span>
                                }
                            </div>
                        }
                    </div>

                    <hr />
                    <h6>Your Created Quotes</h6>
                    <div className="row mb-5">
                        {quotes.length > 0 ?
                            quotes.filter((curElem) => {
                                return curElem.url
                            }).map((curElem, index) => {
                                return (
                                    <div className="col-md-2" key={index}>
                                        <div
                                            className='streame-list-single'
                                            onClick={() => handleChooseContent(curElem, index, "quotes")}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className='streame-list-img' style={{ position: 'relative' }}>
                                                <img alt='' src={curElem.thumbnail} />
                                                {index === loader.selectedIndex && loader.type === "quotes" ?
                                                    <div className='select-tile-style' style={{ zIndex: 9999 }}>
                                                        <GiCheckMark />
                                                    </div> : ""}
                                            </div>
                                            <h6>{curElem.name}</h6>
                                        </div>
                                    </div>
                                )
                            }) :
                            <div className='text-center mt-3' style={{ width: "100%" }}>
                                {loader.fetch ?
                                    <i className="fa fa-spinner fa-spin fetch-loader" />
                                    :
                                    <span style={{ fontSize: 15, color: "#00000" }}>
                                        You have not created any quotes yet!
                                    </span>
                                }
                            </div>
                        }
                    </div>

                    <hr />
                    <h6>Your Created Memes</h6>
                    <div className="row mb-5">
                        {memes.length > 0 ?
                            memes.filter((curElem) => {
                                return curElem.url
                            }).map((curElem, index) => {
                                return (
                                    <div className="col-md-2" key={index}>
                                        <div
                                            className='streame-list-single'
                                            onClick={() => handleChooseContent(curElem, index, "memes")}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className='streame-list-img' style={{ position: 'relative' }}>
                                                <img alt='' src={curElem.thumbnail} />
                                                {index === loader.selectedIndex && loader.type === "memes" ?
                                                    <div className='select-tile-style' style={{ zIndex: 9999 }}>
                                                        <GiCheckMark />
                                                    </div> : ""}
                                            </div>
                                            <h6>{curElem.name}</h6>
                                        </div>
                                    </div>
                                )
                            }) :
                            <div className='text-center mt-3' style={{ width: "100%" }}>
                                {loader.fetch ?
                                    <i className="fa fa-spinner fa-spin fetch-loader" />
                                    :
                                    <span style={{ fontSize: 15, color: "#00000" }}>
                                        You have not created any memes yet!
                                    </span>
                                }
                            </div>
                        }
                    </div>

                    <hr />
                    <h6>Your Created Text</h6>
                    <div className="row mb-5">
                        {texts.length > 0 ?
                            texts.map((curElem, index) => {
                                return (
                                    <div className="col-md-2" key={index}>
                                        <div
                                            className='streame-list-single'
                                            onClick={() => handleChooseContent(curElem, index, "text")}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className='streame-list-img' style={{ position: 'relative' }}>
                                                <img alt='' src={curElem.thumbnail} />
                                                {index === loader.selectedIndex && loader.type === "text" ?
                                                    <div className='select-tile-style' style={{ zIndex: 9999 }}>
                                                        <GiCheckMark />
                                                    </div> : ""}
                                            </div>
                                            <h6>{curElem.name}</h6>
                                        </div>
                                    </div>
                                )
                            }) :
                            <div className='text-center mt-3' style={{ width: "100%" }}>
                                {loader.fetch ?
                                    <i className="fa fa-spinner fa-spin fetch-loader" />
                                    :
                                    <span style={{ fontSize: 15, color: "#00000" }}>
                                        You have not created any memes yet!
                                    </span>
                                }
                            </div>
                        }
                    </div>


                </>
                :
                <div className="row">
                    {props.stream.length > 0 ?
                        props.stream.map((curElem, index) => {
                            return (
                                <div className="col-md-2" key={index}>
                                    <div
                                        className='streame-list-single'
                                        onClick={() => props.handleChange("content_stream_id", curElem.id)}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <div className='streame-list-img' style={{ position: 'relative' }}>
                                            <img alt='' src={curElem.stream.thumbnails} />
                                            {+props.scheduleStream.content_stream_id === +curElem.id ?
                                                <div className='select-tile-style' style={{ zIndex: 9999 }}>
                                                    <GiCheckMark />
                                                </div> : ""}
                                        </div>
                                        <h6>{curElem.title}</h6>
                                    </div>
                                </div>
                            )
                        }) :
                        <div className='text-center mt-3' style={{ width: "100%" }}>
                            <span>
                                You do not have any stream yet! <Link to="/content-stream/add-stream">Click Here</Link> to create!
                            </span>
                        </div>
                    }
                </div>}
        </div>
    )
}

export default ModalStream