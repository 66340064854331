import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import mediumImage from "../../../assets/images/medium-logo.png";
import { saveMedium } from "../../../Redux/Actions/ClientAction";
import { Modal } from "react-bootstrap";


const MediumConnect = ({ checkAccount, editClient, fetchClientData, fromSocial }) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [loader, setLoader] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [mediumInput, setMediumInput] = useState({
        user_id: auth.user.id,
        clientId: editClient.id,
        access_token: null
    })

    const onInputChange = (e) => {
        setMediumInput({ ...mediumInput, access_token: e.target.value });
    }
    const closeConnectModel = () => {
        setShowPopup(false);
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setLoader(true);

        dispatch(saveMedium(mediumInput, setShowPopup, setLoader, fetchClientData));
    }

    useEffect(() => {
        setMediumInput({ ...mediumInput, clientId: editClient.id, user_id: auth.user.id });
    }, [editClient])

    return (
        <>
            <span style={{ cursor: "pointer" }} className={`${checkAccount === false ? 'hide-button' : ''}`} onClick={(e) => setShowPopup(true)} >
                {fromSocial ?
                    <a className='site-link integr_card mt-4' style={{ padding: "2px 20px", fontSize: "14px", background: '#4fc5e1', borderColor: "#4fc5e1" }} > <span> Connect</span> </a>
                    :
                    <img className={`${checkAccount === false ? 'client-img-opacity' : ''}`} src={mediumImage} alt="telegram" />
                }
            </span>

            < Modal className='theme-modal xl' show={showPopup} onHide={closeConnectModel} centered scrollable >
                <form onSubmit={(e) => onFormSubmit(e)}>
                    <Modal.Body>
                        <div className='info-tab'>
                            <h3>Connect Medium</h3>
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <div className='common-input-wrap'>
                                        <label htmlFor="">Access Token</label>
                                        <input
                                            className='common-input'
                                            type="text"
                                            name="access_token" onChange={(e) => onInputChange(e)}
                                            placeholder="Enter Access Token" required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='site-link bdr' type="button" onClick={(e) => closeConnectModel(e)}><span>Cancel</span></button>
                        <button className='site-link' type="submit" disabled={loader}><span>{loader ? <>Connecting <i className="fa fa-spinner fa-spin mx-1" /></> : "Connect"}</span></button>
                    </Modal.Footer>
                </form>
            </Modal>

        </>
    )
}

export default MediumConnect;