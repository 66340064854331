import React from 'react';
import TitleBar from '../../title-bar/TitleBar';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import HomeBanner from './HomeBanner';
import VideosCardsSection from './VideosCardsSection';
import ImageCardsSection from './ImageCardsSection';
import Webinar from './Webinar';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { onFetchStreamList } from '../../../Redux/Actions/MyContentAction';
import { useEffect } from 'react';

const Home = () => {
    const rebrand = useSelector(state => state.rebrand)
    const dispatch = useDispatch()
    const [contentStream, setContentStream] = useState([]);

    const fetchList = () => {
        let data = {}
        dispatch(onFetchStreamList(data, setContentStream))
    }

    useEffect(() => {
        fetchList()
        window.scrollTo(0, 0)
    }, [])


    return (
        <>
            <TitleBar title="Home" />
            <div className='front-page'>
                <Header />
            </div>

            <HomeBanner />
            <VideosCardsSection contentStream={contentStream} />
            <ImageCardsSection contentStream={contentStream} />

            <section className='image-block'>
                <div className='container sm'>
                    <div className='image-block-single'>
                        <div className='row align-items-center'>
                            <div className='col-sm-6 col-xl-7'>
                                <div className='image-block-img'><img alt='' src={require('../../../assets/images/block-img-1.png')} /></div>
                            </div>
                            <div className='col-sm-6 col-xl-5'>
                                <div className='image-block-txt'>
                                    <h6>Hands Free</h6>
                                    <h2>Take a Year Worth of Content:</h2>
                                    <p>Create a robust content library effortlessly with {rebrand.data ? rebrand.data.name : "AI SOCIALS"}. Generate a year's worth of diverse, high-quality posts in moments, ensuring your social media calendar is always brimming with fresh ideas.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='image-block-single'>
                        <div className='row align-items-center'>
                            <div className='col-sm-6 col-xl-7'>
                                <div className='image-block-img'><img alt='' src={require('../../../assets/images/block-img-2.png')} /></div>
                            </div>
                            <div className='col-sm-6 col-xl-5'>
                                <div className='image-block-txt'>
                                    <h6>Clients</h6>
                                    <h2>Auto Find Clients for Your Services: </h2>
                                    <p>Let {rebrand.data ? rebrand.data.name : "AI SOCIALS"} do the legwork for you. Utilize advanced algorithms to identify potential clients interested in your services, streamlining your outreach and expanding your business opportunities.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='image-block-single'>
                        <div className='row align-items-center'>
                            <div className='col-sm-6 col-xl-7'>
                                <div className='image-block-img'><img alt='' src={require('../../../assets/images/block-img-3.png')} /></div>
                            </div>
                            <div className='col-sm-6 col-xl-5'>
                                <div className='image-block-txt'>
                                    <h6>Clients</h6>
                                    <h2>Take on 100s of Clients Effortlessly: </h2>
                                    <p>Scale your business seamlessly with {rebrand.data ? rebrand.data.name : "AI SOCIALS"}. From streamlined communication to automated tasks, efficiently manage a large client base while maintaining personalized interactions and exceptional service quality.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Webinar />
            <Footer />
        </>
    )

}

export default Home;