import React, { useEffect, useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import { FiChevronDown, FiDownload, FiUpload, FiEdit3, FiTrash2 } from 'react-icons/fi'
import { CgScreen } from "react-icons/cg"
import { MdOutlinePlayCircle, MdSchedule } from 'react-icons/md'
import SweetAlert from './SweetAlert';
import { useDispatch } from 'react-redux';
import { onFetchStatus } from '../../Redux/Actions/VideoActions';
import { ProgressBar } from 'react-bootstrap';
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { onDeleteCampaign } from '../../Redux/Actions/CommonActions';
import PushModal from '../pages/MyContent/PushModal';
import { useSelector } from 'react-redux';
import { baseURL } from '../../Global/Global';

const VideoCard = ({ curElem, fetchVideos, contentStream }) => {
    let interval = false
    let date = new Date()
    let h = date.getHours() <= 9 ? `0${date.getHours()}` : date.getHours()
    let m = date.getMinutes() <= 9 ? `0${date.getMinutes()}` : date.getMinutes()
    let s = date.getSeconds() <= 9 ? `0${date.getSeconds()}` : date.getSeconds()
    let time = `${h}:${m}:${s}`

    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [objData, setObjData] = useState(false)

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const [membership, setMembership] = useState([])

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
    })
    const downloadVideo = () => {
        let a = document.createElement('a')
        a.href = objData.videoUrl
        a.download = "download.mp4"
        a.target = "_blank"
        a.click()
    }

    const onDelete = (tId) => {
        setSweet({
            ...sweet,
            enable: true,
            id: tId
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete"
        })
    }

    const performDelete = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting..."
        })
        dispatch(onDeleteCampaign("delete-video-campaign", data, fetchVideos, setSweet))
    }

    const fetchStatus = (interval) => {
        let data = {
            id: curElem.videoData.id
        }
        dispatch(onFetchStatus(data, setObjData, interval))
    }

    const handleOCP = (id) => {
        window.open(`${baseURL}ocp/${id}`, '_blank');
    }


    useEffect(() => {
        if (curElem.videoData) {
            if (curElem.videoData.status === "Rendering" || curElem.videoData.status === "Download Resources") {
                setObjData(curElem.videoData)
                interval = setInterval(() => {
                    fetchStatus(interval)
                }, 5000)
            } else {
                setObjData(curElem.videoData)
            }

            return () => {
                clearInterval(interval)
            }
        }
    }, [curElem])

    useEffect(() => {
        if (auth) {
            if (auth.user.membership) {
                setMembership(auth.user.membership.split("__"))
            }
        }
    }, [auth])

    return (
        <div className='col-sm-4'>
            <div className='video-listing-single'>
                <div className='video-listing-img'>
                    <img alt='' src={objData.thumbnailUrl ? objData.thumbnailUrl + `?${time}not-from-cache-please` : curElem.thumbnail} />
                    <div className='play-icon'><MdOutlinePlayCircle /></div>
                </div>
                <div className='video-listing-bottom'>
                    <div className='video-listing-info'>
                        <h3 className='text-capitalize'>{curElem.name}</h3>
                        <p data-tooltip-id={`my-tooltip-${curElem.id}`}>{objData.status}</p>
                    </div>
                    <div className='video-listing-action'>
                        <Dropdown>
                            <Dropdown.Toggle variant="hh" id="dropdown-basic">
                                <FiChevronDown />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <ul>
                                    {objData.videoUrl ?
                                        <>
                                            <li style={{ cursor: "pointer" }} onClick={downloadVideo}>
                                                <a><span><FiDownload /> </span> Download</a>
                                            </li>
                                            <li style={{ cursor: "pointer" }} onClick={() => setShow(true)}><a ><span><FiUpload /> </span> Push</a></li>
                                            {membership.includes("enterprise") || membership.includes("bundle") || membership.includes("fastpass") ?
                                                <li style={{ cursor: "pointer" }} onClick={() => handleOCP(curElem.id)}><a ><span><CgScreen /> </span> OCP</a></li> : ""}

                                            <li> <Link to={"/schedule"}><span><MdSchedule /></span> Schedule</Link></li>
                                        </> : ""}
                                    {+auth.user.is_client_account === 1 ? "" :

                                        <>
                                            <li><Link to={+curElem.status === 2 ? `/my-content/content-set?id=${curElem.id}` : `/my-content/editor?id=${curElem.id}`}><span><FiEdit3 /> </span> Edit</Link></li>
                                            <li style={{ cursor: "pointer" }} onClick={() => onDelete(curElem.id)}>
                                                <a><span><FiTrash2 /></span> Delete</a>
                                            </li>
                                        </>}
                                </ul>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    {objData.status === "Render Failed" ?
                        <Tooltip
                            id={`my-tooltip-${curElem.id}`}
                            style={{ backgroundColor: "#424b6c", color: "#fff", maxWidth: 500 }}
                            place="bottom"
                            content={objData?.logFile?.errorMessage}
                        /> : ""}
                </div>

                <div style={{ width: "100%", position: "relative" }}>

                    {objData.status === "Rendering" || objData.status === "Downloading Resources" ?
                        <>
                            <ProgressBar style={{ backgroundColor: "#618FCA" }} animated
                                now={objData.renderProgress}
                            />
                            <span className='progress-percent'>{objData.renderProgress}%</span>
                        </>
                        : ""}
                </div>
            </div>

            <PushModal
                curElem={curElem}
                type="video"
                show={show}
                handleClose={handleClose}
                contentStream={contentStream}
            />
            <SweetAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this campaign?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
            />
        </div >
    )
}

export default VideoCard