import axios from "axios"
import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"
import WebFont from "webfontloader"
import { onPushContentStream } from "./MyContentAction"

export const fetchData = (url, data, setState, loader, setLoader, limit, isShort) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token).
        then((res) => {
            if (res.status) {
                let arr = [...res.data]
                if (limit) {
                    arr = arr.slice(0, limit)
                }
                if (isShort === "shortDataCt") {
                    arr.sort((a, b) => {
                        if (a.country_name > b.country_name) {
                            return 1
                        } else {
                            return -1
                        }
                    })
                }
                if (isShort === "shortDataLg") {
                    arr.sort((a, b) => {
                        if (a.name > b.name) {
                            return 1
                        } else {
                            return -1
                        }
                    })
                }
                setState(arr)
            }
            else {
                if (url === "fetch-dashboard-data") { }
                else {
                    setState([])
                }
            }
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
        }).catch((err) => {
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
            console.log(err)
        })
}

export const fetchMedia = (url, data, state, setState, loader, setLoader, setError) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token).
        then((res) => {
            if (res.status) {
                if (data.page_number === 1) {
                    setState(res.data)
                } else {
                    let arr = [...state]
                    setState(arr.concat(res.data))
                }
            }
            else {
                // setState([])
                if (setError) {
                    setError(res.msg)
                }
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                fetch: false
            })
            console.log(err)
        })
}

export const onUploadMedia = (data, fetchMedia, loader, setLoader, setPercent, type) => (dispatch, getState) => {
    const config = {
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            setPercent(percent);
        },
        headers: {
            'Authorization': getState().auth.token
        },
    }
    setLoader({
        ...loader,
        upload: true
    })
    commonAxios("upload-file", data, dispatch, false, config)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                if (type) {
                    fetchMedia(res.data.path, type)
                } else {
                    fetchMedia()
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setPercent(0)
            setLoader({
                ...loader,
                upload: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                upload: false
            })
            console.log(err)
        })
}

export const onCheckStatus = (url, data, status, setStatus, interval, navigate, type) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (+res.data[0].status === 1) {
                    clearInterval(interval)
                    if (type === "posts" || type === "memes" || type === "quotes") {
                        navigate(`/my-content/image-editor?id=${res.data[0].id}&type=${type}`)
                    } else {
                        navigate(`/my-content/editor?id=${res.data[0].id}`)
                    }
                }
                else {
                    setStatus({
                        ...status,
                        text: res.data[0].statusText.message,
                        style: res.data[0].statusText.style
                    })
                }
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onGenerateAi = (data, setImage, loader, setLoader) => (dispatch, getState) => {
    commonAxios("generate-ai-image", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setImage(res.data)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                generate: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                generate: false
            })
            console.log(err)
        })
}

export const onFetchFont = (setFonts, toLoad) => (dispatch) => {
    const fontNotSet = ["Helvetica", "Bebas", "palatino linotype", "Subjectivity", "LEMON MILK", "Gill Sans Ultra Bold", "Gilroy", "MS Reference Sans Serif", "MS Serif"
        , "Microsoft JhengHei", "Times New Roman", "MS UI Gothic", "Bookman Old Style", "Franklin Gothic Book", "Gill Sans MT", "Eras Light ITC", "Microsoft Sans Serif", "Microsoft YaHei UI", "Helvetica Now Display", "Gadugi", "Tw Cen MT"
        , "Ebrima", "Subjectivity Serif", "Clarendon", "Segoe UI Emoji", "Fashion Fetish Heavy", "Bastian Sidney", "Palatino Linotype", "Integral CF", "Steelfish", "Monument Extended", "Metropolis", "SignPainter HouseScript", "ChunkFive", "Franklin Gothic", "Agency FB", "Marchelina Script", "Franklin Gothic Medium", "Nexa Bold", "Spartwell", "Spartwell"
        , "Akira Expanded", "Calibri", "Zeyada", "Droid Serif", "Droid Sans Mono", "Droid Sans"]

    axios({
        method: 'POST',
        url: 'https://adminserver.reelapps.io/fetch-fonts',
        data: {},
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {
        if (res.data.status === true) {
            let arr = res.data.data.filter(curElem => {
                return !fontNotSet.includes(curElem.value)
            }).map((item) => {
                return (
                    item.value
                )
            })
            arr = arr.concat('Inter')

            arr = arr.sort((a, b) => {
                return a > b ? 1 : -1
            })
            if (toLoad) {
                WebFont.load({
                    google: {
                        families: arr
                    }
                })
            } else {
                setFonts(arr);
            }
        }
    }).catch(err => {
        console.log(err)
    })
}

export const onDeleteMedia = (formData, fetchData, setSweet) => (dispatch, getState) => {
    commonAxios("delete-user-uploaded-file", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
                dispatch(setAlert(res.msg, 'success'));
            }
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete"
            })
        }).catch((error) => {
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete"
            })
            console.log(error)
        })
}

export const onFetchPlatform = (url, data, setState) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onDeleteCampaign = (url, data, fetchData, setSweet) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete"
            })
        }).catch((err) => {
            console.log(err)
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete"
            })
        })
}
