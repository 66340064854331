import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { onUpdateStream } from '../../../Redux/Actions/StreamActions'
import draftToHtml from "draftjs-to-html";
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


const StreamEditModal = (props) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [details, setDetails] = useState({
        title: "",
        description: "",
        id: "",
    })

    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [checkEdit, setCheckEdit] = useState(false);

    const handleChange = (e, type) => {
        if (type === "formated") {
            setCheckEdit(true)
            setDetails({
                ...details,
                description: draftToHtml(e)
            })
            setEditorState(
                EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                        convertFromHTML(draftToHtml(e))
                    )
                )
            )
        } else {
            const { name, value } = e.target
            setDetails({
                ...details,
                [name]: value
            })
        }

    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        dispatch(onUpdateStream(details, props.fetchData, props.handleClose, setLoader))
    }

    useEffect(() => {
        if (props.data) {
            const blocksFromHtml = htmlToDraft(props.data.description);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            setEditorState(EditorState.createWithContent(contentState))
            setDetails({
                ...details,
                title: props.data.title,
                description: props.data.description,
                id: props.data.id,
            })
        }
    }, [props.data])

    return (
        <Modal className='theme-modal xl' show={props.show} onHide={props.handleClose} centered scrollable>
            <form onSubmit={handleSubmit}>

                <Modal.Body>
                    <div className='info-tab'>
                        <h3>Update File Title & Description</h3>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className='common-input-wrap' style={{ position: "relative" }}>
                                    <label htmlFor="">Title <span style={{ position: "absolute", right: 0, fontSize: 15 }}>{details.title.length}/20</span></label>
                                    <input
                                        className='common-input'
                                        type="text"
                                        name='title'
                                        placeholder='Edit Stream Title'
                                        value={details.title}
                                        onChange={handleChange}
                                        maxLength={20}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='col-sm-12'>
                                <div className='common-input-wrap'>
                                    {props.data.type === "formatted" ? <>

                                        {
                                            checkEdit ?
                                                <Editor
                                                    toolbar={{
                                                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'remove', 'history']
                                                    }}
                                                    defaultEditorState={editorState}
                                                    // editorState={editorState}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                     onContentStateChange={(e) => handleChange(e, "formated")}
                                                />
                                                : <Editor
                                                    toolbar={{
                                                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'remove', 'history']
                                                    }}
                                                    // defaultEditorState={editorState}
                                                    editorState={editorState}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                     onContentStateChange={(e) => handleChange(e, "formated")}
                                                />
                                        }
                                        {/* <Editor

                                            editorState={editorState}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onContentStateChange={(e) => handleChange(e, "formated")}
                                        /> */}
                                    </> : <>

                                        <label htmlFor="">Description</label>
                                        <textarea
                                            className='common-input'
                                            type="text"
                                            placeholder='Edit Stream Description'
                                            name='description'
                                            value={details.description}
                                            onChange={handleChange}
                                            required
                                        />
                                    </>}

                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='site-link bdr' type='button' onClick={props.handleClose}><span>Cancel </span></button>
                    <button className='site-link' type='submit'><span>{loader ? <>Updating <i className="fa fa-spinner fa-spin mx-1" /></> : "Update"}</span></button>
                </Modal.Footer>
            </form>

        </Modal>
    )
}

export default StreamEditModal