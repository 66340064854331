import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { BsCaretDownFill } from 'react-icons/bs'
import { MdPersonOutline, MdLockOutline, MdPersonAddAlt, MdStarBorder, MdOutlineHelpOutline, MdOutlineLogout, MdBrightnessMedium } from 'react-icons/md'
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, onChangeTheme } from "../../Redux/Actions/AuthActions";
import brightness from "../../assets/images/brightness.png"

const Header = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const rebrand = useSelector(state => state.rebrand)
    const [scroll, setScroll] = useState(false)
    const [theme, setTheme] = useState(false)

    const handleChangeTheme = () => {
        dispatch(onChangeTheme(!theme, setTheme))
    }

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50);
        });
    }, []);

    const [isActive, setActive] = useState(false);
    const toggleClass = () => {
        setActive(!isActive);
    }

    const handleOut = () => {
        dispatch(logoutUser())

    }

    useEffect(() => {
        // Get the value of a cookie using document.cookie
        const cookiesArray = document.cookie.split('; ');
        for (const cookie of cookiesArray) {
            const [name, value] = cookie.split('=');
            if (name === 'theme') {
                setTheme(value === "false" || !value ? false : true)
                break;
            } else {
                setTheme(false)
            }
        }
    }, [])


    return (
        <>
            <header className={scroll ? "site-header fixed" : "site-header"}>
                <div className="container">
                    <div className="header-main">
                        <div className="header-left">
                            <NavLink className="logo" to="/home">
                                <img className="logo-light" alt="" src={rebrand.data ? rebrand.data.logo : require('../../assets/images/logo.png')} />
                                <img className="logo-dark" alt="" src={rebrand.data ? rebrand.data.logo : require('../../assets/images/logo-dark.png')} />

                            </NavLink>
                            <div className={isActive ? 'mobClick open' : 'mobClick'} onClick={toggleClass}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className={isActive ? 'site-nav act' : 'site-nav'} onClick={toggleClass}>
                                <ul>
                                    <li><NavLink to="/home">Dashboard</NavLink></li>
                                    <li>
                                        <NavLink to="/my-content">My Content</NavLink>
                                        <ul>
                                            <li><NavLink to="/my-content">My Contents</NavLink></li>
                                            {+auth.user.is_client_account === 1 ? "" :
                                                <>
                                                    <li><NavLink to="/my-content/create-video">Create Videos</NavLink></li>
                                                    <li><NavLink to={`/my-content/create-image?type=posts`}>Create Posts</NavLink></li>
                                                    <li> <NavLink to="/my-content/create-text">Create Texts</NavLink></li>
                                                    <li> <NavLink to={`/my-content/create-image?type=quotes`}>Create Quotes</NavLink></li>
                                                    <li> <NavLink to={`/my-content/create-memes`}>Create Memes</NavLink></li>
                                                </>}
                                        </ul>
                                    </li>
                                    {+auth.user.is_client_account === 1 || +auth.user.is_virtual_account === 1 ? "" :
                                        <li><NavLink to="/clients">Clients</NavLink></li>}
                                    {+auth.user.is_client_account === 1 ?
                                        <li><NavLink to="/socials">Socials</NavLink></li> : ""}
                                    <li><NavLink to="/content-stream">Content Stream</NavLink></li>
                                    <li><NavLink to="/schedule">Schedule</NavLink></li>
                                    <li><NavLink to="/trends">Trends</NavLink></li>
                                    <li><NavLink to="/report">Report</NavLink></li>
                                </ul>
                            </div>
                        </div>
                        <div className="header-right">
                            <div className="profile-drop">
                                <Dropdown>
                                    <Dropdown.Toggle variant="tt">
                                        <div className="pro-pic">
                                            <img alt="" src={auth.user.profile} />
                                            <div className="pro-stat"></div>
                                        </div>
                                        <BsCaretDownFill />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <ul>
                                            <li><NavLink to="/my-account"><span><MdPersonOutline /> </span> My Account</NavLink></li>
                                            <li><NavLink to="/privacy"><span><MdLockOutline /> </span> Privacy</NavLink></li>
                                            {+auth.user.is_client_account === 1 ? "" :
                                                <>
                                                    {+auth.user.is_virtual_account === 1 ? "" :
                                                        <li><NavLink to="/add-account"><span><MdPersonAddAlt /> </span> Account Management</NavLink></li>
                                                    }
                                                    <li><NavLink to="/upgrade"><span><MdStarBorder /> </span> Upgrade</NavLink></li>
                                                </>

                                            }

                                            <li>
                                                <a>
                                                    <label for="flexSwitchCheckDefault" className="d-flex justify-content-between align-items-center" style={{ width: "100%", cursor: "pointer" }}>
                                                        <div className="icon-img">
                                                            <MdBrightnessMedium />
                                                            <span style={{ marginLeft: "8px" }}>Dark Mode</span>
                                                        </div>
                                                        <div class="form-check form-switch">
                                                            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={handleChangeTheme} checked={theme} />
                                                        </div>
                                                    </label>
                                                </a>
                                            </li>
                                            <li><NavLink to="/help"><span><MdOutlineHelpOutline /> </span> Help</NavLink></li>
                                            <li><NavLink to="" onClick={handleOut}><span><MdOutlineLogout /> </span> Log Out</NavLink></li>
                                        </ul>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header;