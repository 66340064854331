import React from 'react';
import { Modal } from 'react-bootstrap';

const DetailsModal = (props) => {
    console.log(props)
    return (
        <Modal className='theme-modal xl' show={props.show} onHide={props.handleClose} centered scrollable>
            <Modal.Body style={{ padding: 0 }}>
                <div className='cont-box-group'>
                    {/* <h5>Business Results</h5> */}
                    <div className='cont-box'>
                        <div className='table-responsive'>
                            <h3 className='pb-4'>Social Accounts</h3>
                            <table className='table theme-table'>
                                <thead className='text-center'>
                                    <tr>
                                        <th>No.</th>
                                        <th>Account</th>
                                        <th>Status</th>
                                        <th>Post URL</th>
                                        <th>Error</th>
                                    </tr>
                                </thead>
                                <tbody className='text-center'>
                                    {props.data.length > 0 ?
                                        props.data.map((curElem, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        {curElem.type}
                                                    </td>
                                                    <td style={{ color: curElem.status ? "green" : "red" }}>{curElem.status ? "Posted" : "Failed"}</td>
                                                    <td>
                                                        {curElem.post_url ?
                                                            <a target="_blank" href={curElem.post_url} >Visit</a> : ""}</td>
                                                    <td>{curElem.message ? curElem.message : "N/A"}</td>
                                                </tr>
                                            )
                                        })
                                        :
                                        ""
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='site-link bdr' onClick={props.handleClose}><span>Close</span></button>
            </Modal.Footer>
        </Modal>
    );
}

export default DetailsModal;
