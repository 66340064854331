import React, { useState } from 'react';
import { BsFillTrashFill } from 'react-icons/bs'
import { useImmer } from 'use-immer';
import Swal from 'sweetalert2';
import { onTextStream } from '../../../Redux/Actions/StreamActions';
import { useDispatch } from 'react-redux';
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const AddStreamText = (props) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [textArr, setTextArr] = useImmer([
        {
            value: ""
        }
    ])

    const handleAddMore = () => {
        if (props.id) {
            setTextArr((draft) => {
                draft.push({
                    value: ""
                })
            })
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please create content stream first!',
                confirmButtonColor: "#618fca"
            })
        }
    }

    const handleChange = (e, index) => {
        let data
        if (props.type === "text") {
            data = e.target.value
        } else {
            data = draftToHtml(e);
        }
        setTextArr((draft) => {
            draft[index].value = data
        })
    }

    const handleRemove = (index) => {
        if (textArr.length > 1) {
            setTextArr((draft) => {
                draft.splice(index, 1)
            })
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        let isEmpty = textArr.findIndex(({ value }) => value === "" || value === "<p></p>\n")
        if (props.id) {

            const formData = new FormData();
            formData.append('title', props.title);
            formData.append('description', JSON.stringify(textArr));
            formData.append('sequence', null);
            formData.append('content_stream_id', props.id);
            if (isEmpty === -1) {
                setLoader(true)
                dispatch(onTextStream(formData, props.fetchData, setLoader))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Please fill all fields of formatted text!',
                    confirmButtonColor: "#618fca"
                })
            }

        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please create content stream first!',
                confirmButtonColor: "#618fca"
            })
        }
    }
    return (
        <div className='info-tab'>
            <h3>{props.type === "image" ? "Add Text" : ""}</h3>
            <div className='row'>
                <form className='col-sm-12' onSubmit={handleSubmit}>
                    <div className='common-input-wrap' >
                        {textArr.length > 0 ?
                            textArr.map((curElem, index) => {
                                return (
                                    <div style={{ position: "relative" }}>
                                        <label htmlFor="">{props.type === "text" ? "Text" : "Formatted Text"} </label>
                                        {index !== 0 ?
                                            <span style={{
                                                position: "absolute",
                                                top: 0,
                                                right: 2,
                                                cursor: "pointer",
                                                color: "#618fca"
                                            }}
                                                onClick={() => handleRemove(index)}
                                            >
                                                <BsFillTrashFill />
                                            </span>
                                            : ""}
                                        {props.type === "text" ?

                                            <textarea
                                                className='common-input'
                                                type="text"
                                                name='value'
                                                value={curElem.value}
                                                onChange={(e) => handleChange(e, index)}
                                                required
                                                readOnly={props.id ? false : true}
                                            /> :
                                            <div
                                                className='common-input'
                                            >
                                                <Editor
                                                    toolbar={{
                                                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'remove', 'history']
                                                    }}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                    onContentStateChange={(e) => handleChange(e, index)}
                                                />
                                            </div>
                                        }
                                    </div>
                                )
                            })
                            : ""}

                    </div>
                    <button
                        className='site-link bdr me-1 mt-1'
                        type='submit'
                    >
                        <span>{loader ? <>Saving <i className="fa fa-spinner fa-spin me-1" /> </> : "Save"}</span>
                    </button>
                    <button
                        className='site-link mt-1'
                        type='button'
                        onClick={handleAddMore}
                    >
                        <span>Add More</span>
                    </button>
                </form>
            </div>
        </div>
    )

}

export default AddStreamText;