import React, { useState } from 'react';

function DfyLeadMagnets() {

  return (
    <>
      <div className="tabepaneIn">
        <h2 className="tabTitle">DFY Lead Magnets</h2>
        <div className="tabepaneIn-main">
          <div className="tabepaneIn-cont">
            <h3 className="text-white">DFY Lead Magnets</h3>
            <a
              rel="noreferrer"
              href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/Sendiio/100.zip"
              target="_blank"
            >
              <button className="site-link  btn-block mt-0" type="button">
                <span>Download</span>
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  )

}

export default DfyLeadMagnets;