import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TwitterImage from "../../../assets/images/twitter-x.png";
import { saveMedium } from "../../../Redux/Actions/ClientAction";
import { Modal } from "react-bootstrap";
import TwitterLogin from "react-twitter-login";


const TwitterConnect = ({ checkAccount, editClient, fetchClientData, fromSocial }) => {
    const [showPopup, setShowPopup] = useState(false);
    const responseTwitter = (err, response) => {
        console.log(err, response)
    }

    return (
        <>
            <TwitterLogin
                authCallback={responseTwitter}
                className={`border-0 ${checkAccount === false ? 'client-img-opacity' : ''} ${checkAccount === false ? 'hide-button' : ''}`}
                // consumerKey={CONSUMER_KEY}
                consumerSecret={"L8qq9PZyRg6ieKGEKhZolGC0vJWLw8iEJ88DRdyOg"}
            >
                {fromSocial ?
                    <a className='site-link integr_card mt-4' style={{ padding: "2px 20px", fontSize: "14px", background: '#4fc5e1', borderColor: "#4fc5e1" }} > <span> Connect</span> </a>
                    :

                    <img src={TwitterImage} alt="fb-img" />}
            </TwitterLogin>
        </>
    )
}

export default TwitterConnect;