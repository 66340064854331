import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"

export const onFetchVideo = (data, setLoader, navigate) => (dispatch, getState) => {
    commonAxios("fetch-video", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ADD_VIDEO_DATA", payload: res.data[0] })
            } else {
                if (+res.data === 1) {
                    navigate(`/my-content/content-set?id=${data.cId}`)
                } else {
                    dispatch(setAlert(res.msg, "danger"))
                }
                setLoader(false)
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
            console.log((err))
        })
}

export const saveVideo = (campaign, setAllowCall) => (dispatch, getState) => {
    let data = { ...campaign }
    delete data.undoRedo
    delete data.lastAction
    commonAxios("save-video", data, dispatch, getState().auth.token)
        .then((res) => {
            setAllowCall(true)
        }).catch((err) => {
            setAllowCall(true)
            console.log(err)
        })
}

export const onRenderVideo = (data, navigate, setLoader) => (dispatch, getState) => {
    commonAxios("render", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                navigate("/my-content")
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err.msg)
            dispatch(setAlert(err.msg, "danger"));
            setLoader(false)
        })
}

export const onDeleteUserMedia = (data, fetchImgMedia, setSweet) => (dispatch, getState) => {
    commonAxios("delete-user-uploaded-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchImgMedia()
                dispatch(setAlert(res.msg, 'success'))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                type: ""
            })
        }).catch((err) => {
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                type: ""
            })
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)

        })
}


export const onCreateVideo = (data, navigate, setLoader) => (dispatch, getState) => {
    commonAxios("create-video", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/my-content/content-set?id=${res.data}`)
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            console.log(err)
        })
}

export const onFetchScript = (data, setData, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-status-video", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setData(res.data[0])
            }
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            console.log(err)
        })
}

export const onEditScript = (data, loader, setLoader, navigate) => (dispatch, getState) => {
    commonAxios("generate-video", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (data.type) {
                    dispatch(setAlert(res.msg, "success"))
                } else {
                    navigate(`/waiting?id=${data.cId}&type=video`)

                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                save: false,
                generate: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                save: false,
                generate: false
            })
            console.log(err)
        })
}


export const onFetchLang = (pollyLanguages, setPollyLanguages) => (dispatch, getState) => {
    commonAxios("get-polly-languages", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = [...res.data.reverse()]
                arr.sort(function (a, b) {
                    return a.name.localeCompare(b.name)
                });
                // let vId = arr.find(({ code }) => code === langId)
                // if (vId) {
                //     fetchPollyVoices(vId.id, langId)
                // }
                setPollyLanguages({ ...pollyLanguages, data: arr });
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onFetchVoice = (value, pollyVoices, setPollyVoices, setLangData, langData, selectedSlide, langCode, setEngine) => (dispatch, getState) => {

    let formData = new FormData();
    formData.append('lang_code', value);
    let id = ["96"]
    commonAxios("get-artists", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let obj = [...res.data]
                obj = obj.filter((curElem) => {
                    return !id.includes(curElem.id)
                })
                setPollyVoices({ ...pollyVoices, data: obj });
                let valData = obj.find(({ voice_id }) => voice_id === selectedSlide.audio.meta.voiceId)
                if (valData) {
                    setLangData({
                        ...langData,
                        voice: valData.voice_id,
                        voiceId: valData.id,
                        lang: langCode
                    })
                    setEngine(valData.type)
                }

            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onGenerateTts = (data, generateData, setGenerateData, setLoader, setTtsStatus) => (dispatch, getState) => {
    commonAxios("generate-tts-new", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setTtsStatus(false)
                setGenerateData({
                    ...generateData,
                    url: res.data.file_url,
                    duration: res.data.duration - 3
                })
            } else {
                dispatch(setAlert(res.msg, 'danger'));
                setTtsStatus(true)
            }
            setLoader(false);
        }).catch((error) => {
            setTtsStatus(true)
            setLoader(false);
            console.log(error)
        })
}

export const onUploadIR = (data, setData, setLoader, type, setPercent) => (dispatch, getState) => {
    const config = {
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            setPercent(percent);
        },
        headers: {
            'Authorization': getState().auth.token
        },
    }
    commonAxios("upload-file", data, dispatch, false, config)
        .then((res) => {
            if (res.status) {
                let data = {
                    url: res.data.path,
                    nameData: res.data.name,
                    thumbData: res.data.thumbnail
                }
                setData(data.thumbData)
                if (type === "intro") {
                    dispatch(updateIntroOutro('UPDATE_INTRO', data, setLoader))
                }
                else {
                    dispatch(updateIntroOutro('UPDATE_OUTRO', data, setLoader))
                }
            }
            else {
                dispatch(setAlert(res.msg, 'danger'));
                setLoader(false)
            }
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, 'danger'));
            setLoader(false)

        })
}


export const onUpdateTemplate = (data, history, setLoader, id) => (dispatch, getState) => {
    commonAxios("preset-update", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setLoader(true)
                let myData = {
                    cId: id
                }
                dispatch(onFetchVideo(myData, setLoader, history))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
}

export const onVariationChange = (data, slideIndex, setPreLoader) => (dispatch, getState) => {
    setPreLoader(true)
    commonAxios("variation-change", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                let obj = { ...res.data }

                obj.audio = JSON.parse(obj.audio)
                obj.background = JSON.parse(obj.background)
                obj.layers = JSON.parse(obj.layers)
                obj.layers.forEach((layer) => {
                    if (layer.type === "text") {
                        if (!layer.textToHighlight) {
                            layer.textToHighlight = []
                        }
                    }
                    layer.animation.playAnimation = true
                })
                obj.variationId = data.newVariationId
                let dataObj = { obj, slideIndex }

                dispatch({ type: "CHANGE_VARIATION_DATA", payload: dataObj })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setPreLoader(false)
        }).catch((err) => {
            console.log(err)
            setPreLoader(false)
        })
}

export const deleteTile = (slide, index, setSweet) => (dispatch, getState) => {
    let data = {
        slideId: slide.id
    }
    commonAxios("delete-slide", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "DELETE_SLIDE", payload: index })
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
            })
        }).catch((err) => {
            console.log(err)
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
            })
        })
}

export const onGenerateFile = (data, loader, setLoader, output, setOutput) => (dispatch, getState) => {
    commonAxios("generate-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setOutput({
                    ...output,
                    cropped: res.data
                });
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                crop: false
            })
        }).catch(err => {
            console.log(err)
            setLoader({
                ...loader,
                crop: false
            })
        })
}

export const onVideoCrop = (formData, loader, setLoader, output, setOutput) => (dispatch, getState) => {
    commonAxios("video-crop", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setOutput({
                    ...output,
                    cropped: res.data.video,
                    thumbnail: res.data.thumbnail
                })
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            setLoader({
                ...loader,
                crop: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                crop: false
            })
        })
}

export const onFetchStatus = (data, setObjData, interval) => (dispatch, getState) => {
    commonAxios("fetch-status", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let obj = { ...res.data[0] }
                if (obj.status === "Render Successful" || obj.status === "Render Failed") {
                    clearInterval(interval)
                }
                setObjData(obj)
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onAddSlide = (data, setLoader) => (dispatch, getState) => {
    commonAxios("add-slide", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ADD_NEW_SLIDE", payload: res.data })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)

        })
}


export const onGenerateTtsAll = (slideData, ttsLang, setDeactiveButton) => async (dispatch, getState) => {
    dispatch(setAlert("Please wait, We will inform you once the voiceover generated for all the slides", "warning"))
    let arr = [...slideData]
    let i = 0
    for (const curElem of arr) {
        let text = curElem.audio.meta.text
        // if (curElem.layers) {
        //     for (const layer of curElem.layers) {
        //         if (layer.src !== "" && layer.type === "text") {
        //             text += await layer.src
        //             text += "\n"
        //         }
        //     }
        // }
        const data = {
            engine: ttsLang.engine,
            language_id: ttsLang.language_id,
            voice_id: ttsLang.voice_id,
            text: text
        }
        if (data.text !== "") {
            await commonAxios("generate-tts-new", data, dispatch, getState().auth.token)
                .then(async (res) => {
                    if (res.status) {
                        let obj = {
                            slideIndex: i,
                            url: res.data.file_url,
                            duration: res.data.duration - 3,
                            ttsData: data
                        }
                        await dispatch({ type: "ADD_TTS_IN_ALL", payload: obj })
                    }
                    if (i === slideData.length - 1) {
                        dispatch(setAlert("Voiceover generated for all slides", "success"))
                        setDeactiveButton(false)
                    }

                }).catch((error) => {
                    console.log(error)
                })
        }
        i++
    }
}


// No Axios Here

export const updateScript = (index, slideIndex) => (dispatch) => {
    const data = { index, slideIndex }
    dispatch({ type: 'UPDATE_SLIDES_DATA', payload: data });
}

export const textLayerAdd = (slideIndex) => (dispatch) => {
    let randomId = Math.floor(Math.random() * 1000000000000000).toString()
    let layer = {
        id: randomId,
        type: "text",
        layerType: "custom",
        src: "You Can Edit Text Here",
        style: {
            color: "#FFFFFF",
            fontFamily: "Noto Sans",
            background: "",
            fontSize: "17",
            textAlign: "center",
            fontWeight: false,
            fontStyle: false,
            textTransform: "",
            textDecoration: "none",
            lineHeight: 1,
            opacity: 1
        },
        position: {
            left: 150,
            top: 200
        },
        animation: {
            name: "",
            delay: "0.5s",
            duration: "1s",
            playAnimation: false
        },
        size: {
            width: 300,
            height: 50
        },
        textToHighlight: [],
        isSelected: true,
        meta: "",
    }
    const data = { layer, slideIndex }
    dispatch({ type: "ADD_TEXT_LAYER", payload: data })
}

export const textLayerUpdate = (text, slideIndex, layerIndex) => (dispatch) => {
    const data = { text, slideIndex, layerIndex }
    dispatch({ type: "UPDATE_LAYER_TEXT", payload: data })
}

export const onTextBold = (data, slideIndex, textIndex) => (dispatch) => {
    let temp = { data, slideIndex, textIndex }
    dispatch({ type: 'ON_TEXT_FONTBOLD', payload: temp });
}

export const onTextItalic = (data, slideIndex, textIndex) => (dispatch) => {
    let temp = { data, slideIndex, textIndex }
    dispatch({ type: 'ON_TEXT_FONTITALIC', payload: temp });
}

export const onTextUpper = (data, slideIndex, textIndex) => (dispatch) => {
    let temp = { data, slideIndex, textIndex }
    dispatch({ type: 'ON_TEXT_FONTTRANSFORM', payload: temp });
}
export const onTextLower = (data, slideIndex, textIndex) => (dispatch) => {
    let temp = { data, slideIndex, textIndex }
    dispatch({ type: 'ON_TEXT_FONTTRANSFORM', payload: temp });
}
export const onTextCapitalize = (data, slideIndex, textIndex) => (dispatch) => {
    let temp = { data, slideIndex, textIndex }
    dispatch({ type: 'ON_TEXT_FONTTRANSFORM', payload: temp });
}


export const onTextUnderline = (data, slideIndex, textIndex) => (dispatch) => {
    let temp = { data, slideIndex, textIndex }
    dispatch({ type: 'ON_TEXT_FONTDECO', payload: temp });
}

export const onTextStrike = (data, slideIndex, textIndex) => (dispatch) => {
    let temp = { data, slideIndex, textIndex }
    dispatch({ type: 'ON_TEXT_FONTDECO', payload: temp });
}


export const onTextAlign = (data, slideIndex, textIndex) => (dispatch) => {
    let temp = { data, slideIndex, textIndex }
    dispatch({ type: 'ON_TEXT_FONTALIGN', payload: temp });
}

export const onTextLineHeight = (data, slideIndex, textIndex) => (dispatch) => {
    let temp = { data, slideIndex, textIndex }
    dispatch({ type: 'ON_TEXT_FONTLINEHEIGHT', payload: temp });
}

export const onFontColor = (data, slideIndex, textIndex) => (dispatch) => {
    let temp = { data, slideIndex, textIndex }
    dispatch({ type: 'ON_TEXT_FONTCOLOR', payload: temp });
}

export const onFontBgColor = (data, slideIndex, textIndex) => (dispatch) => {
    let temp = { data, slideIndex, textIndex }
    dispatch({ type: 'ON_TEXT_FONTBGCOLOR', payload: temp });
}


export const removeFontBgColor = (slideIndex, textIndex) => (dispatch) => {
    let data = { slideIndex, textIndex }
    dispatch({ type: 'REMOVE_FONTBGCOLOR', payload: data });
}

export const onFontSize = (data, slideIndex, textIndex) => (dispatch) => {
    let temp = { data, slideIndex, textIndex }
    dispatch({ type: 'ON_TEXT_FONTSIZE', payload: temp });
}

export const onFontFamily = (data, slideIndex, textIndex) => (dispatch) => {
    let temp = { data, slideIndex, textIndex }
    dispatch({ type: 'ON_TEXT_FONTSTYLE', payload: temp });
}

export const unselectLayers = (slideIndex) => (dispatch) => {
    dispatch({ type: "UNSELECT_LAYERS", payload: slideIndex })
}
export const updateSelectedLayer = (index, slideIndex) => (dispatch) => {
    const data = { index, slideIndex }
    dispatch({ type: "UPDATE_LAYER", payload: data })
}

export const resizeLayer = (w, h, x, y, slideIndex, layerIndex) => (dispatch) => {
    const data = { w, h, x, y, slideIndex, layerIndex }
    dispatch({ type: "RESIZE_LAYER", payload: data })
}

export const rePositionLayer = (t, l, slideIndex, layerIndex) => (dispatch) => {
    const data = { t, l, slideIndex, layerIndex }
    dispatch({ type: "REPOSITION_LAYER", payload: data })
}

export const endAnimation = (slideIndex, layerIndex) => (dispatch) => {
    const data = { slideIndex, layerIndex }
    dispatch({ type: "END_ANIMATION", payload: data })
}

export const onUpdateLogoInfo = (height, width) => (dispatch) => {
    let data = { height, width }
    dispatch({ type: "UPDATE_LOGO_HW", payload: data })
}

export const onUpdateWatermarkInfo = (height, width) => (dispatch) => {
    let data = { height, width }
    dispatch({ type: "UPDATE_WATERMARK_HW", payload: data })
}

export const updateTextHeight = (height, slideIndex, layerIndex) => (dispatch) => {
    let data = { height, layerIndex, slideIndex }
    dispatch({ type: "UPDATE_TEXT_HEIGHT", payload: data })
}

export const removeLayer = (selectedSlide, slideIndex) => (dispatch) => {
    const layerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
    const data = { layerIndex, slideIndex }
    dispatch({ type: "REMOVE_LAYER", payload: data })
}
export const layerDuplicate = (slideIndex, layerData, layerIndex) => (dispatch) => {
    layerData.id = Math.floor(Math.random() * 1000000000000000).toString()
    const data = { slideIndex, layerData, layerIndex }
    dispatch({ type: "COPY_LAYER", payload: data })
}

export const updateLayerPos = (slideIndex, layerIndex, place) => (dispatch) => {
    const data = { slideIndex, layerIndex }
    if (place === "down") {
        dispatch({ type: "LAYER_DOWN", payload: data })
    } else {
        dispatch({ type: "LAYER_UP", payload: data })
    }
}
export const onUndo = (slideId) => (dispatch) => {
    dispatch({ type: "UNDO", payload: slideId })
}
export const onRedo = (slideId) => (dispatch) => {
    dispatch({ type: "REDO", payload: slideId })
}

export const updateLastAction = (data) => (dispatch) => {
    dispatch({ type: "UPDATE_LAST_ACTION", payload: data })
}
export const updatePresent = (data) => (dispatch) => {
    dispatch({ type: "SET_PRESENT", payload: data })
}

export const updateTtsText = (text, index) => (dispatch) => {
    const data = { text, index }
    dispatch({ type: "UPDATE_TTS1_TEXT", payload: data })
}

export const updateTtsUrl = (val, langData, index) => (dispatch) => {
    const data = { val, langData, index }
    dispatch({ type: "UPDATE_GENERATED_TTS", payload: data })
    dispatch(setAlert('Voiceover applied successfully', 'success'));
    dispatch(addSlideDuration(val.duration))
}
export const addSlideDuration = (value) => (dispatch) => {
    dispatch({ type: 'UPDATE_SLIDE_DURATION', payload: value })
}

export const setTtsData = (meta, slideIndex, enable) => (dispatch) => {
    const data = { meta, slideIndex, enable }
    dispatch({ type: 'ADD_TTS_DATA', payload: data });
    if (meta.src !== "") {
        dispatch(setAlert('Voiceover applied successfully', 'success'));
        dispatch(addSlideDuration(meta.duration))
    }
}

export const addBgMusic = (url, slideIndex, dur) => (dispatch) => {
    const data = { url, slideIndex, dur }
    dispatch({ type: 'ADD_BGAUDIO_DATA', payload: data })
    dispatch(setAlert("Music applied successfully !", "success"))
}

export const removeBgAudioFromSlides = (slideIndex) => (dispatch) => {
    dispatch({ type: 'REMOVE_BGAUDIO_SLIDES', payload: slideIndex })
    dispatch(setAlert("Music removed successfully !", "success"))
}

export const addTransition = (name, index) => (dispatch) => {
    let data = { name, index }
    dispatch({ type: "ADD_TRANSITION", payload: data })
    dispatch(setAlert("Transition updated successfully !", "success"))
}
export const removeTransition = (slideIndex) => (dispatch) => {
    dispatch({ type: "REMOVE_TRANSITION", payload: slideIndex })
}

export const updateAnimation = (val, slideIndex, layerIndex, name) => (dispatch) => {
    let data = { val, slideIndex, layerIndex, name }
    dispatch({ type: "UPDATE_ANIMTION", payload: data })
}

export const bgVolumeChange = (type, value) => (dispatch) => {
    dispatch({ type: type, payload: value });
}
export const aiVolumeChange = (type, value, selectedSlideIndex) => (dispatch) => {
    const data = { value, selectedSlideIndex }
    dispatch({ type: type, payload: data });
}

export const updateLogoWatermark = (type, data, dimension) => (dispatch) => {
    const val = { data, dimension }
    dispatch({ type: type, payload: val });
}

export const removeLogoWatermark = (type) => (dispatch) => {
    dispatch({ type: type })
}
export const updateStatus = (enable, type) => (dispatch) => {
    const data = { enable, type }
    dispatch({ type: 'UPDATE_STATUS_L&W', payload: data })
}

export const updateIntroOutro = (type, data, setLoader) => (dispatch) => {

    let au = document.createElement("video")
    au.src = data.url
    au.addEventListener('loadedmetadata', function () {
        let duration = Number((au.duration).toFixed(1));
        data = {
            ...data,
            duration: duration
        }
        setLoader(false)
        dispatch({ type: type, payload: data });
    }, false);


}

export const updateIOStatus = (status, type) => (dispatch) => {
    if (type === "intro") {
        dispatch({ type: 'SET_INTRO_STATUS', payload: status })
    } else {
        dispatch({ type: 'SET_OUTRO_STATUS', payload: status })
    }
}

export const removeIO = (type) => (dispatch) => {
    if (type === "intro") {
        dispatch({ type: 'REMOVE_INTRO' })
    } else {
        dispatch({ type: 'REMOVE_OUTRO' })
    }
}

export const updateBackground = (file, index, type) => (dispatch) => {
    let data = { file, index }
    if (type === "image") {
        dispatch({ type: 'UPDATE_BG_IMAGE', payload: data })
    }
    else if (type === "video") {
        dispatch({ type: "UPDATE_BG_VIDEO", payload: data })
    }
    else {
        data = { file, index }
        dispatch({ type: "UPDATE_BG_COLOR", payload: data })
    }
}

export const updateMediaLayer = (val, slideIndex, layerIndex, type) => (dispatch) => {
    if (type === "image") {
        val = val.url
        let data = { val, slideIndex, layerIndex }
        dispatch({ type: "UPDATE_LAYER_IMAGE", payload: data })
    } else {
        if (val.width > 720 || val.height > 720) {
            val.width = Math.floor(val.width / 9)
            val.height = Math.floor(val.height / 9)
        } else {
            val.width = Math.floor(val.width / 3)
            val.height = Math.floor(val.height / 3)
        }

        let data = { val, slideIndex, layerIndex }
        dispatch({ type: "UPDATE_LAYER_VIDEO", payload: data })
    }
}

export const addMediaLayer = (val, slideIndex, type, dimension) => (dispatch) => {
    let randomId = Math.floor(Math.random() * 1000000000000000).toString()
    let width = 280, height = 150
    if (type === "video") {
        if (val.width > 720 || val.height > 720) {
            width = Math.floor(val.width / 9)
            height = Math.floor(val.height / 9)
        } else {
            width = Math.floor(val.width / 3)
            height = Math.floor(val.height / 3)
        }
    }
    if (dimension === "1080x1080") {
        width = 280
        height = 280
    } else if (dimension === "1080x1920") {
        width = 150
        height = 280
    }

    const data = {
        objData: {
            id: randomId,
            type: type,
            layerType: "custom",
            style: {
                color: "#FFFFFF",
                fontFamily: "Noto Sans",
                background: "",
                fontSize: "17",
                textAlign: "left",
                fontWeight: false,
                fontStyle: false,
                textTransform: "lowercase",
                textDecoration: "none",
                lineHeight: 1,
                opacity: 1
            },
            position: {
                left: 30,
                top: 30
            },
            animation: {
                name: "",
                delay: "0.5s",
                duration: "1s",
                playAnimation: false
            },
            size: {
                width: width ? width : 280,
                height: height ? height : 150
            },
            isSelected: true,
            src: val.url,
            meta: "",
            mute: true
        },
        slideIndex
    }
    dispatch({ type: "ADD_MEDIA_LAYER", payload: data })
}

export const deleteBackTile = (slideIndex, backIndex, setSweet) => (dispatch) => {
    let data = { slideIndex, backIndex }
    dispatch({ type: "ON_DELETE_BACK", payload: data })
    setTimeout(() => {
        setSweet({
            enable: false,
            id: false,
            confirmButtonName: "Delete",
        })
    }, 1000)
}

export const rearrangeTiles = (slides) => (dispatch) => {
    dispatch({ type: "REARRANGE TILES", payload: slides })
}

export const onRemovePlaySlide = (slideIndex) => (dispatch) => {
    dispatch({ type: "STOP_PLAYING_SLIDE", payload: slideIndex })
}

export const onSelectInnerBackground = (slideIndex, backgroundIndex) => (dispatch) => {
    let data = { slideIndex, backgroundIndex }
    dispatch({ type: "ON_SELECT_INNERBACK", payload: data })
}

export const removeTtsFromSlides = (slideIndex) => (dispatch) => {
    const tts = {
        text: "",
        languageId: "",
        voiceId: "",
        translateText: ""
    }
    const data = { tts, slideIndex }
    dispatch({ type: 'REMOVE_TTSAUDIO_SLIDES', payload: data })
    dispatch(setAlert("Voiceover removed successfully", "success"))
}

export const updateSelectedTiles = (data) => (dispatch) => {
    dispatch({ type: 'UPDATE_SELECTED_TILES', payload: data });
}

export const onPlay = (slideIndex) => (dispatch) => {
    dispatch({ type: "ONPLAY_SLIDE", payload: slideIndex })
}

export const onChangeBackgroundInterval = (slideIndex) => (dispatch) => {
    dispatch({ type: "CHANGE_BG_INTERVAL", payload: slideIndex })
}

export const unMuteVideoAudio = (slideIndex, layerIndex) => (dispatch) => {
    let data = { slideIndex, layerIndex }
    dispatch({ type: "CHANGE_VIDEO_UNMUTE", payload: data })
}

export const muteVideoAudio = (slideIndex, layerIndex) => (dispatch) => {
    let data = { slideIndex, layerIndex }
    dispatch({ type: "CHANGE_VIDEO_MUTE", payload: data })
}

export const onEnableCtaData = (data) => (dispatch) => {
    dispatch({ type: "ENABLE_PROMO", payload: data })
}

export const onUpdateCtaPos = (data) => (dispatch) => {
    dispatch({ type: "UPDATE_POSITION_PROMO", payload: data })
}

export const onUpdateCta = (data) => (dispatch) => {
    dispatch({ type: "UPDATE_CTA_PROMO", payload: data })
}

export const onUnmountEditor = () => (dispatch) => {
    dispatch({ type: "UNMOUNT_EDITOR" })
}

export const applyToAllSlide = (style, innerCss) => (dispatch) => {
    const data = { style, innerCss }
    dispatch({ type: "APPLY_TO_ALL_LAYERS", payload: data })
}