import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { resetPassword } from '../../../Redux/Actions/AuthActions';
import { useDispatch } from 'react-redux';
import queryString from "query-string";

import Footer from '../../footer/Footer';

const ResetPassword = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    let location = useLocation()

    const [loader, setLoader] = useState(false)
    const [loginInfo, setLoginInfo] = useState({
        password: null,
        confirm_password: null,
        password_token: queryString.parse(location.search).token
    })

    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    })


    const handleChange = (e) => {
        const { name, value } = e.target
        setLoginInfo({
            ...loginInfo,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{6,}$/;
        if (loginInfo.password) {
            if (pattern.test(loginInfo.password)) {
                if ((loginInfo.password === loginInfo.confirm_password)) {
                    setPasswordMsg({ ...passwordMsg, msg: "Password is valid!", validation: true })
                    setLoader(true);
                    dispatch(resetPassword(loginInfo, setLoader, navigate));
                } else {
                    setPasswordMsg({ ...passwordMsg, msg: "Password is not matched!", validation: true })
                }
            } else {
                setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.", validation: true })
            }
        }
    }

    return (
        <>
            <div className="login-wrap">
                <div className="login-head">
                    <div className="login-logo"><img alt='' src={require('../../../assets/images/logo.png')} /></div>
                </div>

                <div className="login-left">
                    <div className='welcome-txt'>
                        <h2>Welcome Back!</h2>
                        <p>Reignite your social media optimisation journey with AI SOCIALS. Your gateway to enhanced engagement and brand impact awaits. Let's get started!.</p>
                    </div>
                </div>
                <div className="login-right">
                    <form className="login-right-main" onSubmit={handleSubmit}>
                        <h2>Reset Password</h2>
                        <div className='common-input-wrap'>
                            <label htmlFor="">New Password</label>
                            <input
                                className='common-input'
                                type="password"
                                name="password"
                                placeholder='***********'
                                onChange={handleChange}
                            />
                        </div>
                        <div className='common-input-wrap'>
                            <label htmlFor="">Confirm Password</label>
                            <input
                                className='common-input'
                                type="password"
                                name="confirm_password"
                                placeholder='***********'
                                onChange={handleChange}
                            />
                           
                        </div>
                        <p style={{ fontSize: 13, margin: "5px" , color:"#DC143C"}}>{passwordMsg.validation ? passwordMsg.msg : ""}</p>
                        <div className='common-input-wrap'>
                            <button className='site-link full' type='submit'><span>Reset Password {loader ? <i className="fa fa-spinner fa-spin mx-1" /> : ""}</span></button>
                        </div>
                        <div className='common-input-wrap'>
                            <p>Login? <Link to="/login">Click here</Link></p>
                        </div>
                    </form>
                </div>
            </div>

            <div className='loginFooter'>
              <Footer></Footer>
            </div>
        </>
    )

}

export default ResetPassword;