import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../src/assets/css/general-sans.css'
import './App.css';
import './CommonCss.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './components/pages/Home/Home';
import MyContent from './components/pages/MyContent/MyContent';
import Clients from './components/pages/Clients/Clients';
import Trends from './components/pages/trends/Trends';
import AddClient from './components/pages/Clients/AddClient';
import Report from './components/pages/Report/Report';
import CreateReport from './components/pages/Report/CreateReport';
import Upgrade from './components/pages/upgrade/Upgrade';
import { useEffect } from 'react';
import { loadUser, onChangeTheme } from './Redux/Actions/AuthActions';
import { useDispatch, useSelector } from 'react-redux';
import { removeAlert } from './Redux/Actions/AlertActions';
import AccountManagement from './components/pages/AccountManagement/AccountManagement';
import ForgotPassword from './components/pages/Authentication/ForgotPassword';
import Login from './components/pages/Authentication/Login';
import PrivateRoute from './components/CommonComponent/PrivateRoute';
import Alert from './components/CommonComponent/Alert';
import Privacy from './components/pages/MyAccount/Privacy';
import MyAccount from './components/pages/MyAccount/MyAccount';
import ImageEditor from './components/pages/CreateImage/ImageEditor';

import TextSet from './components/pages/CreateText/TextSet';
import MasterLogin from './components/pages/Authentication/MasterLogin';
import ResetPassword from './components/pages/Authentication/ResetPassword';
import Help from './components/pages/HelpSupport/Help';
import Training from './components/pages/HelpSupport/Training';
import SupportArticle from './components/pages/HelpSupport/SupportArticle';
import CreateImage from './components/pages/CreateImage/CreateImage';
import LoaderPage from './components/CommonComponent/LoaderPage';
import CreateVideo from './components/pages/CreateVideo/CreateVideo';
import ContentSet from './components/pages/CreateVideo/ContentSet';
import Editor from './components/pages/CreateVideo/Editor/Editor';
import { onFetchFont } from './Redux/Actions/CommonActions';
import LodaingScreen from './components/pages/Loading-Screen/LodaingScreen';
import CreateText from './components/pages/CreateText/CreateText';
import ScheduleTable from './components/pages/Schedule/ScheduleTable';
import ScheduleDetails from './components/pages/Schedule/ScheduleDetails';
import Content from './components/CommonComponent/Content';
import { useState } from 'react';
import MainContent from './components/pages/MyContent/MainContent';
import MainClient from './components/pages/Clients/MainClient';
import MainContentStream from './components/pages/ContentStream/MainContentStream';
import ContentStream from './components/pages/ContentStream/ContentStream';
import AddStream from './components/pages/ContentStream/AddStream';
import AddStreamText from './components/pages/ContentStream/AddStreamText';
import Schedule from './components/pages/Schedule/Schedule';
import MainSchedule from './components/pages/Schedule/MainSchedule';
import MainReport from './components/pages/Report/MainReport';
import CreateMemes from './components/pages/CreateMemes/CreateMemes';
import Integration from './components/pages/SocialIntegration/Integration';

function App() {
  const dispatch = useDispatch()
  const alert = useSelector(state => state.alert)
  const auth = useSelector(state => state.auth)

  const fetchUser = () => {
    dispatch(loadUser())
  }

  useEffect(() => {
    if (alert !== undefined) {
      if (alert.message !== "") {
        setTimeout(() => {
          dispatch(removeAlert())
        }, 4000);
      }
    }
  }, [alert, dispatch])


  const fetchFonts = () => {
    dispatch(onFetchFont(false, true))
  }


  useEffect(() => {
    fetchUser()
    fetchFonts()

    let theme = false;
    const cookiesArray = document.cookie.split('; ');
    for (const cookie of cookiesArray) {
      const [name, value] = cookie.split('=');
      if (name === 'theme') {
        theme = value === "false" || !value ? false : true
        break;
      } else {
        theme = false
      }
    }
    dispatch(onChangeTheme(theme))
  }, [])



  return (
    <div className="App">
      <Alert />
      <div>
        <Router>
          <Routes>

            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/master-login" element={<MasterLogin />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />

            <Route exact path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
            <Route exact path="/trends" element={<PrivateRoute><Trends /></PrivateRoute>} />
            <Route exact path="/my-account" element={<PrivateRoute><MyAccount /></PrivateRoute>} />
            <Route exact path="/privacy" element={<PrivateRoute><Privacy /></PrivateRoute>} />
            <Route exact path="/add-account" element={<PrivateRoute><AccountManagement /></PrivateRoute>} />
            <Route exact path="/help" element={<PrivateRoute><Help /></PrivateRoute>} />
            <Route exact path="/upgrade" element={<PrivateRoute><Upgrade /></PrivateRoute>} />
            <Route exact path="/training" element={<PrivateRoute><Training /></PrivateRoute>} />
            <Route exact path="/support-article" element={<PrivateRoute><SupportArticle /></PrivateRoute>} />
            <Route exact path="/waiting" element={<PrivateRoute><LoaderPage /></PrivateRoute>} />
            <Route exact path="/socials" element={<PrivateRoute><Integration /></PrivateRoute>} />

            <Route exact path="/loading-screen" element={<PrivateRoute><LodaingScreen /></PrivateRoute>} />

            <Route exact path="/my-content" element={<PrivateRoute><MainContent /></PrivateRoute>} >
              <Route index element={<MyContent />} />
              <Route path="create-video" element={<PrivateRoute><CreateVideo /></PrivateRoute>} />
              <Route path="create-image" element={<PrivateRoute><CreateImage /></PrivateRoute>} />
              <Route path="create-memes" element={<PrivateRoute><CreateMemes /></PrivateRoute>} />
              <Route path="create-text" element={<PrivateRoute><CreateText /></PrivateRoute>} />
              <Route path="editor" element={<PrivateRoute><Editor /></PrivateRoute>} />
              <Route path="image-editor" element={<PrivateRoute><ImageEditor /></PrivateRoute>} />
              <Route path="content-set" element={<PrivateRoute><ContentSet /></PrivateRoute>} />
              <Route path="text-set" element={<PrivateRoute><TextSet /></PrivateRoute>} />
              <Route path="content" element={<PrivateRoute><Content /></PrivateRoute>} />

            </Route>

            <Route exact path="/clients" element={<PrivateRoute><MainClient /></PrivateRoute>} >
              <Route index element={<Clients />} />
              <Route path="add-client" element={<PrivateRoute><AddClient /></PrivateRoute>} />
            </Route>

            <Route exact path="/content-stream" element={<PrivateRoute><MainContentStream /></PrivateRoute>} >
              <Route index element={<ContentStream />} />
              <Route path="add-stream" element={<PrivateRoute><AddStream /></PrivateRoute>} />
              <Route path="edit-stream" element={<PrivateRoute><AddStream /></PrivateRoute>} />
              <Route path="add-stream-text" element={<PrivateRoute><AddStreamText /></PrivateRoute>} />
            </Route>

            <Route exact path="/schedule" element={<PrivateRoute><MainSchedule /></PrivateRoute>} >
              <Route index element={<Schedule />} />
              <Route path="schedule-content" element={<PrivateRoute><ScheduleTable /></PrivateRoute>} />
              <Route path="schedule-details" element={<PrivateRoute><ScheduleDetails /></PrivateRoute>} />
            </Route>

            <Route exact path="/report" element={<PrivateRoute><MainReport /></PrivateRoute>} >
              <Route index element={<Report />} />
              <Route path="create-report" element={<PrivateRoute><CreateReport /></PrivateRoute>} />
            </Route>
            <Route path="*" element={<Login />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
