import React, { useEffect, useState } from "react";
import vimeoImage from "../../../assets/images/vimeo_new.png";
import { useDispatch, useSelector } from "react-redux";
import randomstring from "randomstring";
import { checkNetworkStatus } from "../../../Redux/Actions/ClientAction";
import { baseURL } from "../../../Global/Global";
import { Modal } from "react-bootstrap";

const VimeoConnect = ({ checkAccount, editClient, fetchClientData, fromSocial }) => {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [checkStatus, setCheckStatus] = useState(true);
    const [randomString, setRandomString] = useState(randomstring.generate());

    const [loader, setLoader] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [vimeoInput, setVimeoInput] = useState({
        clientId: editClient.id,
        client_id: null,
        client_secret: null
    });

    const onInputChange = (e) => {
        setVimeoInput({ ...vimeoInput, [e.target.name]: e.target.value });
    }

    const closeConnectModel = () => {
        setShowPopup(false);
    }

    let interval;

    const connectVimeo = (e) => {
        e.preventDefault()
        e.stopPropagation();
        setShowPopup(false);

        let url = `${baseURL}connect-vimeo?token=${auth.token}&key=${randomString}&user_id=${auth.user.id}&clientId=${editClient.id}&client_id=${vimeoInput.client_id}&client_secret=${vimeoInput.client_secret}`;
        let myWindow = window.open(encodeURI(url), 'mywindow', 'width=500,height=600');

        interval = setInterval(() => {
            if (checkStatus) {
                dispatch(checkNetworkStatus(randomString, setCheckStatus, interval, myWindow, fetchClientData));
            }

        }, 5000); 
    }

    useEffect(() => {
        return () => {
            clearInterval(interval);
        }
    }, [])

    return (
        <>
            <span style={{ cursor: "pointer" }} className={`${checkAccount === false ? 'hide-button' : ''}`} onClick={(e) => setShowPopup(true)} >
                {fromSocial ?
                    <a className='site-link integr_card mt-4' style={{ padding: "2px 20px", fontSize: "14px", background: '#4fc5e1', borderColor: "#4fc5e1" }} > <span> Connect</span> </a>
                    :
                    <img className={`${checkAccount === false ? 'client-img-opacity' : ''}`} src={vimeoImage} alt="vimeo" />
                }
            </span>
            < Modal className='theme-modal xl' show={showPopup} onHide={closeConnectModel} centered scrollable >
                <form onSubmit={(e) => connectVimeo(e)}>
                    <Modal.Body>
                        <div className='info-tab'>
                            <h3>Connect Vimeo</h3>
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <div className='common-input-wrap'>
                                        <label htmlFor="">Client ID</label>
                                        <input
                                            className='common-input'
                                            type="text"
                                            name="client_id" onChange={(e) => onInputChange(e)}
                                            placeholder="Enter Client ID" required
                                        />
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='common-input-wrap'>
                                        <label htmlFor="">Client Secret</label>
                                        <input
                                            className='common-input'
                                            type="text"
                                            placeholder="Enter Client Secret" required
                                            name="client_secret" onChange={(e) => onInputChange(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='site-link bdr' type="button" onClick={(e) => closeConnectModel(e)}><span>Cancel</span></button>
                        <button className='site-link' type="submit" disabled={loader}><span>{loader ? <>Connecting <i className="fa fa-spinner fa-spin mx-1" /></> : "Connect"}</span></button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}

export default VimeoConnect;