import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateScript } from '../../../../../Redux/Actions/VideoActions'


const Script = () => {
  const dispatch = useDispatch()
  const campaign = useSelector(state => state.video.campaign)
  const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")

  const updateStoryTile = (index) => {
    if (selectedSlideIndex !== index) {
      dispatch(updateScript(index, selectedSlideIndex))
    }
  }
  return (
    <>
      <div className='quote-cont'>
        <div className='quote-lists video-scripts'>
          <ul style={{ height: 650, overflow: 'auto' }}>
            {campaign.slides.length > 0 ?
              campaign.slides.map((curElem, index) => {
                return (
                  <li
                    className={`${selectedSlideIndex === index ? 'active cursor-pointer' : ''}`}
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={() => updateStoryTile(index)}
                  >
                    {curElem.scene}
                  </li>
                )
              })
              : ""}

          </ul>
        </div>
      </div>
    </>
  )
}

export default Script