import React, { useState } from 'react'
import { AiOutlineDelete } from 'react-icons/ai'
import { FiEdit } from 'react-icons/fi'
import { onDeleteCampaign } from '../../../Redux/Actions/CommonActions'
import { useDispatch } from 'react-redux'
import SweetAlert from '../../CommonComponent/SweetAlert'
import StreamEditModal from './StreamEditModal'
import { useEffect } from 'react'
import sanitizeHtml from 'sanitize-html';

const StreamFileRows = (props) => {
    const dispatch = useDispatch()
    const [desc, setDesc] = useState(props.description)

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
    })

    const handleDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
            id: props.id
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete"
        })
    }

    const performDelete = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting..."
        })
        dispatch(onDeleteCampaign("delete-stream", data, props.fetchData, setSweet))
    }

    useEffect(() => {
        if (props.description) {
            const clean = sanitizeHtml(props.description, {
                allowedTags: [],
                allowedAttributes: []
            })
            setDesc(clean)
        }
    }, [props])

    return (
        <tr
            ref={props.provided.innerRef}
            {...props.provided.draggableProps}
            {...props.provided.dragHandleProps}
        >
            <td>{props.index + 1}</td>
            <td>
                <span className='thumb-image'><img alt='' src={props.image} /></span>
            </td>
            <td>{props.title.length > 20 ? props.title.slice(0, 20) + "..." : props.title}</td>
            <td>
                {desc.length > 50 ? desc.slice(0, 50) + "..." : desc}
            </td>
            <td>{props.created}</td>
            <td className='text-end'>
                <span className='smBtn' onClick={handleDelete}>
                    <AiOutlineDelete />
                </span>
                <span className='smBtn edit' onClick={handleShow}>
                    <FiEdit />
                </span>
            </td>

            <StreamEditModal
                show={show}
                handleClose={handleClose}
                data={props}
                fetchData={props.fetchData}
            />

            <SweetAlert
                show={sweet.enable}
                message={"Are you sure you want to delete it?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
            />
        </tr>
    )
}

export default StreamFileRows