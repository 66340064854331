import React, { useState } from 'react';

import { RxLetterCaseCapitalize, RxLetterCaseLowercase, RxLetterCaseUppercase } from 'react-icons/rx'
import { BiBold, BiUnderline } from 'react-icons/bi'
import { AiOutlineItalic, AiOutlineAlignLeft, AiOutlineAlignCenter, AiOutlineAlignRight } from 'react-icons/ai'
import { RiStrikethrough2, RiAlignJustify } from 'react-icons/ri'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import ColorPicker from '../../../../CommonComponent/ColorPicker';
import { applyToAllSlide, onFontBgColor, onFontColor, onFontFamily, onFontSize, onTextAlign, onTextBold, onTextCapitalize, onTextItalic, onTextLineHeight, onTextLower, onTextStrike, onTextUnderline, onTextUpper, textLayerAdd, textLayerUpdate } from '../../../../../Redux/Actions/VideoActions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { onFetchFont } from '../../../../../Redux/Actions/CommonActions';
import ContentEditable from 'react-contenteditable';
import sanitizeHtml from 'sanitize-html';

const Text = () => {
    const dispatch = useDispatch()
    const campaign = useSelector(state => state.video.campaign)
    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
    const textIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
    const selectedText = selectedSlide.layers.find(({ isSelected }) => isSelected === true)
    const [layerText, setLayerText] = useState("")

    const [slideAll, setSlideAll] = useState(false);
    const [fonts, setFonts] = useState([])
    const [styleState, setStyleState] = useState({
        color: "#000000",
        fontFamily: "",
        background: false,
        fontSize: "36",
        textAlign: "center",
        fontWeight: false,
        fontStyle: false,
        textTransform: "lowercase",
        textDecoration: "none",
        lineHeight: 1,
        opacity: 1,
    })

    const addTextLayer = () => {
        dispatch(textLayerAdd(selectedSlideIndex))
    }

    const onLayerTextChange = (text) => {
        if (selectedText.type === "text") {
            const clean = sanitizeHtml(text, {
                allowedTags: []
            });
            setLayerText(clean)
            dispatch(textLayerUpdate(clean, selectedSlideIndex, textIndex))
        }
    }

    const onTextStyle = (type) => {
        setSlideAll(false)
        if (type === 'bold') {
            if (styleState.fontWeight === false || styleState.fontWeight === "") {
                dispatch(onTextBold("bold", selectedSlideIndex, textIndex));
            } else {
                dispatch(onTextBold(false, selectedSlideIndex, textIndex));
            }
        } else if (type === 'italic') {
            if (styleState.fontStyle === false || styleState.fontStyle === "") {
                dispatch(onTextItalic("italic", selectedSlideIndex, textIndex));
            } else {
                dispatch(onTextItalic(false, selectedSlideIndex, textIndex));
            }
        } else if (type === 'uppercase') {
            if (styleState.textTransform === "uppercase") {
                dispatch(onTextUpper("none", selectedSlideIndex, textIndex));
            } else {
                dispatch(onTextUpper("uppercase", selectedSlideIndex, textIndex));
            }
        }
        else if (type === 'capitalize') {
            if (styleState.textTransform === "capitalize") {
                dispatch(onTextCapitalize("none", selectedSlideIndex, textIndex));
            } else {
                dispatch(onTextCapitalize("capitalize", selectedSlideIndex, textIndex));
            }
        }

        else if (type === 'lowercase') {
            if (styleState.textTransform === "lowercase") {
                dispatch(onTextLower("none", selectedSlideIndex, textIndex));
            } else {
                dispatch(onTextLower("lowercase", selectedSlideIndex, textIndex));
            }
        } else if (type === 'underline') {
            if (styleState.textDecoration === "underline") {
                dispatch(onTextUnderline("none", selectedSlideIndex, textIndex));
            } else {
                dispatch(onTextUnderline("underline", selectedSlideIndex, textIndex));
            }
        } else if (type === 'line-through') {
            if (styleState.textDecoration === "line-through") {
                dispatch(onTextStrike("none", selectedSlideIndex, textIndex));
            } else {
                dispatch(onTextStrike("line-through", selectedSlideIndex, textIndex));
            }
        }
    }

    const onAlign = (align) => {
        setSlideAll(false);
        dispatch(onTextAlign(align, selectedSlideIndex, textIndex));
    }

    const handleColor = (e, type) => {
        if (type === "text") {
            dispatch(onFontColor(e.hex, selectedSlideIndex, textIndex));

        } else {
            dispatch(onFontBgColor(e.hex, selectedSlideIndex, textIndex));
        }
    }

    const onInputChange = (e, type) => {
        setSlideAll(false);
        if (type === 'size') {
            dispatch(onFontSize(e.target.value, selectedSlideIndex, textIndex));
        } else if (type === 'font_family') {
            dispatch(onFontFamily(e.target.value, selectedSlideIndex, textIndex));
        }
    }

    const onChangeLineHeight = (e) => {
        setSlideAll(false);
        dispatch(onTextLineHeight(e.target.value, selectedSlideIndex, textIndex))
    }

    const fetchFonts = () => {
        dispatch(onFetchFont(setFonts, false))
    }

    const applyChanges = () => {
        if (slideAll === false) {
          setSlideAll(true);
          dispatch(applyToAllSlide(styleState, selectedText.innerCss));
        }
      }

    useEffect(() => {
        if (selectedText) {
            setStyleState({
                ...selectedText.style
            })
            setLayerText(selectedText.src)
        }
    }, [selectedText])

    useEffect(() => {
        fetchFonts()
    }, [])

    return (
        <>
            <div className='upload-txt'>
                <div className='inner-tab'>
                    <Tab.Container defaultActiveKey="first" justify>
                        <Nav variant="n" className="">
                            <Nav.Item>
                                <Nav.Link eventKey="first">Text Customization</Nav.Link>
                            </Nav.Item>
                            {/*<Nav.Item>*/}
                            {/*    <Nav.Link eventKey="second">Generate</Nav.Link>*/}
                            {/*</Nav.Item>*/}
                        </Nav>

                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <div className='font-heading'>
                                    <ul>
                                        <li style={{ cursor: "pointer" }} onClick={addTextLayer}>Add a text layer</li>
                                    </ul>
                                </div>
                                {selectedText ? selectedText.type === "text" ?
                                    <>
                                        <div className='inp-wrap mt-2'>
                                            <ContentEditable
                                                className="input"
                                                html={layerText}
                                                disabled={false}
                                                // onBlur={handleBlur}
                                                // onClick={(e) => handleClick(e)}
                                                // onMouseUp={() => handleHighlight()}
                                                onChange={(e) => onLayerTextChange(e.target.value)}
                                                style={{ height: "100px", overflowX: "hidden", overflowY: "auto", color: "#000" }}
                                            />
                                            {/* <textarea placeholder='e.g : A rocket launching at sunset' className='input' name="" id="" /> */}
                                        </div>
                                        <div className='box-cont'>
                                            <label htmlFor="">Size</label>
                                            <div className='box-cont-in'>
                                                <div className='txt-size'>
                                                    <span>Aa</span>
                                                    <div className="slidecontainer">

                                                        <input
                                                            type="range"
                                                            min="10"
                                                            max="100"
                                                            className="slider"
                                                            id="myRange"
                                                            value={styleState.fontSize}
                                                            onChange={(e) => onInputChange(e, "size")}
                                                        />
                                                    </div>
                                                    <span>Aa</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='inp-wrap'>
                                            <label htmlFor="">Fonts</label>
                                            <select
                                                className='input solid'
                                                value={styleState.fontFamily}
                                                style={{ fontFamily: styleState.fontFamily }}
                                                onChange={(e) => onInputChange(e, 'font_family')}
                                            >
                                                <option value={""} >Select Font</option>
                                                {
                                                    fonts.length > 0 ?
                                                        fonts.map((font, index) => {
                                                            return (
                                                                <option style={{ fontFamily: font }}
                                                                    value={font} key={index}>
                                                                    {font}
                                                                </option>
                                                            )
                                                        })
                                                        : ''
                                                }
                                            </select>
                                        </div>
                                    </>
                                    : "" : ""}
                                {selectedText ? selectedText.type === "text" || selectedText.type === "shape" ?
                                    <div className='row text-picker'>
                                        {selectedText.type === "text" ?
                                            <div className='col-6'>
                                                <ColorPicker
                                                    name="color"
                                                    from="text"
                                                    color={styleState.color}
                                                    callBack={handleColor}
                                                />
                                            </div> : ""}

                                        <div className='col-6'>
                                            <ColorPicker
                                                name="background color"
                                                from="textBg"
                                                color={styleState.background}
                                                callBack={handleColor}
                                            />
                                        </div>
                                    </div> : "" : ""}
                                {selectedText ? selectedText.type === "text" ?
                                    <>
                                        <div className='box-cont'>
                                            <label htmlFor="">Type Settings</label>
                                            <div className='box-cont-in'>
                                                <div className='type-settings'>
                                                    <ul>
                                                        <li title='Bold' className={styleState.fontWeight === "bold" ? 'active' : ""} onClick={() => onTextStyle("bold")}><BiBold /></li>
                                                        <li title='Italic' className={styleState.fontStyle === "italic" ? 'active' : ""} onClick={() => onTextStyle("italic")}><AiOutlineItalic /></li>
                                                        <li title='Underline' className={styleState.textDecoration === "underline" ? 'active' : ""} onClick={() => onTextStyle("underline")}><BiUnderline /></li>
                                                        <li title='Strike' className={styleState.textDecoration === "line-through" ? 'active' : ""} onClick={() => onTextStyle("line-through")}><RiStrikethrough2 /></li>
                                                        <li title='Uppercase' className={styleState.textTransform === "uppercase" ? 'active' : ""} onClick={() => onTextStyle("uppercase")}><RxLetterCaseUppercase /></li>
                                                        <li title='Capitalize' className={styleState.textTransform === "capitalize" ? 'active' : ""} onClick={() => onTextStyle("capitalize")}><RxLetterCaseCapitalize /></li>
                                                        <li title='Lowercase' className={styleState.textTransform === "lowercase" ? 'active' : ""} onClick={() => onTextStyle("lowercase")}><RxLetterCaseLowercase /></li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='box-cont'>
                                            <label htmlFor="">Align</label>
                                            <div className='box-cont-in'>
                                                <div className='type-settings'>
                                                    <ul>
                                                        <li title='Text Left' className={styleState.textAlign === "left" ? 'active' : ""} onClick={() => onAlign("left")}><AiOutlineAlignLeft /></li>
                                                        <li title='Text Center' className={styleState.textAlign === "center" ? 'active' : ""} onClick={() => onAlign("center")}><AiOutlineAlignCenter /></li>
                                                        <li title='Text Right' className={styleState.textAlign === "right" ? 'active' : ""} onClick={() => onAlign("right")}><AiOutlineAlignRight /></li>
                                                        <li title='Text Justify' className={styleState.textAlign === "justify" ? 'active' : ""} onClick={() => onAlign("justify")}><RiAlignJustify /></li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row text-picker'>
                                            <div className='col-6'>
                                                <div className='box-cont' >
                                                    <label htmlFor="">Line Height</label>
                                                    <div className='box-cont-in'>
                                                        <div className='type-settings'>
                                                            <ul>
                                                                <li>
                                                                    <input
                                                                        className='input solid'
                                                                        type="number"
                                                                        min={1}
                                                                        step="0.1"
                                                                        value={styleState.lineHeight}
                                                                        onChange={onChangeLineHeight}
                                                                    />
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-6'>
                                                <div className='box-cont' >
                                                    <label htmlFor="">Apply To All Slide</label>
                                                    <div className='box-cont-in'>
                                                        <label className="switch">
                                                            <input
                                                                className='input solid'
                                                                type="checkbox"
                                                                checked={slideAll}
                                                                disabled={slideAll}
                                                                onChange={() => applyChanges()}
                                                            />
                                                            <span className="sliderN round"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </> : "" : ""}
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <div className='inp-wrap mt-3'>
                                    <label htmlFor="">Number of words</label>
                                    <div className='row'>
                                        <div className='col-6'><input className='input solid' type="number" /></div>
                                    </div>
                                </div>
                                <div className='inp-wrap mt-3'>
                                    <label htmlFor="">Language</label>
                                    <select name="" id="" className='input solid'>
                                        <option>English</option>
                                    </select>
                                </div>
                                <div className='inp-wrap'>
                                    <label htmlFor="">Describe the Topic you want</label>
                                    <textarea placeholder='e.g : A rocket launching at sunset' className='input' name="" id=""></textarea>
                                </div>
                                <div className='inp-wrap text-end'>
                                    <button className='site-link'>Regenerate</button>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>
        </>
    )

}

export default Text;