import React, { useEffect, useState } from "react";
import telegramImage from "../../../assets/images/telegram_new.png";
import { useDispatch, useSelector } from "react-redux";
import { saveTelegram } from "../../../Redux/Actions/ClientAction";
import { Modal } from "react-bootstrap";
// import { feUrl } from "../../global/global";

const TelegramConnect = ({ checkAccount, editClient, fetchClientData, fromSocial}) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [loader, setLoader] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [telegramInput, setTelegramInput] = useState({
        clientId: editClient.id,
        user_id: auth.user.id,
        chat_id: null,
        bot_token: null
    })

    const connectTelegram = (e) => {
        setShowPopup(true);
    }

    const closeConnectModel = () => {
        setShowPopup(false);
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setLoader(true);

        dispatch(saveTelegram(telegramInput, setShowPopup, setLoader, fetchClientData));
    }

    const onInputChange = (e) => {
        setTelegramInput({ ...telegramInput, [e.target.name]: e.target.value });
    }

    useEffect(() => {
        setTelegramInput({ ...telegramInput, clientId: editClient.id, user_id: auth.user.id });
    }, [editClient])

    return (
        <>
            <span style={{ cursor: "pointer" }} className={`${checkAccount === false ? 'hide-button' : ''}`} onClick={(e) => connectTelegram(e)} >
                {fromSocial ?
                    <a className='site-link integr_card mt-4' style={{ padding: "2px 20px", fontSize: "14px", background: '#4fc5e1', borderColor: "#4fc5e1" }} > <span> Connect</span> </a> :

                    <img className={`${checkAccount === false ? 'client-img-opacity' : ''}`} src={telegramImage} alt="telegram" />
                }
            </span>

            < Modal className='theme-modal xl' show={showPopup} onHide={closeConnectModel} centered scrollable >
                <form onSubmit={(e) => onFormSubmit(e)}>
                    <Modal.Body>
                        <div className='info-tab'>
                            <h3>Connect Telegram</h3>
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <div className='common-input-wrap'>
                                        <label htmlFor="">Chat ID</label>
                                        <input
                                            className='common-input'
                                            type="text"
                                            name="chat_id" onChange={(e) => onInputChange(e)}
                                            placeholder="Enter Chat ID" required
                                        />
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='common-input-wrap'>
                                        <label htmlFor="">Bot Token</label>
                                        <input
                                            className='common-input'
                                            type="text"
                                            placeholder="Enter Bot Token" required
                                            name="bot_token" onChange={(e) => onInputChange(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='site-link bdr' type="button" onClick={(e) => closeConnectModel(e)}><span>Cancel</span></button>
                        <button className='site-link' type="submit" disabled={loader}><span>{loader ? <>Connecting <i className="fa fa-spinner fa-spin mx-1" /></> : "Connect"}</span></button>
                    </Modal.Footer>
                </form>
            </Modal>

        </>
    );
}

export default TelegramConnect;