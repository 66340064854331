import React from 'react'
import { FiChevronDown, FiUpload, FiEdit3, FiTrash2 } from 'react-icons/fi'
import { MdSchedule } from 'react-icons/md'
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import PushModal from '../pages/MyContent/PushModal';
import { useState } from 'react';
import SweetAlert from './SweetAlert';
import { useDispatch } from 'react-redux';
import { onDeleteCampaign } from '../../Redux/Actions/CommonActions';
import { useSelector } from 'react-redux';

const ImageCard = ({ curElem, fetchImages, type, contentStream }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
    })

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)

    const onDelete = (tId) => {
        setSweet({
            ...sweet,
            enable: true,
            id: tId
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete"
        })
    }

    const performDelete = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting..."
        })

        let url = "delete-image-campaign"
        if (type === "text") {
            url = "delete-text-campaign"
        }
        dispatch(onDeleteCampaign(url, data, fetchImages, setSweet))
    }
    return (
        <div className='col-sm-4'>
            <div className='video-listing-single'>
                <div className='video-listing-img'><img alt='' src={curElem.thumbnail} /></div>
                <div className='video-listing-bottom'>
                    <div className='video-listing-info'>
                        <h3 className='text-capitalize'>{curElem.name}</h3>
                        <p>{type === "text" ? curElem.created : curElem.url ? curElem.created : "Awaiting Customization"}</p>
                    </div>
                    <div className='video-listing-action'>
                        <Dropdown>
                            <Dropdown.Toggle variant="hh" id="dropdown-basic">
                                <FiChevronDown />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <ul>
                                    {curElem.url || type === 'text' ?
                                        <>
                                            <li style={{ cursor: "pointer" }} onClick={() => setShow(true)}> <a><span><FiUpload /></span> Push</a></li>
                                            <li> <Link to={"/schedule"}><span><MdSchedule /></span> Schedule</Link></li>
                                        </>
                                        : ""}
                                    {+auth.user.is_client_account === 1 ? "" :
                                        <>
                                            {/*{type === "text" ?*/}
                                            <li><Link to={type === "text" ? `/my-content/text-set?id=${curElem.id}` : `/my-content/image-editor?id=${curElem.cId}&type=${curElem.type === "image" ? "posts" : curElem.type}`}><span><FiEdit3 /> </span> Edit</Link></li>
                                            {/*: ""*/}
                                            {/*}*/}
                                            <li style={{ cursor: "pointer" }} className='cursor-pointer' onClick={() => onDelete(curElem.id)}>
                                                <a><span><FiTrash2 /></span> Delete</a>
                                            </li>
                                        </>}
                                </ul>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <PushModal
                curElem={curElem}
                show={show}
                type={type}
                handleClose={handleClose}
                contentStream={contentStream}
            />

            <SweetAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this campaign?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
            />
        </div >
    )
}

export default ImageCard