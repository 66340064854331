import React, { useEffect, useState } from 'react';
import TitleBar from '../../title-bar/TitleBar';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from "query-string"
import { onFetchText, onSaveText } from '../../../Redux/Actions/ImageAction';

const TextSet = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const id = queryString.parse(location.search).id
    const [pageLoader, setPageLoader] = useState(true)
    const [save, setSave] = useState(false)

    const [data, setData] = useState({
        text: "",
        name: ""
    })
    const fetchScript = () => {
        let data = {
            id: id
        }
        dispatch(onFetchText(data, setData, setPageLoader))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let obj = {
            id: id,
            text: data.text
        }
        setSave(true)
        dispatch(onSaveText(obj, setSave))
    }

    useEffect(() => {
        if (id) {
            fetchScript()
        }
    }, [id])
    return (
        !pageLoader ?
            <>
                <TitleBar title="Text Script" />
                <Header />

                <section className='page-wrap'>
                    <div className='container'>
                        <h1 className='page-title'>{data.name}</h1>

                        <form className='cont-box' onSubmit={handleSubmit}>

                            <div className='common-input-wrap mt-0'>
                                <textarea
                                    className='common-input large'
                                    value={data.text}
                                    onChange={(e) => setData({
                                        ...data,
                                        text: e.target.value
                                    })}
                                    required
                                />
                            </div>
                            <div className='client-btn'>
                                <button className='site-link bdr ms-0' type='submit'><span>{save ? <>Saving <i className="fa fa-spinner fa-spin mx-1" /></> : "Save Scripts"}</span></button>
                            </div>
                        </form>

                    </div>
                </section>

                <Footer />
            </>
            :
            <div className="loader-sec">
                <div className="loader" />
            </div>

    )

}

export default TextSet;