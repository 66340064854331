import React from 'react'

const Webinar = () => {
    return (
        <section className='webinar'>
            <div className='container sm'>
                <div className='row align-items-center'>
                    <div className='col-sm-6'>
                        <div>
                            <img alt='' src={require('../../../assets/images/abhi.png')} />
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='image-block-txt'>
                            <h6>AI-SOCIAL LESSON #1</h6>
                            <h2>Join Us for live webinar</h2>
                            <p>Join us for a private training that will walk you through how to use AI-Social to help you create and sell videos for maximum engagement, views and profits. This training or upgrade will not be made available afterwards. Sign up and make sure to show up on this live training with all your questions</p>
                            <a className='site-link mt-4' href="https://abhineos.com/training" target='_blank'><span>Signup for training</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Webinar