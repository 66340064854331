import React, { useEffect, useState } from 'react'
import Header from '../../header/Header'
import Footer from '../../footer/Footer'
import { RiDeleteBin6Line } from 'react-icons/ri';
import youtube from '../../../assets/images/youtube.png'
import Facebook from '../../../assets/images/facebook-1.png';
import LinkedIn from '../../../assets/images/linkedin-1.png';
import twitter from '../../../assets/images/twitter-x.png';
import telegram_new from '../../../assets/images/telegram_new.png';
import vimeo_new from '../../../assets/images/vimeo_new.png';
import Instagram from '../../../assets/images/instagram-1.png';
import wordpresslogo from '../../../assets/images/wordpress-logo.png';
import reddit_new from '../../../assets/images/reddit_new.png';
import medium_logo from '../../../assets/images/medium-logo.png';
import { useSelector } from 'react-redux';
import { onDeleteSocial, onFetchClient } from '../../../Redux/Actions/ClientAction';
import { useDispatch } from 'react-redux';
import FacebookConnect from '../Clients/FacebookConnect';
import LinkedInConnect from '../Clients/LinkedInConnect';
import TelegramConnect from '../Clients/TelegramConnect';
import Youtube from '../Clients/Youtube';
import TwitterConnect from '../Clients/TwitterConnect';
import VimeoConnect from '../Clients/VimeoConnect';
import InstagramConnect from '../Clients/InstagramConnect';
import WordpressConnect from '../Clients/WordpressConnect';
import RedditConnect from '../Clients/RedditConnect';
import MediumConnect from '../Clients/MediumConnect';
import { FaTrash } from 'react-icons/fa';
import SweetAlert from '../../CommonComponent/SweetAlert';
import { useNavigate } from 'react-router-dom';


const Integration = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const auth = useSelector(state => state.auth)
    const state = {
        id: auth.user.id
    }


    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
    })

    const [loader, setLoader] = useState(false)
    const [socialActive, setSocialActive] = useState([])
    const rawData = [
        { type: "youtube" }, { type: "facebook" }, { type: "reddit" }, { type: "telegram" }, { type: "vimeo" }, { type: "wordpress" }, { type: "medium" }, { type: "linkedin" }, { type: "instagram" }, { type: "twitter" }
    ]

    const fetchClientData = () => {
        let data = {
            id: auth.user.id
        }
        setLoader(true)
        dispatch(onFetchClient(data, setSocialActive, setLoader))
    }

    const onDelete = (tId) => {
        setSweet({
            ...sweet,
            enable: true,
            id: tId
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete"
        })
    }

    const performDelete = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting..."
        })
        dispatch(onDeleteSocial(data, fetchClientData, setSweet))
    }
    useEffect(() => {
        fetchClientData()
    }, [])

    useEffect(() => {
        if (auth) {
            if (+auth.user.is_client_account === 1) {
            } else {
                navigate("/")
            }
        }
    }, [auth])


    return (
        <>
            <Header />

            <section className='page-wrap'>
                <div className='container'>
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="int_head ">
                                <h2>Connected</h2>
                                <hr style={{ opacity: "1", color: "#619cd8" }} />
                            </div>
                        </div>
                        {socialActive.length > 0 ?
                            socialActive.map((curElem, index) => {

                                const image = curElem.type === "youtube" ? youtube :
                                    curElem.type === "facebook" ? Facebook :
                                        curElem.type === "instagram" ? Instagram :
                                            curElem.type === "reddit" ? reddit_new :
                                                curElem.type === "telegram" ? telegram_new :
                                                    curElem.type === "vimeo" ? vimeo_new :
                                                        curElem.type === "wordpress" ? wordpresslogo :
                                                            curElem.type === "medium" ? medium_logo :
                                                                curElem.type === "linkedin" ? LinkedIn :
                                                                    curElem.type === "twitter" ? twitter :
                                                                        ""
                                return (
                                    <div className="col-md-3" key={index}>
                                        <div className="integr-single connected">
                                            <div className="connection-box-main">
                                                <div className="connection-img">
                                                    <img src={image} width="56px" alt="youtube" />
                                                </div>

                                                <div className="connection-txt">
                                                    <div className="noConnect">
                                                        <div className="youbtube-connection">
                                                            <div className="youtube-Wrap">
                                                                <h6 className="text-capitalize">{curElem.type}</h6>
                                                                <p className="text-capitalize">{curElem.title}</p>
                                                                <p>Added on - {curElem.created}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="integr-del" style={{ cursor: "pointer" }} onClick={() => onDelete(curElem.id)}>
                                                <FaTrash size={20} />
                                            </span>
                                            <span className="tag-connected">Connected</span>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <div className='fetch-loader-block'>
                                {loader ?
                                    <i className="fa fa-spinner fa-spin fetch-loader" />
                                    :
                                    <span style={{ fontSize: 15, color: "#00000" }}>
                                        You have no social account connected yet!
                                    </span>
                                }
                            </div>

                        }

                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="int_head">
                                <h2>Available</h2>
                                <hr style={{ opacity: "1", color: "#619cd8" }} />

                            </div>
                        </div>
                        {rawData.map((curElem, index) => {
                            const image = curElem.type === "youtube" ? youtube :
                                curElem.type === "facebook" ? Facebook :
                                    curElem.type === "instagram" ? Instagram :
                                        curElem.type === "reddit" ? reddit_new :
                                            curElem.type === "telegram" ? telegram_new :
                                                curElem.type === "vimeo" ? vimeo_new :
                                                    curElem.type === "wordpress" ? wordpresslogo :
                                                        curElem.type === "medium" ? medium_logo :
                                                            curElem.type === "linkedin" ? LinkedIn :
                                                                curElem.type === "twitter" ? twitter :
                                                                    ""
                            const compo = curElem.type === "youtube" ? <Youtube checkAccount={true} editClient={state} fetchClientData={fetchClientData} fromSocial={true} /> :
                                curElem.type === "facebook" ? <FacebookConnect checkAccount={true} editClient={state} fetchClientData={fetchClientData} fromSocial={true} /> :
                                    curElem.type === "instagram" ? <InstagramConnect checkAccount={true} editClient={state} fetchClientData={fetchClientData} fromSocial={true} /> :
                                        curElem.type === "reddit" ? <RedditConnect checkAccount={true} editClient={state} fetchClientData={fetchClientData} fromSocial={true} /> :
                                            curElem.type === "telegram" ? <TelegramConnect checkAccount={true} editClient={state} fetchClientData={fetchClientData} fromSocial={true} /> :
                                                curElem.type === "vimeo" ? <VimeoConnect checkAccount={true} editClient={state} fetchClientData={fetchClientData} fromSocial={true} /> :
                                                    curElem.type === "wordpress" ? <WordpressConnect checkAccount={true} editClient={state} fetchClientData={fetchClientData} fromSocial={true} /> :
                                                        curElem.type === "medium" ? <MediumConnect checkAccount={true} editClient={state} fetchClientData={fetchClientData} fromSocial={true} /> :
                                                            curElem.type === "linkedin" ? <LinkedInConnect checkAccount={true} editClient={state} fetchClientData={fetchClientData} fromSocial={true} /> :
                                                                curElem.type === "twitter" ? <TwitterConnect checkAccount={false} editClient={state} fetchClientData={fetchClientData} fromSocial={true} /> :
                                                                    ""
                            return (
                                <div className="col-md-3" key={index}>
                                    <div className="integr-single connected" style={{ borderColor: "gray" }}>
                                        <div className="connection-box-main">
                                            <div className="connection-img">
                                                <img className="disable-social-img" style={{ opacity: 0.5 }} src={image} width="56px" alt="youtube" />
                                            </div>
                                            <div className="connection-txt">
                                                <div className="noConnect">
                                                    <div className="youbtube-connection">
                                                        <div className="youtube-Wrap">
                                                            <h6 className="text-capitalize">{curElem.type}</h6>
                                                            {compo}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </section >

            <SweetAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this Social account?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
            />
            <Footer />
        </>
    )
}

export default Integration