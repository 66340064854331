import React from 'react'

const GoogleTrend = ({ curElem }) => {

    const handleVisit = (url) => {
        window.open(url, '_blank');
    }
    return (
        <>
            <div className='col-xl-6' >
                <div className='trending-details-single'>
                    <div className='trending-left'>
                        <div className='trending-img'><img alt='' src={curElem.articles[0]?.image?.imageUrl} /></div>
                    </div>
                    <div className='trending-right'>
                        <div className='trending-title'>
                            <h3>{curElem.articles[0].title}</h3>
                        </div>
                        <div className='bottom-btn-group'>
                            <button type='button' className='site-link ms-0' onClick={() => handleVisit(curElem.articles[0].url)}><span>Visit</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GoogleTrend