import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import ImageCard from '../../CommonComponent/ImageCard';
import { useDispatch } from 'react-redux';
import { fetchData } from '../../../Redux/Actions/CommonActions';

const ImageCardsSection = ({ contentStream }) => {
    const dispatch = useDispatch()
    const [images, setImages] = useState([])
    const [loader, setLoader] = useState({
        fetch: false
    })

    const fetchImages = () => {
        let data = {}
        setLoader({
            fetch: true
        })
        dispatch(fetchData("fetch-image-campaign", data, setImages, loader, setLoader, 3))
    }

    useEffect(() => {
        fetchImages()
    }, [])

    return (
        <section className='recent-video'>
            <div className='container'>
                <div className='sec-intro'>
                    <h2>Recently Created Posts</h2>
                    <p>Your creative journey captured in pixels. Explore your latest image creations, ready to captivate your audience.</p>
                </div>
                <div className='video-listing'>
                    <div className='row'>
                        {images.length > 0 ?
                            images.map((curElem, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <ImageCard
                                            curElem={curElem}
                                            fetchImages={fetchImages}
                                            contentStream={contentStream}
                                        />
                                    </React.Fragment>
                                )
                            })
                            : <div className='fetch-loader-block'>
                                {loader.fetch ?
                                    <i className="fa fa-spinner fa-spin fetch-loader" />
                                    :
                                    <span style={{ fontSize: 15, color: "#000000" }}>
                                        You have not created any posts yet!
                                    </span>
                                }
                            </div>}
                    </div>
                </div>
                {images.length > 0 ?
                    <div className='load-more'>
                        <Link className='site-link' to="/my-content/content?type=images"><span>View All</span></Link>
                    </div> : ""}
            </div>
        </section>
    )
}

export default ImageCardsSection