import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchData } from '../../../../../Redux/Actions/CommonActions'
import TransCard from './TransCard'

const Transition = () => {
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)

  const [data, setData] = useState([])
  const [loader, setLoader] = useState({
    fetch: false
  })

  const fetchTransition = () => {
    setLoader({
      ...loader,
      fetch: true
    })
    dispatch(fetchData("fetch-transition", {}, setData, loader, setLoader))
  }

  useEffect(() => {
    fetchTransition()
  }, [])

  return (
    <div className='list-images'>
      <h6 className='mb-1'>Transitions</h6>
      <ul style={{ height: 600, overflow: 'auto' }}>
        {
          data.length > 0 ?
            data.map((curElem, index) => {
              return (
                <React.Fragment key={index}>
                  <TransCard
                    name={curElem.name}
                    preview={curElem.preview}
                    thumbnail={curElem.thumbnail}
                  />
                </React.Fragment>
              )
            })
            :
            loader.fetch ? <div className='fetch-loader-block' style={{ height: "100%", width: "100%" }}><i className="fa fa-spinner fa-spin fetch-loader" /></div> : ""

        }


      </ul>
    </div>
  )
}

export default Transition