import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onCreateBaseImage } from '../../../../Redux/Actions/ImageAction'
import { GiCheckMark } from "react-icons/gi"

const NoOfImages = ({ onSetInitialCanvas, canvas, setLoader, text }) => {

    const dispatch = useDispatch()
    const image = useSelector(state => state.image)
    const [selectedImgIndex, setSelectedImgIndex] = useState(-1)

    const handleChangeTemplate = (index) => {
        if (selectedImgIndex !== index) {
            setLoader(true)
            let data = JSON.parse(JSON.stringify(canvas.toJSON()));
            data.objects.forEach((curElem) => {
                if (curElem.type === "image") {
                    curElem.isOld = "Yes"
                }
            })
            let img = canvas.toDataURL({
                format: 'png',
                multiplier: image.dimension === "1920x1080" ? 2.5 : image.dimension === "1080x1920" ? 3 : 2
            });
            let baseObj = {
                type: "image",
                data: img
            }
            onSetInitialCanvas()

            dispatch(onCreateBaseImage(baseObj, selectedImgIndex, data, false, index, text))
        } 
    }

    useEffect(() => {
        if (image) {
            let ind = image.imageData.findIndex(({ isSelected }) => +isSelected === 1)
            if (ind !== -1) {
                setSelectedImgIndex(ind)
            }
        }
    }, [image])


    return (
        <div className='list-images'>
        <ul>
            {
                image ?
                    image.imageData.length > 0 ?
                        image.imageData.map((curElem, index) => {
                            return (
                                <li key={index}>
                                    <div className='list-images-single' onClick={() => handleChangeTemplate(index)} style={{ cursor: 'pointer' }} >
                                        <img alt="" src={curElem.thumbnail} />
                                        {+selectedImgIndex === +index ?
                                            <div className='select-tile-style'>
                                                <GiCheckMark />
                                            </div> : ""}
                                    </div>

                                </li>
                            )
                        })

                        : "" : ""}
        </ul>
    </div>
    )
}

export default NoOfImages