import React, { useEffect, useState } from 'react';
import AudioTrack from '../../../../CommonComponent/AudioTrack';
import { useDispatch } from 'react-redux';
import { fetchMedia } from '../../../../../Redux/Actions/CommonActions';
import InfiniteScroll from 'react-infinite-scroll-component';

const MediaLibrary = ({ onSelectAudio, selectedAudio, setSelectedAudio }) => {
    const dispatch = useDispatch()
    const [pageCount, setPageCount] = useState(1);
    const [audio, setAudio] = useState([])
    const [loader, setLoader] = useState({
        fetch: false
    })
    const fetchAudios = (page = 1) => {
        let data = {
            search_type: 'music',
            page_number: page,
            keyword: ''
        }

        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchMedia("load-library-audio", data, audio, setAudio, loader, setLoader, false))
    }

    useEffect(() => {
        fetchAudios()
    }, [])
    return (
        <div className='audio-list' id='scrollableAudio123' style={{ height: 500, overflowY: "auto" }}>
            <InfiniteScroll
                dataLength={audio.length} //This is important field to render the next data
                next={() => fetchAudios(pageCount + 1)}
                hasMore={true}
                scrollableTarget="scrollableAudio123"
            >
                <ul  >
                    {audio.length > 0 ?
                        audio.map((curElem, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <AudioTrack
                                        name={curElem.title}
                                        url={curElem.url}
                                        type="music"
                                        fetchAudios={fetchAudios}
                                        selectedAudio={selectedAudio}
                                        setSelectedAudio={setSelectedAudio}
                                        onSelectAudio={onSelectAudio}
                                        // currentUrl={currentUrl}
                                        // setCurrentUrl={setCurrentUrl}
                                        key={index}
                                        id={curElem.id}
                                    />
                                </React.Fragment>
                            )
                        })
                        :
                        ""
                    }
                </ul>
                {loader.fetch ?
                    <div className='fetch-loader-block' style={{ height: "100%", width: "100%" }}><i className="fa fa-spinner fa-spin fetch-loader" /></div>
                    : ""}
            </InfiniteScroll>

        </div>
    );
}

export default MediaLibrary;
