
import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"

export const onFetchClientReport = (data, setState, navigate, setLoader) => (dispatch, getState) => {
    commonAxios("client-report", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            } else {
                dispatch(setAlert(res.msg, "danger"))
                navigate("/report")
            }
            setLoader(false)
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
            console.log(err)
        })
}

export const onCreateAgency = (data, loader, setLoader, fetchAgency, handleClose) => (dispatch, getState) => {
    commonAxios("agency", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchAgency()
                handleClose()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, submit: false })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({ ...loader, submit: false })
            console.log(err)
        })
}

export const onUpdateAgency = (data, loader, setLoader, fetchAgency, handleClose) => (dispatch, getState) => {
    commonAxios("update-agency", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchAgency()
                handleClose()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, submit: false })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({ ...loader, submit: false })
            console.log(err)
        })
}

export const onFetchAgency = (data, state, setState, setAgencyName) => (dispatch, getState) => {
    commonAxios("fetch-agency", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState({
                    ...state,
                    id: res.data[0].id,
                    logo: res.data[0].logo,
                    name: res.data[0].name,
                    email: res.data[0].email,
                    phone: res.data[0].phone,
                    siteUrl: res.data[0].site
                })
                if (setAgencyName) {
                    setAgencyName(res.data[0].name)
                }
            }
        }).catch((err) => {
            console.log(err)
        })
}