import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { onDeleteClient } from '../../../Redux/Actions/ClientAction';
import SweetAlert from '../../CommonComponent/SweetAlert';
import { useDispatch } from 'react-redux';
import Youtube from '../../../assets/images/youtube.png'
import LinkedIn from '../../../assets/images/LinkedIn.png'
import Facebook from '../../../assets/images/Facebook.png'
import Vimeo from '../../../assets/images/vimeo_new.png'
import Telegram from '../../../assets/images/telegram_new.png'
import Reddit from '../../../assets/images/reddit_new.png'
import Wordpress from '../../../assets/images/wordpress-logo.png'
import Medium from '../../../assets/images/medium-logo.png'
import Twitter from '../../../assets/images/twitter-x.png'
import Instagram from '../../../assets/images/Instagram.png'

import { useEffect } from 'react';

const ClientCard = ({ curElem, fetchClient }) => {

    const dispatch = useDispatch()
    const [socialActive, setSocialActive] = useState({
        youtube: false,
        facebook: false,
        linkedin: false,
        telegram: false,
        vimeo: false,
        reddit: false,
        wordpress: false,
        medium: false,
        twitter:false,
        instagram:false
    });


    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
    })


    const onDelete = (tId) => {
        setSweet({
            ...sweet,
            enable: true,
            id: tId
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete"
        })
    }

    const performDelete = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting..."
        })
        dispatch(onDeleteClient(data, fetchClient, setSweet))
    }


    useEffect(() => {
        if (curElem.socialAccounts?.length > 0) {
            let obj = {
                youtube: false,
                facebook: false,
                linkedin: false,
                telegram: false,
                vimeo: false,
                reddit: false,
                wordpress: false,
                medium: false,
                twitter:false,
                instagram:false
            }
            curElem.socialAccounts.map((curElem) => {
                obj = {
                    ...obj,
                    [curElem.type]: true
                }
            })
            setSocialActive(obj)
        }
    }, [curElem])

    return (
        <div className='col-sm-6 col-xl-4'>
            <div className='client-single'>
                <div className='client-imgN'><img alt='' src={curElem.profile} /></div>
                <h3>{curElem.name}</h3>
                <p>{curElem.description}</p>
                <div className='client-social'>
                    <ul>
                        <li className='mt-1'><img className={socialActive.facebook ? "" : 'disable-social-img'} alt='' src={Facebook} /></li>
                        <li className='mt-1'><img className={socialActive.youtube ? "" : 'disable-social-img'} alt='' src={Youtube} /></li>
                        <li className='mt-1'><img className={socialActive.linkedin ? "" : 'disable-social-img'} alt='' src={LinkedIn} /></li>
                        <li className='mt-1'><img className={socialActive.vimeo ? "" : 'disable-social-img'} alt='' src={Vimeo} /></li>
                        <li className='mt-1'><img className={socialActive.telegram ? "" : 'disable-social-img'} alt='' src={Telegram} /></li>
                        <li className='mt-1'><img className={socialActive.reddit ? "" : 'disable-social-img'} alt='' src={Reddit} /></li>
                        <li className='mt-1'><img className={socialActive.wordpress ? "" : 'disable-social-img'} alt='' src={Wordpress} /></li>
                        <li className='mt-1'><img style={{ filter: socialActive.medium ? "" : "opacity(0.5)" }} className={socialActive.medium ? "" : 'disable-social-img'} alt='' src={Medium} /></li>
                        <li className='mt-1'><img  style={{ filter: socialActive.twitter ? "" : "opacity(0.5)" }} className={socialActive.twitter ? "" : 'disable-social-img'} alt='' src={Twitter} /></li>
                        <li className='mt-1'><img className={socialActive.instagram ? "" : 'disable-social-img'} alt='' src={Instagram} /></li>
                        
                    </ul>
                </div>
                <div className='client-btn'>
                    <button onClick={() => onDelete(curElem.id)} className='site-link bdr'><span>Delete</span></button>
                    <Link to={`/clients/add-client?id=${curElem.id}`} className='site-link'><span>Edit</span></Link>
                </div>
            </div>

            <SweetAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this client?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
            />
        </div>
    )
}

export default ClientCard
