import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { useDispatch, useSelector } from 'react-redux';
import Library from '../../../../CommonComponent/Library';
import Upload from '../../../CreateImage/EditorTabs/Background/Upload';
import { SketchPicker } from 'react-color';
import AiImageGenerate from '../../../../CommonComponent/AiImageGenerate';
import { updateBackground } from '../../../../../Redux/Actions/VideoActions';

const Background = () => {
  const dispatch = useDispatch()
  const campaign = useSelector(state => state.video.campaign)
  const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
  const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
  const [bgColor, setBgColor] = useState("")
  const [checkMedia, setCheckMedia] = useState(false)


  const handleBackground = (val, type) => {
    if (type === "images") {
      let data = { ...val }
      dispatch(updateBackground(data, selectedSlideIndex, "image"))
    } else if (type === "video") {
      let data = { ...val }
      dispatch(updateBackground(data, selectedSlideIndex, "video"))
    } else if (type === "color") {
      setCheckMedia(false)
      dispatch(updateBackground(val, selectedSlideIndex, "color"))
    }
    else if (type === "ui") {
      let data = {
        url: val
      }
      dispatch(updateBackground(data, selectedSlideIndex, "image"))
    }
  }


  useEffect(() => {
    if (selectedSlide) {
      if (selectedSlide.background[0].type === "color") {
        setBgColor(selectedSlide.background[0].src)
      }
    }
  }, [selectedSlide])
  return (
    <>

      <div className='library-cont'>
        <div className='inner-tab'>
          <Tab.Container defaultActiveKey="first" justify>
            <Nav variant="n" className="">
              <Nav.Item>
                <Nav.Link eventKey="first">Images</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">Videos</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="third">Upload</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="forth">Color</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="fifth">AI Image</Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="first">
                <Library
                  handleBackground={handleBackground}
                  sType={"images"}
                  type="layer"
                  setCheckMedia={setCheckMedia}
                  checkMedia={checkMedia}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <Library
                  handleBackground={handleBackground}
                  sType={"video"}
                  type="layer"
                  setCheckMedia={setCheckMedia}
                  checkMedia={checkMedia}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <Upload
                  from="videoEditor"
                  handleBackground={handleBackground}
                  setCheckMedia={setCheckMedia}
                  checkMedia={checkMedia}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="forth">
                <div className='sketch-background-ui'>
                  <SketchPicker
                    color={bgColor} onChange={(e) => handleBackground(e.hex, "color")}
                  />
                </div>
              </Tab.Pane>

              <Tab.Pane eventKey="fifth">
                <AiImageGenerate
                  handleBackground={handleBackground}
                  setCheckMedia={setCheckMedia}
                  checkMedia={checkMedia}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </>
  )

}

export default Background;