import React, { useState, useEffect } from 'react';
import { BsPlayFill } from 'react-icons/bs'
import { FaArrowRight } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai'
import { useDispatch } from 'react-redux';
import { fetchData } from '../../../Redux/Actions/CommonActions';
import { Link } from 'react-router-dom';
import BannerImg from "../../../assets/images/appbanner.jpg";
import modalCloseIcon from "../../../assets/images/modal-close.png";

const HomeBanner = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const rebrand = useSelector(state => state.rebrand)
    const [data, setData] = useState([{
        clientDetails: 0,
        clientEarning: 0,
        contentStream: 0,
        schedularData: 0,
        totalContent: 0
    }])
    const [client, setClient] = useState([])
    const [showClose, setShowClose] = useState(false)

    const [showPopup, setShowPopup] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const colorObj = {
        color: "#ffffff",
        position: "absolute",
        top: '-20px',
        right: "-21px",
        fontSize: 11,
        background: "#515151",
        width: 150,
        textAlign: "center",
        padding: "8px 7px",
        borderRadius: 2,
        cursor: "pointer"
    }
    const handelPopupClick = () => {
        document.cookie = `popupClicked${auth.user.id}=true; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
        setShowPopup(false);
    }

    const handelClose2 = () => {
        setShowPopup(false);
    }

    const handleMsgClick = () => {
        document.cookie = `popupClicked${auth.user.id}=true; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
        setShowPopup(false);
    }

    useEffect(() => {
        const popupCookie = document.cookie
        if (popupCookie.includes(`popupClicked${auth.user.id}=true`)) {
            setShowPopup(false)
        } else {
            setShowPopup(true)
        }
    }, []);
    const fetchClientData = () => {
        if (+auth.user.is_client_account) { } else {
            dispatch(fetchData("fetch-app-client", {}, setClient))
        }
    }

    const fetchDbData = () => {
        dispatch(fetchData("fetch-dashboard-data", {}, setData))
    }

    useEffect(() => {
        fetchDbData()
    }, [])

    useEffect(() => {
        if (auth) {
            fetchClientData()
        }
    }, [auth])
    return (
        <>
            <section className='hero-banner'>
                <div className='hero-banner-video'>
                    <iframe src="https://player.vimeo.com/video/854938420?badge=0&amp;autopause=0&amp;autoplay=1&amp;mute=1&amp;volume=0&amp;player_id=0&amp;app_id=58479&amp;loop=true" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                </div>
                <div className='container'>
                    <div className='hero-banner-in'>
                        <div className='hero-banner-txt'>
                            <h6>Welcome to</h6>
                            <h1>{rebrand.data ? rebrand.data.name : <> <span>AI</span> Socials</>}</h1>
                            <p>Navigate a world of insights and creativity, as {rebrand.data ? rebrand.data.name : "AI SOCIALS"} empowers your content strategy. Elevate engagement, track growth, and conquer social media success.</p>
                            <a className='site-link mt-4' onClick={handleShow}> <span><BsPlayFill /> Watch Video</span> </a>
                        </div>
                    </div>
                </div>
            </section>



            <div className='container'>
                <section className='stats'>
                    <div className='stats-single'>
                        <div className='stats-in forGraph'>
                            <div className='graph-img'><img alt='' src={require('../../../assets/images/graph.png')} /></div>
                            <div className='graph-txt'>
                                <p>Content Stream</p>
                                <h6>{+data[0].contentStream > 9 ? +data[0].contentStream : `0${+data[0].contentStream}`}</h6>
                            </div>
                        </div>
                    </div>
                    {+auth.user.is_client_account === 1 || +auth.user.is_virtual_account === 1 ? "" :
                        <div className='stats-single'>
                            <div className='stats-in forClients'>
                                <div className='client-top'>
                                    <h6>Clients</h6>

                                    <Link to="/clients">
                                        <FaArrowRight />
                                    </Link>
                                </div>
                                <div className='client-bottom'>
                                    <span className='client-img'>
                                        <ul>
                                            {client.length > 0 ?
                                                client.slice(0, 3).map((curElem, index) => {
                                                    return (
                                                        <li key={index}><img alt='' src={curElem.profile} /></li>
                                                    )
                                                })
                                                : ""}
                                        </ul>
                                        <div className='number-client'>{client.length > 100 ? "100+" : client.length > 9 ? client.length : `0${client.length}`}</div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    }
                    <div className='stats-single'>
                        <div className='stats-in for-content'>
                            <div className='content-sc-top'>
                                <p>Content <br /> schedule</p>
                                <h6>{+data[0].schedularData > 9 ? +data[0].schedularData : `0${+data[0].schedularData}`}</h6>
                            </div>
                            <div className='content-sc-bottom'>
                                <div>
                                    <p>Mon</p>
                                    <p>Tue</p>
                                    <p>Wed</p>
                                </div>
                                <div><img alt='' src={require('../../../assets/images/line.png')} /></div>
                            </div>
                        </div>
                    </div>
                    <div className='stats-single bg-1'>
                        <div className='stats-in'>
                            <div><img className='image-light' alt='' src={require('../../../assets/images/content.png')} /></div>
                            <div className='content-sc-top'>
                                <p>Content <br /> Created</p>
                                <h6>{+data[0].totalContent > 9 ? +data[0].totalContent : `0${data[0].totalContent}`}</h6>
                            </div>
                        </div>
                    </div>
                    <div className='stats-single bg-2'>
                        <div className='stats-in'>
                            <div><img alt='' src={require('../../../assets/images/dollar.png')} /></div>
                            <div className='content-sc-top'>
                                <p></p>
                                <h6>${data[0].clientEarning}</h6>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Modal className="theme-modal ai_img_wrap video-modal ds" show={showPopup} onHide={handelClose2} centered>
                <Modal.Body>
                    <div onClick={() => setShowClose(true)} className="vidClose" >
                        <div style={{ position: "relative" }}>
                            <div className='modalClose' onClick={handleClose}><AiOutlineClose style={{
                                fontSize: "30px", marginBottom: "3px", top: '-5px',
                                right: '-8px', position: "absolute"
                            }} /></div>
                            {showClose ?
                                <p style={colorObj} onClick={handleMsgClick}>Don't show me again</p> : ""}

                        </div>

                    </div>
                    <div className="modalVid">
                        {/* <a href="https://aIclubhub.com/" target="_blank" rel="nooper noreferrer" onClick={handelPopupClick}>
                                            <img src={BannerImg} alt="bannerImg" title="AiStaff-Fb-Group" />
                                        </a> */}
                        <div className="para_ai h-100">
                            <a href="https://aIclubhub.com/" target="_blank" rel="nooper noreferrer" onClick={handelPopupClick} style={{ display: "block" }} className="w-100 d-flex h-100 justify-content-center align-items-center flex-column">
                                <p style={{
                                    fontSize: "55px",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    lineHeight: "1.3",
                                    color: "#fff"
                                }}>Click Here To Join  Members <br /> Only Facebook Group </p>
                                <button className="btn btn-danger mt-3 ps-4 pe-4 pt-2 pb-2" style={{ display: " block", fontSize: "45px" }}> Click Here</button>
                            </a>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
            <Modal className='theme-modal video-modal xl' show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div className='modalClose' onClick={handleClose}><AiOutlineClose /></div>
                    <div className="ratio ratio-16x9">
                        <iframe class="embed-responsive-item" src="https://player.vimeo.com/video/859912381?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="1920" height="1074" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="AiSocialsWalkthrough"></iframe>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default HomeBanner