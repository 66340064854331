import React from 'react'
import LogoWatermark from './LogoWatermark'
import VolumeControl from './VolumeControl'
import { useDispatch, useSelector } from 'react-redux'
import { addSlideDuration, muteVideoAudio, unMuteVideoAudio } from '../../../../../Redux/Actions/VideoActions'
import { setAlert } from '../../../../../Redux/Actions/AlertActions'
import { VscMute, VscUnmute } from "react-icons/vsc"
import Prompt from './Prompt'

const Settings = () => {
  const dispatch = useDispatch()
  const campaign = useSelector(state => state.video.campaign)
  const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
  const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
  const selectedLayer = selectedSlide.layers.find(({ isSelected }) => isSelected === true)

  const logo = campaign.logo
  const watermark = campaign.waterMark

  const addDuration = (e) => {
    let num = e.target.value
    if (num > 300) {
      dispatch(setAlert("You can't set slide duration more than 300 sec.", "warning"))
      dispatch(addSlideDuration(300))
    } else {
      dispatch(addSlideDuration(num))
    }
  }

  const handleMuteVideoLayer = (data) => {
    const selectedLayerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
    if (data.mute === false) {
      dispatch(muteVideoAudio(selectedSlideIndex, selectedLayerIndex))
    }
  }

  function hasAudio(video) {
    return video.mozHasAudio ||
      Boolean(video.webkitAudioDecodedByteCount) ||
      Boolean(video.audioTracks && video.audioTracks.length);
  }

  const handleUnMuteVideoLayer = (selectedLayer) => {
    let video = document.querySelector('#checkAudioData');
    video.play()
    video.muted = true
    setTimeout(() => {
      let data = hasAudio(video)
      if (data) {
        const selectedLayerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
        if (selectedLayer.mute) {
          dispatch(unMuteVideoAudio(selectedSlideIndex, selectedLayerIndex))
        }
      } else {
        dispatch(setAlert("This video does not have an audio", "danger"))
      }
      video.pause()
    }, 1000)
  }

  return (
    <>
      <LogoWatermark
        type={"logo"}
        data={logo}
      />
      <hr />

      <LogoWatermark
        type={"watermark"}
        data={watermark}
      />
      <hr />


      <div className='inp-wrap mt-2'>
        <label>Duration (s)</label>
        <input
          className='input solid'
          name='duration'
          type="number"
          min={1}
          value={selectedSlide.duration}
          onChange={(e) => addDuration(e)}
        />
      </div>
      <hr />


      {selectedLayer ?
        selectedLayer.type === "video" ?
          <div className='inp-wrap  d-flex justify-content-between mt-4'>
            <label>Mute/Unmute Video</label>
            {selectedLayer.mute ?
              <VscMute
                size={25}
                onClick={() => handleUnMuteVideoLayer(selectedLayer)}
                color="#618fca"
                className="cursor-pointer"
              /> :
              <VscUnmute
                size={25}
                onClick={() => handleMuteVideoLayer(selectedLayer)}
                className="cursor-pointer"
                color="#618fca"
              />
            }
            <hr />

          </div>
          : "" : ""}


      <VolumeControl />
      <hr />

      <Prompt
        promo={campaign.promo}
      />

      {selectedLayer ?
        selectedLayer.type === "video" ?
          <video
            className="no-showvideotag"
            id="checkAudioData"
            controls
            src={selectedLayer.src ? selectedLayer.src : ""}
          /> : ""

        : ""
      }
    </>
  )
}

export default Settings