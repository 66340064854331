import React, { useEffect, useState } from 'react'
import Header from '../../header/Header'
import TitleBar from '../../title-bar/TitleBar'
import Footer from '../../footer/Footer'
import { fetchData, onFetchPlatform } from '../../../Redux/Actions/CommonActions'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom/dist'
import { onCreateText } from '../../../Redux/Actions/ImageAction'

const CreateText = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [country, setCountry] = useState([])
    const [language, setLanguage] = useState([])
    const [platform, setPlatform] = useState(false)

    const [create, setCreate] = useState({
        campaignName: "",
        keyword: "",
        platform: "",
        country: "",
        language: ""
    })


    const handleChange = (e) => {
        let { name, value } = e.target
        setCreate({
            ...create,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        let data = { ...create }
        dispatch(onCreateText(data, setLoader, navigate))
    }

    const fetchImgPlatform = () => {
        dispatch(onFetchPlatform("fetch-text-platform", {}, setPlatform))
    }

    const fetchLanguage = () => {
        dispatch(fetchData("fetch-google-language", {}, setLanguage, false, false, false, "shortDataLg"))
    }
    const fetchCountries = () => {
        dispatch(fetchData("countries", {}, setCountry, false, false, false, "shortDataCt"))
    }
    useEffect(() => {
        fetchLanguage()
        fetchCountries()
        fetchImgPlatform()

    }, [])
    return (
        <>
            <TitleBar title="Create Text" />
            <Header />

            <section className='page-wrap'>
                <div className='container'>
                    <h1 className='page-title'>Create Text</h1>

                    <form className='cont-box' onSubmit={handleSubmit}>

                        <div className='common-input-wrap mt-0'>
                            <label htmlFor="">Campaign Name</label>
                            <input
                                className='common-input'
                                type="text"
                                placeholder='Add Campaign Name'
                                name='campaignName'
                                value={create.campaignName}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className='common-input-wrap'>
                            <label htmlFor="">Shortly describe to create Text post.</label>
                            <input
                                className='common-input'
                                type="text"
                                placeholder='Describe post'
                                name='keyword'
                                value={create.keyword}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Platform</label>
                                    <select
                                        name="platform"
                                        className='common-input'
                                        onChange={handleChange}
                                        value={create.platform}
                                        required
                                    >
                                        <option value="">Select Platform</option>
                                        {platform.length > 0 ?
                                            platform.map((curElem, index) => {
                                                return (
                                                    <option key={index} value={curElem.id}>{curElem.name}</option>
                                                )
                                            })
                                            : ""}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Country</label>
                                    <select
                                        className='common-input'
                                        type="text"
                                        value={create.country}
                                        name='country'
                                        placeholder='Describe the image post'
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value={""}>Select Country</option>
                                        {country.length > 0 ?
                                            country.map((curElem, index) => {
                                                return (
                                                    <option
                                                        key={index}
                                                        value={curElem.country_name}
                                                    >
                                                        {curElem.country_name}
                                                    </option>
                                                )
                                            })
                                            : ""}
                                    </select>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Languages</label>
                                    <select
                                        className='common-input'
                                        type="text"
                                        value={create.language}
                                        name='language'
                                        placeholder='Describe the image post'
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value={""}>Select Language</option>
                                        {language.length > 0 ?
                                            language.map((curElem, index) => {
                                                console.log(language)
                                                return (
                                                    <option key={index} value={curElem.name}>{curElem.name}</option>
                                                )
                                            })
                                            : ""}
                                    </select>

                                </div>
                            </div>
                        </div>

                        <div className='client-btn'>
                            <button className='site-link ms-0' type='submit'><span>{loader ? <>Creating <i className="fa fa-spinner fa-spin mx-1" /></> : "Create"}</span></button>
                        </div>
                    </form>

                </div>
            </section>

            <Footer />
        </>
    )
}

export default CreateText