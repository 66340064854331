import React, { useState } from 'react';
import { Search } from 'react-iconly'
import TitleBar from '../../title-bar/TitleBar';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { fetchData } from '../../../Redux/Actions/CommonActions';
import ClientCard from './ClientCard';

const Clients = () => {

    const dispatch = useDispatch();
    const [loader, setLoader] = useState({
        fetch: false
    })
    const [Clients, setClients] = useState([])
    const [q, setQ] = useState('')

    const fetchClient = () => {
        let data = {}
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchData("fetch-app-client", data, setClients, loader, setLoader))
    }

    useEffect(() => {
        fetchClient()
    }, [])

    return (
        <>
            <TitleBar title="Home" />
            <Header></Header>

            <section className='page-wrap'>
                <div className='container'>
                    <h1 className='page-title'>My Clients</h1>

                    <div className='content-search'>
                        <div className='row'>
                            <div className='col-xl-10'>
                                <div className='input-wrap with-icon'>
                                    <span className='inp-icon'><Search /></span>
                                    <input className='inp' type="text" placeholder='Search' onChange={(e) => setQ(e.target.value)} />
                                </div>
                            </div>
                            <div className='col-xl-2'>
                                <Link className='site-link full bdr' to="/clients/add-client"><span>+ Add New Client</span></Link>
                            </div>
                        </div>
                    </div>

                    <div className='client-list'>
                        <div className='row'>

                            {Clients.length > 0 ?
                                Clients.filter((ele) => {
                                    return ele.name.toLowerCase().includes(q.toLowerCase())
                                }).map((curElem, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <ClientCard
                                                curElem={curElem}
                                                fetchClient={fetchClient}
                                            />
                                        </React.Fragment>
                                    )
                                })
                                : <div className='fetch-loader-block mt-5'>
                                    {loader.fetch ?
                                        <i className="fa fa-spinner fa-spin fetch-loader" />
                                        :
                                        <span style={{ fontSize: 15 }}>
                                            You do not have any clients yet!
                                        </span>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>

            <Footer></Footer>
        </>
    )

}

export default Clients;