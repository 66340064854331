import React, { useEffect, useState } from "react";
import redditImage from "../../../assets/images/reddit_new.png";
import { useDispatch, useSelector } from "react-redux";
import randomstring from "randomstring"
import { checkNetworkStatus } from "../../../Redux/Actions/ClientAction";
import { baseURL } from "../../../Global/Global";

const RedditConnect = ({ checkAccount, editClient, fetchClientData, fromSocial }) => {

    let interval = false
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [checkStatus, setCheckStatus] = useState(true);
    const [randomString, setRandomString] = useState(randomstring.generate());

    const connectReddit = (e) => {
        let url = `${baseURL}connect-reddit?token=${auth.token}&key=${randomString}&user_id=${auth.user.id}&clientId=${editClient.id}`;

        let myWindow = window.open(url, 'mywindow', 'width=500,height=600');

        interval = setInterval(() => {
            if (checkStatus) {
                dispatch(checkNetworkStatus(randomString, setCheckStatus, interval, myWindow, fetchClientData));
            }

        }, 5000);
    }


    useEffect(() => {
        return () => {
            clearInterval(interval)
        }
    }, [])

    return (
        <>
            <span style={{ cursor: "pointer" }} className={`${checkAccount === false ? 'hide-button' : ''}`} onClick={(e) => connectReddit(e)}>
                {fromSocial ?
                    <a className='site-link integr_card mt-4' style={{ padding: "2px 20px", fontSize: "14px", background: '#4fc5e1', borderColor: "#4fc5e1" }} > <span> Connect</span> </a>
                    :
                    <img className={`${checkAccount === false ? 'client-img-opacity' : ''}`} src={redditImage} alt="reddit-image" />
                }           </span>
        </>
    )

}


export default RedditConnect;