import React, { useState } from 'react';

function DfyWhitelabelContentStreams() {

  return (
    <>
      <div className="tabepaneIn ">
        <h2 className="tabTitle">
        DFY Whitelabel Content Streams
        </h2>
        <div className="tabepaneIn-main">
        
          <div className="col-md-12">
            <div className=" text-center  text-white">
              <div className="">
                <a
                  rel="noreferrer"
                  href="https://s3.us-west-2.amazonaws.com/virtualpioneer.net/Creative+Market/ContentStreams.zip"
                  target="_blank"
                >
                  <button className="site-link mt-0">

                    <span> DFY Whitelabel Content Streams</span>
                  </button>
                </a>
              </div>
            </div>

          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  )

}

export default DfyWhitelabelContentStreams;