import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchData } from '../../../Redux/Actions/CommonActions';
import VideoCard from '../../CommonComponent/VideoCard';

const VideosCardsSection = ({ contentStream }) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        fetch: false
    })
    const [videos, setVideos] = useState([])

    const fetchVideos = () => {
        let data = {}
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchData("fetch-video-campaign", data, setVideos, loader, setLoader, 3))
    }

    useEffect(() => {
        fetchVideos()
    }, [])

    return (
        <section className='recent-video'>
            <div className='container'>
                <div className='sec-intro'>
                    <h2>Recently Created Videos</h2>
                    <p>Watch your ideas come to life. Delve into your most recent videos primed to tell your story in motion.</p>
                </div>
                <div className='video-listing'>
                    <div className='row'>
                        {videos.length > 0 ?
                            videos.map((curElem, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <VideoCard
                                            curElem={curElem}
                                            fetchVideos={fetchVideos}
                                            contentStream={contentStream}
                                        />
                                    </React.Fragment>
                                )
                            })
                            : <div className='fetch-loader-block' >
                                {loader.fetch ?
                                    <i className="fa fa-spinner fa-spin fetch-loader" />
                                    :
                                    <span style={{ fontSize: 15, color: "#000000" }}>
                                        You have not created any videos yet!
                                    </span>
                                }
                            </div>}
                    </div>
                </div>
                {videos.length > 0 ?
                    <div className='load-more'>
                        <Link className='site-link' to="/my-content/content?type=videos"><span>View All</span></Link>
                    </div> : ""}
            </div>
        </section>
    )
}

export default VideosCardsSection