import React, { useEffect } from 'react'
import { useState } from 'react'
import { onRegenerateMeta } from '../../../../Redux/Actions/ImageAction'
import { useDispatch } from 'react-redux'
import { onUpadteMetaData } from '../../../../Redux/Actions/ImageAction';
const Meta = ({ imageData, setIsUpdate, text, setText }) => {
    const dispatch = useDispatch()

    const [loader, setLoader] = useState(false)
    const [id, setId] = useState(-1)
    const [indexofImage, setIndexofImage] = useState(-1)

    const handleGenerate = () => {
        setLoader(true)
        let data = {
            cId: id,
            text: text
        }
        setIsUpdate(false)
        dispatch(onRegenerateMeta(data, indexofImage, setLoader))
    }

    useEffect(() => {
        if (imageData) {
            let selctedTemp = imageData.find(({ isSelected }) => +isSelected === 1)
            let selctedTempIndex = imageData.findIndex(({ isSelected }) => +isSelected === 1)
            setIndexofImage(selctedTempIndex)
            setId(selctedTemp.cId)
            if (selctedTemp) {
                let te = selctedTemp.meta.replaceAll("?", "")
                // alert("'as,jmnbdvjkasbdkjb")
                te = te.replaceAll("♀", "")
                te = te.replaceAll("♂", "")
                te = te.replaceAll("⚥", "")
                setText(te)
            }
        }
    }, [imageData])



    const onChangeHandler = (e) => {
        const value = e.target.value;
        setText(value);
        // dispatch(onUpadteMetaData(indexofImage, value));
    }

    // console.log(imageData[indexofImage].meta)
    return (
        <>
            <div className='inp-wrap'>
                <label htmlFor="">Caption to be posted with image</label>
                <textarea
                    placeholder='Image Caption'
                    className='input'
                    value={text}
                    onChange={(e) => onChangeHandler(e)}
                />

            </div>
            <div className='inp-wrap text-end'>
                <button
                    className='site-link'
                    onClick={handleGenerate}
                ><span>
                        {loader ? <>Regenerating <i className="fa fa-spinner fa-spin" /></> : "Regenerate"}
                    </span>
                </button>
            </div>
        </>
    )
}

export default Meta