import React, { useCallback, useEffect, useState } from 'react';
import { Edit } from 'react-iconly'
import TitleBar from '../../title-bar/TitleBar';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { MdNumbers } from "react-icons/md"
import { TbTextSize } from 'react-icons/tb';
import { FaBorderStyle } from "react-icons/fa"
import { PiSelectionBackgroundFill } from 'react-icons/pi';
import { MdPermMedia } from 'react-icons/md';
import { BsFillLayersFill, BsGlobe, BsPaintBucket } from 'react-icons/bs';
import { BiSolidComponent } from 'react-icons/bi'
import { HiTemplate } from "react-icons/hi"
import NoOfImages from './EditorTabs/NoOfImages';
import Media from './EditorTabs/Media';
import Elements from './EditorTabs/Elements';
import Border from './EditorTabs/Border';
import Layers from './EditorTabs/Layers';
import Template from './EditorTabs/Template';
import Meta from './EditorTabs/Meta';
import MyCanvas from './MyCanvas';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { fabric } from 'fabric';
import { onCreateBaseImage, onFetchDesign, onSaveCanvas, onUnmountDesign } from '../../../Redux/Actions/ImageAction';
import Background from './EditorTabs/Background/Background';
import Canva from "./EditorTabs/Canva";
import ImageText from './EditorTabs/Text/ImageText';


// Canva.propTypes = {canvas: PropTypes.bool};
const ImageEditor = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [pageLoader, setPageLoader] = useState(false)
    const { id, type } = queryString.parse(location.search)
    const image = useSelector(state => state.image)
    const [selectedImg, setSelectedImg] = useState(false)
    const [variable, setVariable] = useState("img")
    const [canvas, setCanvas] = useState(false);
    const [pastHistory, setPastHistory] = useState([canvas])
    const [futureHistory, setFutureHistory] = useState([])
    const [canvasState, setCanvasState] = useState(canvas);
    const [canvasLoader, setCanvasLoader] = useState(false);
    const [loader, setLoader] = useState(true);
    const [canvasJson, setCanvasJson] = useState({
        data: []
    });
    const [isUpdate, setIsUpdate] = useState(true)
    const [isDownload, setIsDownload] = useState(false)
    const [text, setText] = useState("")

    const initCanvas = () => (
        new fabric.Canvas('canvas', {
            preserveObjectStacking: true,
            height: image.dimension === "1920x1080" ? 432 : image.dimension === "1080x1920" ? 640 : 540,
            width: image.dimension === "1920x1080" ? 768 : image.dimension === "1080x1920" ? 360 : 540,
        })
    )

    const initCanvasStyle = () => {
        fabric.Object.prototype.set({
            borderColor: '#618FCA',
            cornerColor: '#618FCA',
            cornerSize: 10,
            cornerStyle: 'circle',
            transparentCorners: false,
            padding: 5,
            rotatingPointOffset: 20,
            borderDashArray: [5, 5],
            objectCaching: true,
            hoverCursor: 'pointer'
        });
    }

    const saveJson = () => {
        let newCanvasState = JSON.stringify(canvas);
        setCanvasState(newCanvasState);
        setPastHistory(history => [...history, newCanvasState])
        setFutureHistory([])
    }


    const onObjectModified = useCallback(
        e => {
            const newCanvasState = e.target.canvas.toJSON();
            setCanvasState(newCanvasState);
            setPastHistory(history => [...history, newCanvasState])
            setFutureHistory([])
        },
        [setCanvasState, setPastHistory]
    );


    const onUndo = () => {
        let undoData = pastHistory.pop()
        if (undoData) {
            setFutureHistory(future => [...future, undoData])
            canvas.loadFromJSON(undoData)
        }
    }

    const onRedo = () => {
        let redoData = futureHistory.pop()
        if (redoData) {
            setPastHistory(history => [...history, redoData])
            canvas.loadFromJSON(redoData)
        }
    }

    const downloadCanvas = () => {
        const link = document.createElement('a');
        link.download = 'download.png';
        link.href = canvas.toDataURL({
            format: 'png',
            multiplier: image.dimension === "1920x1080" ? 2.5 : image.dimension === "1080x1920" ? 3 : 2
        }
            // 'image/png'
        );
        link.click();
    }

    const saveCanvas = () => {
        let obj = { ...image }
        const selectedIndex = obj.imageData.findIndex(({ isSelected }) => +isSelected === 1)
        let data = JSON.parse(JSON.stringify(canvas.toJSON()))
        data.objects.forEach((curElem) => {
            if (curElem.type === "image") {
                curElem.isOld = "Yes"
            }
        })
        let img = canvas.toDataURL({
            format: 'png',
            multiplier: image.dimension === "1920x1080" ? 2.5 : image.dimension === "1080x1920" ? 3 : 2
        });
        setCanvasLoader(true)
        let baseObj = {
            type: "image",
            data: img
        }
        dispatch(onCreateBaseImage(baseObj, selectedIndex, data, setIsDownload, text))


    }
    useEffect(() => {
        if (isDownload) {
            let data = { ...image }
            // const selectedIndex = data.imageData.findIndex(({ isSelected }) => +isSelected === 1)
            // data.imageData[selectedIndex].meta = text
            dispatch(onSaveCanvas(data, navigate, setCanvasLoader, setIsDownload))
        }
    }, [image, isDownload])




    // console.log(text)
    useEffect(() => {
        if (canvas) {
            canvas.loadFromJSON(canvas);
            canvas.on("object:modified", onObjectModified);

        }
    }, [canvas, onObjectModified]);
    // console.log(image.imageData)
    useEffect(() => {
        if (selectedImg) {
            canvas.loadFromJSON(selectedImg, function () {
                if(!canvas.backgroundColor){
                    canvas.backgroundColor = 'white';
                }
               if (type === "memes") {
                    let h, w
                    const img = new Image();
                    img.src = selectedImg.backgroundImage.src;
                    img.onload = function () {
                        h = this.height
                        w = this.width
                        canvas.setBackgroundImage(selectedImg.backgroundImage.src, canvas.renderAll.bind(canvas), {
                            scaleX: canvas.width / w,
                            scaleY: canvas.height / h,
                            crossOrigin: "*"
                        });
                    }
                }
                else {
                    if (variable === "img") {
                        canvas.forEachObject((curObj, index) => {
                            if (curObj.type === "image") {
                                if (curObj.isOld) { }
                                else {
                                    let h, w
                                    const img = new Image();
                                    img.src = curObj.src;
                                    img.onload = function () {
                                        h = this.height
                                        w = this.width
                                        const widthFactor = curObj.width / w
                                        const heightFactor = curObj.height / h
                                        const minFactor = Math.min(widthFactor, heightFactor)
                                        curObj.scale(minFactor)
                                        curObj.width = w;
                                        curObj.height = h;

                                        if (curObj.clipPath) {
                                            if (curObj.clipPath.type === "circle") {
                                                curObj.clipPath.height = h
                                                curObj.clipPath.width = w
                                                curObj.clipPath.radius = w < h ? w / 2 : h / 2
                                            } else if (curObj.clipPath.type === "ellipse") {
                                                curObj.clipPath.height = h
                                                curObj.clipPath.width = w
                                                curObj.clipPath.rx = w / 2
                                                curObj.clipPath.ry = h / 2
                                            } else if (curObj.clipPath.type === "rect") {
                                                curObj.clipPath.height = h
                                                curObj.clipPath.width = w
                                                curObj.clipPath.rx = w / 8
                                                curObj.clipPath.ry = h / 8
                                            }
                                        }
                                        canvas.renderAll();
                                    }
                                }
                            }
                        })
                    }
                    if (canvas.backgroundImage) {
                        const bgImage = new Image();
                        bgImage.src = canvas.backgroundImage.src;
                        bgImage.onload = function () {
                            let h1 = this.height
                            let w1 = this.width
                            canvas.backgroundImage.scaleY = canvas.height / h1
                            canvas.backgroundImage.scaleX = canvas.width / w1
                            canvas.backgroundImage.width = w1
                            canvas.backgroundImage.height = h1
                            canvas.renderAll();
                        }
                    }
                }
                canvas.renderAll();
                setLoader(false)
                setFutureHistory([])
                setPastHistory([])
            })
        }
    }, [selectedImg])

    const fetchDesign = () => {
        let data = { imageId: id }
        dispatch(onFetchDesign(data, setPageLoader))
    }


    useEffect(() => {
        if (canvas) {
            if (id === undefined) {
                canvas.dispose();
                setCanvas(initCanvas);
                initCanvasStyle();
            }
        }
    }, [location])

    const onSetInitialCanvas = () => {
        canvas.dispose();
    }
    useEffect(() => {
        if (image && isUpdate) {
            if (image.imageData.length > 0) {
                initCanvasStyle()
                setCanvas(initCanvas)
                const temp = image.imageData.find(({ isSelected }) => +isSelected === 1)
                if (temp) {
                    setSelectedImg(temp.data)
                    setVariable(temp.variable)
                } else {
                    setSelectedImg(image.imageData[0].data)
                }
            }
        }
        if (!isUpdate) {
            setIsUpdate(true)
        }
    }, [image])

    useEffect(() => {
        if (!loader) {
            setTimeout(() => {
                setPastHistory([canvas.toJSON()])
            }, 2000)
        }
    }, [loader])

    useEffect(() => {
        if (id) {
            fetchDesign()
        }
    }, [id])

    useEffect(() => {
        return () => {
            dispatch(onUnmountDesign())
        }
    }, [])

    // console.log(text)
    return (
        !pageLoader ?
            <>
                <TitleBar title="Image Editor" />
                <Header />

                <section className='page-wrap'>

                    <div className='title-block'>
                        <div className='container'>
                            <div className='title-block-in'>
                                <h1 className='page-title text-capitalize'>Create {type}</h1>
                                <div className='edit-title'>
                                    <p>{image.name}</p>
                                    <span
                                        onClick={() => { console.log(JSON.parse(JSON.stringify(canvas.toJSON()))) }}
                                    ><Edit /></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className='container'>
                            <div className='editor-wrap'>
                                <div className='editor-left'>
                                    <div className='editor-left-in'>
                                        <div className='editor-tab'>
                                            <Tab.Container id="left-tabs-example" defaultActiveKey="images">
                                                <Nav variant="tab-side-nav" className="flex-column editor-tab-left">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="images" className='text-capitalize'><span><MdNumbers /></span> {type}</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="text"><span><TbTextSize /></span> Text</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="background"><span><PiSelectionBackgroundFill /></span> Background</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="media"><span><MdPermMedia /></span> Media</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="elements"><span><BiSolidComponent /></span> Elements</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="border"><span><FaBorderStyle /></span> Borders</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="layer"><span><BsFillLayersFill /></span> Layers</Nav.Link>
                                                    </Nav.Item>
                                                    {image.type === "quotes" || image.type === "memes" ? "" :
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="template"><span><HiTemplate /></span> Templates</Nav.Link>
                                                        </Nav.Item>
                                                    }
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="meta"><span><BsGlobe /></span> Meta</Nav.Link>
                                                    </Nav.Item>
                                                    {/*<Nav.Item>*/}
                                                    {/*    <Nav.Link eventKey="canva"><span><BsPaintBucket /></span> Canva</Nav.Link>*/}
                                                    {/*</Nav.Item>*/}
                                                </Nav>

                                                <Tab.Content>
                                                    <Tab.Pane eventKey="images">
                                                        <NoOfImages
                                                            setPageLoader={setPageLoader}
                                                            onSetInitialCanvas={onSetInitialCanvas}
                                                            canvas={canvas}
                                                            setLoader={setLoader}
                                                            text={text}
                                                        />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="text">
                                                        <ImageText
                                                            canvas={canvas}
                                                            saveJson={saveJson}
                                                        />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="background">
                                                        <Background
                                                            canvas={canvas}
                                                            saveJson={saveJson}
                                                        />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="media">
                                                        <Media
                                                            saveJson={saveJson}
                                                            canvas={canvas}
                                                            canvasJson={canvasJson}
                                                            setCanvasJson={setCanvasJson}
                                                        />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="elements">
                                                        <Elements
                                                            canvas={canvas}
                                                            saveJson={saveJson}
                                                            canvasJson={canvasJson}
                                                            setCanvasJson={setCanvasJson}
                                                        />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="border">
                                                        <div className='text-picker'>
                                                            <Border
                                                                canvas={canvas}
                                                                saveJson={saveJson}
                                                                canvasJson={canvasJson}
                                                                setCanvasJson={setCanvasJson}
                                                            />
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="layer">
                                                        <div className='text-picker'>
                                                            <Layers
                                                                canvas={canvas}
                                                                saveJson={saveJson}
                                                                canvasJson={canvasJson}
                                                                setCanvasJson={setCanvasJson}
                                                            />
                                                        </div>

                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="template">
                                                        <Template
                                                            canvas={canvas}
                                                            onSetInitialCanvas={onSetInitialCanvas}
                                                            fetchDesign={fetchDesign}
                                                            setPageLoader={setPageLoader}
                                                            setCvLoader={setLoader}
                                                            setSelectedImg={setSelectedImg}
                                                        />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="meta">
                                                        <Meta
                                                            canvas={canvas}
                                                            imageData={image.imageData}
                                                            setIsUpdate={setIsUpdate}
                                                            text={text}
                                                            setText={setText}
                                                        />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="canva">
                                                        <Canva
                                                            canvas={canvas}
                                                        />
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Tab.Container>
                                        </div>
                                    </div>
                                </div>
                                <div className='editor-right'>
                                    <div className='editor-area'>
                                        <MyCanvas
                                            undo={onUndo}
                                            redo={onRedo}
                                            canvas={canvas}
                                            canvasState={canvasState}
                                            saveJson={saveJson}
                                            cvLoader={loader}
                                            dimension={image.dimension}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='render-bar'>
                                <button className='site-link bdr me-2' onClick={downloadCanvas}><span>Download</span></button>
                                <button className='site-link' onClick={saveCanvas}><span>{canvasLoader ? <>Saving <i className="fa fa-spinner fa-spin mx-1" /></> : "Save"}</span></button>
                            </div>

                        </div>
                    </div>
                </section>

                <Footer />
            </> :
            <div className="loader-sec">
                <div className="loader" />
            </div>
    )

}

export default ImageEditor;