import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { FiSearch } from 'react-icons/fi'
import { FiCrop } from 'react-icons/fi'
import { useDispatch } from 'react-redux';
import { fetchMedia } from '../../Redux/Actions/CommonActions';
import { useState } from 'react';
import HoverVideoPlayer from "react-hover-video-player";
import { GiCheckMark } from 'react-icons/gi';
import CropModal from './CropModal';

const Library = ({ handleBackground, sType, type, checkMedia, setCheckMedia }) => {
    const dispatch = useDispatch()
    const [images, setImages] = useState([])
    const [error, setError] = useState("")
    const [cropData, setCropData] = useState({
        current: false,
        cropped: false
    })
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const [loader, setLoader] = useState({
        fetch: false,
    })
    const [search, setSearch] = useState({
        key: "",
        page_number: 0,
    })
    const handleChange = (e) => {
        setSearch({
            ...search,
            key: e.target.value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setImages([])
        setError("")
        fetchImages()
    }

    const handleSelectImage = (data) => {
        handleBackground(data, sType)
        setCheckMedia(data.url)
    }
    const fetchImages = (page = 1) => {
        let data = {
            keyword: search.key,
            page_number: page,
            search_type: sType
        }
        setSearch({
            ...search,
            page_number: page
        })
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchMedia("load-library-images", data, images, setImages, loader, setLoader, setError))
    }

    const selectCrop = (e,url) => {
        e.stopPropagation()
        handleShow();
        setCropData({
            ...cropData,
            current: url,
            cropped: false
        })
    }


    return (
        <div className='list-images'>
            <form className='cont-search with-icon mb-2' onSubmit={handleSubmit}>
                <span><FiSearch /></span>
                <input
                    className='cont-inp'
                    type="text"
                    placeholder='Search'
                    onChange={handleChange}
                />
            </form>
            <div>
                <InfiniteScroll
                    dataLength={images.length}
                    hasMore={true}
                    next={() => fetchImages(search.page_number + 1)}
                    scrollableTarget={`bgImagesScroll-${type}-${sType}`}
                    style={{ overflowX: 'hidden' }}
                    className='quote-lists'
                >
                    <ul id={`bgImagesScroll-${type}-${sType}`} style={{ height: 500, overflow: 'auto' }}>
                        {
                            images.length > 0 ?
                                images.map((curElem, index) => {
                                    return (
                                        <li key={index}>
                                            <div className='list-images-single' style={{ cursor: "pointer" , textAlign:"center"}} onClick={() => handleSelectImage(curElem, index)}>
                                                {checkMedia === curElem.url ?
                                                    <div className='select-tile-style' style={{ zIndex: 9999 }}>
                                                        <GiCheckMark />
                                                    </div> : ""}

                                                <div className='img-crop' style={{ zIndex: 9999 }} onClick={(e) => selectCrop(e, curElem.url)}>
                                                    <FiCrop />
                                                </div>
                                                {sType === "images" ?
                                                    <img alt="" src={curElem.thumbnail} />
                                                    :
                                                    <HoverVideoPlayer
                                                        className="hover-video-player"
                                                        style={{ height: '100%' }}
                                                        videoSrc={curElem.url}
                                                        pausedOverlay={
                                                            <img
                                                                src={curElem.thumbnail}
                                                                alt={`${search.keyword + ' ' + index}`}
                                                                style={{ objectFit: "cover", width: "100%", height: "100%" }} />
                                                        }
                                                        loadingOverlay={
                                                            <div style={{ color: "#618fca", display: 'flex', alignItems: "center", justifyContent: "center", height: "100%" }}>
                                                                <i className="fa fa-spinner fa-spin hover-loader-center" />
                                                            </div>
                                                        }
                                                    />
                                                }

                                            </div>
                                        </li>
                                    )
                                })
                                : ""
                        }
                        {
                            error ? <div className='text-center' style={{ height: "100%", width: "100%", fontSize: 15, padding: 5 }}>
                                {error}
                            </div> : ""
                        }
                        {
                            loader.fetch ? <div className='fetch-loader-block' style={{ height: "100%", width: "100%" }}><i className="fa fa-spinner fa-spin fetch-loader" /></div> : ""
                        }

                    </ul>
                </InfiniteScroll>
            </div >

            <CropModal
                show={show}
                handleClose={handleClose}
                type={sType}
                cropData={cropData}
                setCropData={setCropData}
                handleSelectImage={handleSelectImage}
            />
        </div >
    )
}

export default Library