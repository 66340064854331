import React, { useEffect, useState } from 'react';
import { onUpdateTemplate, onVariationChange } from '../../../../../Redux/Actions/VideoActions';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../../../../../Redux/Actions/CommonActions';
import { GiCheckMark } from 'react-icons/gi';

const Variations = ({ setPreLoader }) => {
    const dispatch = useDispatch()
    const campaign = useSelector(state => state.video.campaign)
    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")

    const [loader, setLoader] = useState({ fetch: false })
    const [variation, setVariation] = useState([])


    const handleSelectTemplate = (id) => {
        if (selectedSlide.variationId !== id) {
            let data = {
                slideId: selectedSlide.id,
                newVariationId: id
            }
            dispatch(onVariationChange(data, selectedSlideIndex, setPreLoader))
        }
    }

    const getVariation = (id) => {
        setLoader({
            ...loader,
            fetch: true
        })
        let data = {
            templateId: id
        }
        dispatch(fetchData("fetch-variation", data, setVariation, loader, setLoader))
    }

    useEffect(() => {
        if (campaign.templateId) {
            getVariation(campaign.templateId)
        }
    }, [campaign.templateId])

    return (
        <ul>
            {
                variation.length > 0 ?
                    variation.filter((obj) => {
                        // return campaign.dimension === obj.dimension
                        return campaign.dimension === "1920x1080" || campaign.dimension === "1080x1920" ? campaign.dimension === obj.dimension : obj.dimension === "1920x1080"
                    }).map((curElem, index) => {
                        return (
                            <li key={index}>
                                <div
                                    className='list-images-single'
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleSelectTemplate(curElem.id)}
                                >
                                    <img
                                        alt=""
                                        src={curElem.previewImage}
                                    />
                                    {+selectedSlide.variationId === +curElem.id ?
                                        <div className='select-tile-style'>
                                            <GiCheckMark />
                                        </div>
                                        : ''
                                    }
                                </div>


                            </li>
                        )
                    })
                    :
                    loader.fetch ? <div className='fetch-loader-block' style={{ height: "100%", width: "100%" }}><i className="fa fa-spinner fa-spin fetch-loader" /></div> : ""
            }
        </ul>
    );
}

export default Variations;
