import React, { useRef } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onUpdateLogoInfo } from '../../../../../Redux/Actions/VideoActions'

const Logo = () => {
    const dispatch = useDispatch()
    const campaign = useSelector(state => state.video.campaign)
    const logoRef = useRef(null)

    useEffect(() => {
        if (campaign.logo.src !== "") {
            setTimeout(() => {
                dispatch(onUpdateLogoInfo(logoRef.current.clientHeight, logoRef.current.clientWidth))
            }, 2000)
        }else{
            dispatch(onUpdateLogoInfo(0, 0))
        }
    }, [campaign.logo.src])

    return (
        campaign.logo.src !== "" ?
            <div className={`logo-${campaign.logo.pos} logo-style`}>
                <img
                    ref={logoRef}
                    src={campaign.logo.src}
                    alt="logo"
                />
            </div>
            : ''
    )
}

export default Logo