import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PiUpload } from 'react-icons/pi'
import TitleBar from '../../title-bar/TitleBar';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import { addStream, onFetchEditStream, onUploadStream, updateFileSequence } from '../../../Redux/Actions/StreamActions';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import Swal from 'sweetalert2';
import StreamFileRows from './StreamFileRows';
import AddStreamText from './AddStreamText';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const AddStream = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const id = queryString.parse(location.search).id
    let allowExt = ['image/jpg', 'image/png', 'image/jpeg', 'image/JPEG']

    const [successFile, setSuccessFile] = useState({
        files: []
    });
    const [uploadImageCount, setUploadImageCount] = useState(0);

    const [loader, setLoader] = useState({
        create: false,
        upload: false
    })
    const [stream, setStream] = useState({
        id: id,
        type: "image",
        title: "",
        tags: null,
        description: "",
        stream: []
    })

    const [uploadStatus, setUploadStatus] = useState({
        percentage: 0,
        total_size: 0,
        uploaded_size: 0,
        file_name: null
    });

    const [uploadStream, setUploadStream] = useState({
        title: stream.title,
        description: stream.description,
        sequence: 1,
        content_stream_id: null,
        stream: null
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setStream({
            ...stream,
            [name]: value
        });

    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };


    const onSetRecorder = (result) => {
        if (!result.destination) {
            return;
        }
        const items = reorder(stream.stream, result.source.index, result.destination.index);
        let data = {
            data: items
        }
        dispatch(updateFileSequence(data));
        setStream({
            ...stream,
            stream: items
        })
    }

    const handleChangeMedia = (e) => {
        let files = e.target.files;

        for (let i = 0; i < files.length; i++) {
            let maxUploadSize = 5000000;
            if (files[i].type === 'video/mp4') {
                maxUploadSize = 200000000
            }

            if (allowExt.includes(files[i].type) && stream.type === "image") {
                if (files[i].size < maxUploadSize) {
                    setUploadStream({ ...uploadStream, sequence: uploadStream.sequence + 1 });

                    const formData = new FormData();
                    formData.append('title', '');
                    formData.append('description', '');
                    formData.append('sequence', uploadStream.sequence);
                    formData.append('stream', files[i]);
                    formData.append('content_stream_id', stream.id);
                    dispatch(onUploadStream(formData, uploadStatus, setUploadStatus, successFile, setSuccessFile, setUploadImageCount, files, i, fetchEditStream))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max uploaded size for image 5MB!',
                        confirmButtonColor: "#618fca"
                    })
                }
            } else if (files[i].type === 'video/mp4' && stream.type === "video") {
                if (files[i].size < maxUploadSize) {
                    setUploadStream({ ...uploadStream, sequence: uploadStream.sequence + 1 });

                    const formData = new FormData();
                    formData.append('title', '');
                    formData.append('description', '');
                    formData.append('sequence', uploadStream.sequence);
                    formData.append('stream', files[i]);
                    formData.append('content_stream_id', stream.id);
                    dispatch(onUploadStream(formData, uploadStatus, setUploadStatus, successFile, setSuccessFile, setUploadImageCount, files, i, fetchEditStream))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max uploaded size for video 20MB!',
                        confirmButtonColor: "#618fca"
                    })
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have Selected Invalid File Type!',
                    confirmButtonColor: "#618fca"
                })
            }

        }
    }

    const handleAlert = () => {
        if (id || stream.id) { }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please create content stream before upload files!',
                confirmButtonColor: "#618fca"
            })
        }
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        let data = { ...stream }
        delete data.stream
        setLoader({
            ...loader,
            create: true
        })
        dispatch(addStream(data, stream, setStream, loader, setLoader, navigate))

    }

    const fetchEditStream = () => {
        let data = {
            id: id
        }
        dispatch(onFetchEditStream(data, setStream))
    }

    useEffect(() => {
        if (id) {
            fetchEditStream()
        }
    }, [id])



    return (
        <>
            <TitleBar title="Home" />
            <Header />

            <section className='page-wrap'>
                <div className='container'>
                    <h1 className='page-title'>{id ? "Update" : "Add"} Streams</h1>

                    <div className='cont-box'>
                        <div className='row'>
                            <div className='col-sm-6'>
                                {stream.type === "image" || stream.type === "video" ?
                                    <div className='upload-box upload-box-large h-100'>
                                        {
                                            uploadStatus.percentage > 0 ?
                                                <div className=''>
                                                    <h6 style={{ fontSize: 20 }} className='mb-3'>Uploading {uploadImageCount} Files</h6>
                                                    <h6 style={{ fontSize: 18 }} className='mb-3'>{uploadStatus.file_name}</h6>
                                                    <h6 style={{ fontSize: 17 }}> {uploadStatus.percentage}% uploaded </h6>
                                                </div> :

                                                <>
                                                    <PiUpload />
                                                    <p>{stream.type === "image" ? <>JPG , PNG upto 5MB is allowed</> : <>MP4 upto 200MB allowed</>}</p>
                                                    {stream.id ?
                                                        <>
                                                            <input
                                                                type="file"
                                                                onChange={handleChangeMedia}
                                                                multiple
                                                            />
                                                        </>
                                                        : ""
                                                    }
                                                    <div className='bottom-btn-group'>
                                                        <button className='site-link' style={{ background: id || stream.id ? "" : "gray" }} onClick={handleAlert}><span>Upload</span></button>
                                                    </div>
                                                </>
                                        }
                                    </div> :
                                    stream.type === "text" || stream.type === "formatted" ?
                                        <AddStreamText
                                            id={stream.id}
                                            uploadStream={uploadStream}
                                            fetchData={fetchEditStream}
                                            type={stream.type}
                                            title={stream.title}
                                        />
                                        : ""

                                }

                            </div>
                            <form className='col-sm-6' onSubmit={handleSubmit} >
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className='common-input-wrap mt-0'>
                                            <label htmlFor="">Type</label>
                                            <select
                                                className='common-input'
                                                type="text"
                                                name='type'
                                                value={stream.type}
                                                onChange={handleChange}
                                                disabled={id ? true : false}
                                                required
                                            >
                                                <option value="image">Image</option>
                                                <option value="video">Video</option>
                                                <option value="text">Text</option>
                                                <option value="formatted">Formatted Text</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='common-input-wrap mt-0' style={{ position: 'relative' }}>
                                            <label htmlFor="">Title of Content Stream <span style={{ position: "absolute", right: 2, fontSize: 15 }}>{stream.title.length}/20</span></label>
                                            <input
                                                className='common-input'
                                                type="text"
                                                placeholder="Title of Content Stream"
                                                name='title'
                                                value={stream.title}
                                                maxLength={20}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Tags</label>
                                    <input
                                        className='common-input'
                                        type="text"
                                        name='tags'
                                        placeholder="Enter a tag separated by comma (,)"
                                        value={stream.tags}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Description</label>
                                    <textarea
                                        placeholder="Description of Content Stream"
                                        className='common-input'
                                        name="description"
                                        value={stream.description}
                                        onChange={handleChange}
                                        type="text"
                                        required
                                    />
                                </div>
                                <div className='bottom-btn-group'>
                                    <button className='site-link' type='submit'><span>{loader.create ? <>
                                        {id ? "Updating" : "Creating"}
                                        <i className="fa fa-spinner fa-spin mx-1" /></> : id ? "Update" : "Create"}</span></button>
                                </div>
                            </form>

                        </div>


                    </div>

                    <div className='cont-box-group'>
                        <h5>Files of this Content Stream</h5>
                        <div className='cont-box'>
                            <div className='table-responsive'>
                                <table className='table theme-table'>
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Thumbnail</th>
                                            <th>Title</th>
                                            <th>Description</th>
                                            <th>Created Date</th>
                                            <th className='text-end'>Action</th>
                                        </tr>
                                    </thead>
                                    <DragDropContext onDragEnd={onSetRecorder}>
                                        <Droppable droppableId="droppable">
                                            {(provided) => (
                                                <tbody
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    {stream.stream.length > 0 ?
                                                        stream.stream.map((curElem, index) => {
                                                            let img = curElem.thumbnails
                                                            return (
                                                                <Draggable key={index} draggableId={`id_${index}`} index={index}>
                                                                    {(provided) => (
                                                                        <StreamFileRows
                                                                            provided={provided}
                                                                            id={curElem.id}
                                                                            image={img}
                                                                            index={index}
                                                                            type={stream.type}
                                                                            created={curElem.created}
                                                                            title={curElem.title ? curElem.title : stream.title}
                                                                            description={curElem.description ? curElem.description : stream.description}
                                                                            fetchData={fetchEditStream}
                                                                        />
                                                                    )}
                                                                </Draggable>
                                                            )
                                                        })
                                                        :
                                                        <tr>
                                                            <td colSpan={7} className='fetch-loader-block'>
                                                                {loader.fetch ?
                                                                    <i className="fa fa-spinner fa-spin fetch-loader" />
                                                                    :
                                                                    <span style={{ fontSize: 15 }}>
                                                                        You have not uploaded any stream yet!
                                                                    </span>
                                                                }
                                                            </td>
                                                        </tr>
                                                    }

                                                </tbody>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <Footer />
        </>
    )

}

export default AddStream;