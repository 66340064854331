import React, { useEffect, useState } from 'react';
import TitleBar from '../../title-bar/TitleBar';
import Header from '../../header/Header';
import { Search } from 'react-iconly';
import { Link } from 'react-router-dom';
import Footer from '../../footer/Footer';
import { fetchData, onDeleteCampaign } from '../../../Redux/Actions/CommonActions';
import { useDispatch } from 'react-redux';
import { AiOutlineDelete } from 'react-icons/ai';
import { FiEye } from 'react-icons/fi';
import redditImage from "../../../assets/images/reddit_new.png";
import telegramImage from "../../../assets/images/telegram_new.png";
import vimeoImage from "../../../assets/images/vimeo_new.png";
import wordpressLogo from "../../../assets/images/wordpress-logo.png";
import yt from "../../../assets/images/youtube.png";
import mediumImage from "../../../assets/images/medium-logo.png";
import linkdin from "../../../assets/images/LinkedIn.png";
import instagram from "../../../assets/images/Instagram.png";
import fb from "../../../assets/images/facebook-1.png";
import SweetAlert from '../../CommonComponent/SweetAlert';
import EditTimeModal from './EditTimeModal';

const ScheduleTable = () => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        fetch: false
    })
    const [q, setQ] = useState({
        search: "",
        type: "all"
    })
    const [schedulesList, setSchedulesList] = useState([])
    const [datetimeToEdit, setDatetimeToEdit] = useState(false)
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
    })

    const handleDelete = (id) => {
        setSweet({
            ...sweet,
            enable: true,
            id: id
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete"
        })
    }

    const handleFilter = (e) => {
        const { name, value } = e.target
        setQ({
            ...q,
            [name]: value
        })
    }
    const performDelete = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting..."
        })
        dispatch(onDeleteCampaign("delete-scheduler", data, fetchAllSchedules, setSweet))
    }


    const onEditTime = (curElem) => {
        handleShow()
        setDatetimeToEdit(curElem)
    }

    const fetchAllSchedules = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchData("fetch-schedulers", {}, setSchedulesList, loader, setLoader))
    }
    useEffect(() => {
        fetchAllSchedules()
    }, [])
    return (
        <>
            <TitleBar title="Schedule Content" />
            <Header />

            <section className='page-wrap'>
                <div className='container'>
                    <h1 className='page-title'>Schedule Content</h1>
                    <div className='content-search'>
                        <div className='row'>
                            <div className='col-xl-8'>
                                <div className='input-wrap with-icon'>
                                    <span className='inp-icon'><Search /></span>
                                    <input
                                        className='inp'
                                        type="text"
                                        placeholder='Search'
                                        value={q.search}
                                        name='search'
                                        onChange={handleFilter}
                                    />
                                </div>
                            </div>
                            <div className='col-xl-2'>
                                <div className='input-wrap'>
                                    <select
                                        className='inp'
                                        name="type"
                                        value={q.type}
                                        onChange={handleFilter}
                                    >
                                        <option value="all">All</option>
                                        <option value="image">Image</option>
                                        <option value="video">Video</option>
                                        <option value="text">Text</option>
                                        <option value="formatted">Formatted Text</option>
                                    </select>
                                </div>
                            </div>
                            <div className='col-xl-2'>
                                <Link className='site-link full bdr' to="/schedule"><span>+ Schedule</span></Link>
                            </div>
                        </div>
                    </div>

                    <div className='cont-box-group'>
                        {/* <h5>Business Results</h5> */}
                        <div className='cont-box'>
                            <div className='table-responsive'>
                                <table className='table theme-table'>
                                    <thead className='text-center'>
                                        <tr>
                                            <th>No.</th>
                                            <th>Client</th>
                                            <th>Stream</th>
                                            <th>Network</th>
                                            <th>Content Posted</th>
                                            <th>Schedule</th>
                                            <th>Timezone</th>
                                            <th>Frequency</th>
                                            <th>Repurpose</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-center'>
                                        {schedulesList.length > 0 ?
                                            schedulesList.filter((curElem) => {
                                                return (curElem.contentStream.title?.toLowerCase().includes(q.search.toLowerCase()) && (curElem.contentStream.type === q.type || "all" === q.type))
                                            }).map((curElem, index) => {
                                                let streamImg = ""
                                                if (curElem.contentStream.streams.length > 0) {
                                                    streamImg = curElem.contentStream.streams[0].thumbnails
                                                }
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            <span className='thumb-image'>
                                                                <img alt='' src={curElem.client.profile} />
                                                            </span>
                                                            <p>{curElem.client.name}</p>
                                                        </td>
                                                        <td>
                                                            <span className='thumb-image'>
                                                                <img alt='' src={streamImg} />
                                                            </span>
                                                            <p>{curElem.contentStream.title}</p>
                                                        </td>
                                                        <td>
                                                            {curElem.accounts.length > 0 ?
                                                                curElem.accounts.map((acc, index) => {
                                                                    if (acc.length > 0) {
                                                                        let image = acc[0].type === "youtube" ? yt :
                                                                            acc[0].type === "facebook" ? fb :
                                                                                acc[0].type === "reddit" ? redditImage :
                                                                                    acc[0].type === "telegram" ? telegramImage :
                                                                                        acc[0].type === "vimeo" ? vimeoImage :
                                                                                            acc[0].type === "wordpress" ? wordpressLogo :
                                                                                                acc[0].type === "medium" ? mediumImage :
                                                                                                    acc[0].type === "linkedin" ? linkdin :
                                                                                                        acc[0].type === "instagram" ? instagram :
                                                                                                            ""
                                                                        return (
                                                                            <span>
                                                                                <span className='thumb-image ms-1' key={index} style={{ height: 50, width: 50, padding: 5 }}>
                                                                                    <img alt='' src={image} />
                                                                                </span>
                                                                                <p style={{ fontSize: 10 }}>{acc[0].username}</p>

                                                                            </span>

                                                                        )
                                                                    } else {
                                                                        return null
                                                                    }
                                                                })
                                                                : ""}

                                                        </td>
                                                        <td> {curElem.posted_count ? curElem.posted_count : 0} /{JSON.parse(curElem.streams).length}</td>
                                                        <td>
                                                            {curElem.scheduleDate.split(" ")[0]}{" "}
                                                            {curElem.hours >= 9 ? curElem.hours : '0' + curElem.hours}:{curElem.minutes >= 9 ? curElem.minutes : '0' + curElem.minutes}
                                                            <span
                                                                onClick={(e) => onEditTime(curElem)}
                                                                className='mx-1 '
                                                                style={{ color: "#618fca", cursor: "pointer" }}
                                                            >
                                                                <i className="fas fa-edit" data-for="mainTooltip" data-iscapture="true" data-tip="Reschedule my Post"></i>
                                                            </span>
                                                        </td>
                                                        <td>{curElem.timezone}</td>

                                                        <td className='text-capitalize'>{curElem.type}</td>
                                                        <td >{+curElem.rePurpose === 1 ? 'Yes' : 'No'}</td>
                                                        <td >
                                                            <Link className='smBtn edit' to={`/schedule/schedule-details?id=${curElem.id}`}><FiEye /></Link>
                                                            <span className='smBtn'
                                                                onClick={() => handleDelete(curElem.id)}
                                                            ><AiOutlineDelete /></span>
                                                        </td>
                                                    </tr>
                                                )
                                            })

                                            :
                                            <tr>
                                                <td colSpan={10} className='fetch-loader-block'>
                                                    {loader.fetch ?
                                                        <i className="fa fa-spinner fa-spin fetch-loader" />
                                                        :
                                                        <span style={{ fontSize: 15 }}>
                                                            You have not created any content stream yet. Click on 'Schedule' to create one!
                                                        </span>
                                                    }
                                                </td>
                                            </tr>

                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <EditTimeModal
                show={show}
                handleClose={handleClose}
                datetimeToEdit={datetimeToEdit}
                fetchData={fetchAllSchedules}
            />

            <SweetAlert
                show={sweet.enable}
                message={"Are you sure you want to delete it?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
            />

            <Footer />
        </>
    );
}

export default ScheduleTable;
