import React, { useEffect, useState } from 'react';
import TitleBar from '../../title-bar/TitleBar';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import Select from "react-select"
import { onSearchGoogleLocation, onSearchGoogleSocial, onSearchYoutubeSocial } from '../../../Redux/Actions/TrendAction';
import RedditCard from './RedditCard';
import YoutubeCard from './YoutubeCard';
import GoogleTrend from './GoogleTrend';


function Trends() {

    const dispatch = useDispatch();

    const [trendState, setTrendState] = useState({
        social: 'reddit',
        connection: null,
        type: 'relevance',
        keyword: null,
        location: null
    });

    const [youtubeLoader, setYoutubeLoader] = useState(false);
    const [redditResponse, setRedditResponse] = useState([]);
    const [youtubeResponse, setYoutubeResponse] = useState([]);
    const [googleResponse, setGoogleResponse] = useState([]);
    const [googleLocation, setGoogleLocations] = useState([]);
    const [loader, setLoader] = useState(false);
    const [googleLoc, setGoogleLoc] = useState('');


    const [redditToken, setRedditToken] = useState('');
    const [redditLoad, setRedditLoad] = useState(false);

    const [youtubeLoad, setYoutubeLoad] = useState(false);
    const [youtubeToken, setYoutubeToken] = useState('');

    const [commonLic, setCommonLic] = useState(false);
    const [timezone, setTimezone] = useState('-60');


    const onInputChange = (e) => {
        setTrendState({ ...trendState, [e.target.name]: e.target.value });

        if (e.target.name === "keyword") {
            setYoutubeResponse([]);
            setRedditResponse([]);
        }
    }

    const onLocationChange = (e) => {
        setGoogleLoc(e);
        setTrendState({ ...trendState, location: e.value });
    }

    const onCheckCommon = (e) => {
        if (commonLic === true) {
            setCommonLic(false);
        } else {
            setCommonLic(true);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
        setGoogleResponse([])
        setRedditResponse([])
        setYoutubeResponse([])
        if (trendState.social === 'reddit') {
            searchRedditSocial();
        }
        else if (trendState.social === 'youtube') {
            searchYoutubeSocial()
        }
        else if (trendState.social === 'google') {
            searchGoogleSocial();
        }
    }

    const searchGoogleSocial = async () => {
        if (trendState.location) {
            let data = {
                "country": trendState.location,
                "timezone": timezone
            }

            dispatch(onSearchGoogleSocial(data, setGoogleResponse, setLoader))
        } else {
            setLoader(false);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select location first',
                confirmButtonColor: "#618FCA"
            })
        }
    }

    const searchRedditSocial = async () => {
        let response = await fetch(`https://www.reddit.com/search.json?q=${trendState.keyword}&sort=${trendState.type}&show=all`);

        let jsonData = await response.json();
        setRedditToken(jsonData.data.after);
        setRedditResponse(jsonData.data.children);
        setLoader(false);
    }

    const loadMoreReddit = async () => {
        setRedditLoad(true);
        let response = await fetch(`https://www.reddit.com/search.json?q=${trendState.keyword}&sort=${trendState.type}&show=all&after=${redditToken}`);

        let jsonData = await response.json();
        setRedditToken(jsonData.data.after);

        let arr = [...redditResponse];
        setRedditResponse(arr.concat(jsonData.data.children));
        setRedditLoad(false);
    }

    // const downloadYoutube = async (id) => {
    //     setYoutubeLoader(true);
    //     let options = {
    //         headers: {
    //             'Authorization': auth.token
    //         }
    //     }
    //     await axios.get(`${baseURL}download-youtube-video?url=https://www.youtube.com/watch?v=${id}`, options).then(res => {
    //         if (res.data.status === true) {
    //             console.log(res.data);
    //             window.location = 'https://backend.agencyreel.io/download-file?url=' + res.data.url;
    //         } else {
    //             dispatch(setAlert(res.data.message, 'danger'));
    //         }
    //         setYoutubeLoader(false);
    //     })
    // }

    const searchYoutubeSocial = async () => {
        setYoutubeLoad(true);
        let data = {
            keyword: trendState.keyword,
            no_of_videos: 10,
            next_page_token: youtubeToken,
            license: commonLic
        }

        dispatch(onSearchYoutubeSocial(data, setYoutubeToken, youtubeResponse, setYoutubeResponse, setYoutubeLoad, setLoader))
    }

    const sortYoutubeLike = () => {
        let sortData = [...youtubeResponse].sort((a, b) => b.stats.like - a.stats.like)

        setYoutubeResponse(sortData);
    }

    const sortYoutubeView = () => {
        let sortData = [...youtubeResponse].sort((a, b) => b.stats.views - a.stats.views)

        setYoutubeResponse(sortData);
    }

    const sortYoutubeComments = () => {
        let sortData = [...youtubeResponse].sort((a, b) => b.stats.comments - a.stats.comments)

        setYoutubeResponse(sortData);
    }

    const sortYoutubeDislike = () => {
        let sortData = [...youtubeResponse].sort((a, b) => b.stats.dislike - a.stats.dislike)

        setYoutubeResponse(sortData);
    }

    const searchGoogleLocation = () => {
        let data = {}
        dispatch(onSearchGoogleLocation(data, setGoogleLocations))
    }

    useEffect(() => {
        searchGoogleLocation();
    }, []);

    return (
        <>
            <TitleBar title="Home" />
            <Header></Header>

            <section className='page-wrap'>
                <div className='container'>
                    <h1 className='page-title'>Trends</h1>

                    <div className='cont-box'>
                        <form action="" onSubmit={(e) => handleSubmit(e)}>
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <div className='common-input-wrap mt-0'>
                                        <label htmlFor="">Select Social Media Platform</label>

                                        <select className="common-input" id="exampleFormControlSelect1"
                                            name="social"
                                            onChange={(e) => onInputChange(e)}>
                                            <option selected value="reddit">Reddit</option>
                                            <option value="youtube">Youtube</option>
                                            <option value="google">Google</option>
                                        </select>
                                    </div>
                                </div>
                                {trendState.social === 'reddit' ?
                                    <div className='col-sm-6'>
                                        <div className='common-input-wrap mt-0'>
                                            <label htmlFor="">Relevance</label>
                                            <select className="common-input"
                                                name="type" onChange={(e) => onInputChange(e)}
                                                required={true}
                                            >
                                                <option value="relevance">Relevance</option>
                                                <option value="hot">Hot</option>
                                                <option value="top">Top</option>
                                                <option value="new">New</option>
                                            </select>
                                        </div>
                                    </div>
                                    : ""
                                }

                                {
                                    trendState.social === 'google' ?
                                        <div className='col-sm-6'>
                                            <div className='common-input-wrap mt-0'>
                                                <label htmlFor="">Enter Location</label>
                                                <Select
                                                    options={googleLocation}
                                                    value={googleLoc}
                                                    onChange={(value) => onLocationChange(value)}
                                                />
                                            </div>
                                        </div>
                                        : ''
                                }

                                {
                                    trendState.social === "reddit" || trendState.social === "youtube" ?
                                        <div className='col-sm-12'>
                                            <div className='common-input-wrap'>
                                                <label htmlFor="">Enter Keywords</label>
                                                <input className='common-input'
                                                    name="keyword"
                                                    pattern="[a-z A-Z 0-9]*"
                                                    title="Input String Value"
                                                    type="text"
                                                    placeholder="Keyword"
                                                    onChange={(e) => onInputChange(e)}
                                                    required />
                                            </div>
                                        </div>
                                        : ''
                                }

                                {
                                    trendState.social === "youtube" ?
                                        <div className="col-lg-3 col-md-6  d-flex align-items-center">
                                            <div className="form-check form-switch mt-3 p-0">
                                                <label style={{ display: "block" }}>Creative Common License</label>
                                                <input className="form-check-input" name="send" type="checkbox" value="1" checked={commonLic} onChange={(e) => onCheckCommon(e)} style={{ marginLeft: "6px", marginTop: "6px" }} />
                                            </div>
                                        </div>
                                        : ""
                                }

                            </div>
                            <div className='bottom-btn-group'>
                                <button type='submit' className='site-link ms-0'><span>{loader ? <>Searching <i className="fa fa-spinner fa-spin mx-1" /></> : "Search"}</span></button>
                            </div>
                        </form>
                    </div>

                    <div className='trending-details'>
                        <h5>Trending Results</h5>
                        <div className='row'>
                            {redditResponse.length > 0 ?
                                redditResponse.map((curElem, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <RedditCard
                                                curElem={curElem}
                                            />
                                        </React.Fragment>

                                    )
                                })
                                : ""
                            }

                            {
                                trendState.social === 'reddit' ?
                                    redditResponse.length > 0 ?
                                        redditToken !== '' ?
                                            <button
                                                disabled={redditLoad}
                                                className='site-link text-center d-flex justify-content-center'
                                                onClick={(e) => loadMoreReddit()}
                                            >
                                                <span>{redditLoad ? <>Loading <i className="fa fa-spinner fa-spin mx-1" /> </> : "Load More"}</span>
                                            </button>
                                            : ''
                                        : ''
                                    : ''
                            }


                            {
                                // youtube
                                trendState.social === "youtube" ?
                                    youtubeResponse.length > 0 ?
                                        <div className="trands-table-box">
                                            <table className="table table-responsive">
                                                <thead className="theadbgcolor">
                                                    <tr style={{ fontSize: "13px" }}>
                                                        <th scope="col" style={{ fontWeight: "400" }} >Youtube Videos</th>
                                                        <th scope="col" style={{ width: "50px", fontWeight: "400", cursor: "pointer" }}
                                                            onClick={(e) => sortYoutubeView()} title="Most Views">View <i className="fa fa-sort"></i></th>
                                                        <th scope="col" style={{ width: "50px", fontWeight: "400", cursor: "pointer" }}
                                                            onClick={(e) => sortYoutubeLike()} title="Most Likes">Like <i className="fa fa-sort"></i></th>
                                                        <th scope="col" style={{ width: "50px", fontWeight: "400", cursor: "pointer" }}
                                                            onClick={(e) => sortYoutubeComments()} title="Most Comments">Comments <i className="fa fa-sort"></i></th>
                                                        <th scope="col" style={{ width: "50px", fontWeight: "400", cursor: "pointer" }}
                                                            onClick={(e) => sortYoutubeDislike()} title="Most Dislikes">Dislike <i className="fa fa-sort"></i></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        youtubeResponse.length > 0 ?
                                                            youtubeResponse.map((curElem, index) => {
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <YoutubeCard
                                                                            curElem={curElem}
                                                                        />
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                            : ''
                                                    }
                                                </tbody>
                                            </table>
                                            {
                                                trendState.social === 'youtube' ?
                                                    youtubeResponse.length > 0 ?
                                                        youtubeToken !== '' ?
                                                            <button style={{ margin: "auto" }}
                                                                disabled={youtubeLoad}
                                                                onClick={(e) => searchYoutubeSocial()}
                                                                className='site-link text-center d-flex justify-content-center'
                                                            >
                                                                <span>{youtubeLoad ? <>Loading <i className="fa fa-spinner fa-spin mx-1" /> </> : "Load More"}</span>
                                                            </button>
                                                            : ''
                                                        : ''
                                                    : ''
                                            }
                                        </div>
                                        : ''
                                    : ''
                            }


                            {
                                trendState.social === 'google' ?
                                    googleResponse.length > 0 ?
                                        <section className="py-5">
                                            <div className="container">
                                                <div className="row">
                                                    {
                                                        googleResponse.map((curElem, index) => {
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    <GoogleTrend curElem={curElem} />
                                                                </React.Fragment>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </section>
                                        : ''
                                    : ''
                            }
                        </div>
                    </div>

                    {youtubeResponse.length === 0 && googleResponse.length === 0 && redditResponse.length === 0 ?
                        < div className='fetch-loader-block mt-5'>
                            <span style={{ fontSize: 20 }}>
                                No Data Found
                            </span>
                        </div>
                        : ""
                    }

                </div>
            </section >

            <Footer></Footer>
        </>
    )

}

export default Trends;