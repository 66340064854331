import React from 'react'
import IntroOutro from './IntroOutro'
import { useSelector } from 'react-redux'

const Credits = () => {
  const campaign = useSelector(state => state.video.campaign)
  const intro = campaign.intro
  const outro = campaign.outro

  return (
    <>
      <IntroOutro
        data={intro}
        type="intro"
      />
      <hr />
      <IntroOutro
        data={outro}
        type="outro"
      />
    </>
  )
}

export default Credits