import React from 'react';

function Footer() {

  return (
    <>
      <footer className='section site-footer'>
        <div className='container'>
          <div className='site-footer-in'>

            <div className='footer-nav'>
              <ul>
                <li><a href="https://reelapps.io/terms.html">Terms & Conditions</a></li>
                <li><a href="https://reelapps.io/privacy.html">Privacy Policy</a></li>
                <li><a href="http://support.vineasx.com/">Support</a></li>
              </ul>
            </div>

            <div className="footer-single">
              <p>© {(new Date().getFullYear())}, All Rights Reserved to <a href="https://vineasx.com/" target="_blank" rel="noreferrer">VineaSX Solutions LLC.</a> Developed and Maintained by <a href="http://www.vega6.com/" target="_blank" rel="noreferrer">Vega6</a></p>
              <p>You are interacting with AI system. Content Safety & Content Moderation Limits Applied.</p>
            </div>


            <div className='footer-logo'><a href='https://reelapps.io/' target='_blank'> <img alt='' src={require('../../assets/images/ReelApps.png')} /></a></div>

          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer;