import React, { useEffect, useState } from 'react';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import TitleBar from '../title-bar/TitleBar';
import { useDispatch } from 'react-redux';
import { fetchData } from '../../Redux/Actions/CommonActions';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import VideoCard from './VideoCard';
import ImageCard from './ImageCard';
import { Search } from 'react-iconly';
import { onFetchStreamList } from '../../Redux/Actions/MyContentAction';

const Content = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const type = queryString.parse(location.search).type
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(false)
    const [q, setQ] = useState("")
    const [contentStream, setContentStream] = useState([]);

    const onFetchData = () => {
        let url
        if (type === "videos") {
            url = "fetch-video-campaign"
        } else if (type === "images") {
            url = "fetch-image-campaign"
        } else if (type === "quotes") {
            url = "fetch-quotes-campaign"
        } else if (type === "memes") {
            url = "fetch-memes-campaign"
        } else if (type === "text") {
            url = "fetch-text-campaign"
        }
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchData(url, {}, setData, loader, setLoader))
    }

    useEffect(() => {
        if (type) {
            onFetchData()
        }
    }, [type])

    const fetchList = () => {
        let data = {}
        dispatch(onFetchStreamList(data, setContentStream))
    }

    useEffect(() => {
        fetchList()
    }, [])


    return (
        <>
            <TitleBar title="Content" />
            <Header />

            <section className='page-wrap'>

                <div className='container'>
                    <h1 className='page-title text-capitalize'>{type}</h1>

                    <div className='content-search'>
                        <div className='row'>
                            <div className='col-xl-10'>
                                <div className='input-wrap with-icon'>
                                    <span className='inp-icon'><Search /></span>
                                    <input
                                        className='inp'
                                        type="text"
                                        placeholder='Search'
                                        value={q}
                                        onChange={(e) => setQ(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='col-xl-2'>
                                <div className='input-wrap'>
                                    <select className='inp' name="" id="">
                                        <option value="">All</option>
                                        {/* <option value="">Date</option> */}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className='recent-video mt-0'>
                        <div className='video-listing'>
                            <div className='row'>
                                {data.length > 0 ?
                                    data.filter((curElem) => {
                                        return curElem.name.toLowerCase().includes(q.toLowerCase())
                                    }).map((curElem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                {type === "videos" ?
                                                    <VideoCard
                                                        curElem={curElem}
                                                        fetchVideos={onFetchData}
                                                        contentStream={contentStream}
                                                    /> :
                                                    type === "images" || type === "quotes" || type === "memes" || type === "text" ?
                                                        <ImageCard
                                                            curElem={curElem}
                                                            fetchImages={onFetchData}
                                                            type={"image"}
                                                            contentStream={contentStream}
                                                        /> : ""
                                                }
                                            </React.Fragment>
                                        )
                                    })
                                    :
                                    <div className='fetch-loader-block'>
                                        {loader.fetch ?
                                            <i className="fa fa-spinner fa-spin fetch-loader" />
                                            : ""
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </section>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default Content;
