import React, { useState } from 'react';
import { PiUpload } from 'react-icons/pi';
import { useDispatch } from 'react-redux';
import { onUploadMedia } from '../../../../../Redux/Actions/CommonActions';
import Swal from 'sweetalert2';
import { removeLogoWatermark, updateLogoWatermark, updateStatus } from '../../../../../Redux/Actions/VideoActions';
import dummyImage from "../../../../../assets/images/dummyImage.jpg"
import { useEffect } from 'react';

const LogoWatermark = (props) => {
    
    const dispatch = useDispatch()
    const [percent, setPercent] = useState(0)
    const [loader, setLoader] = useState({
        upload: false
    })
    const [lw, setLw] = useState("")

    const selectImage = (url, lwType) => {
        let uploadType = 'ADD_LOGO';
        if (lwType === "watermark") {
            uploadType = 'ADD_WATERMARK';
        }
        setLw(url)
        dispatch(updateLogoWatermark(uploadType, url));
    }

    const handleLogoWaterMark = (e) => {
        dispatch(updateStatus(e.target.checked, props.type))
        if (e.target.checked === false) {
            if (props.type === "logo") {
                dispatch(removeLogoWatermark('REMOVE_LOGO_LAYER'))
            } else {
                dispatch(removeLogoWatermark('REMOVE_WATERMARK_LAYER'))
            }
        }
    }
    const logoWaterPos = (position) => {
        let fileType = 'UPDATE_LOGO_POSITION';
        if (props.type === 'watermark') {
            fileType = 'UPDATE_WATERMARK_POSITION';
        }
        dispatch(updateLogoWatermark(fileType, position));
    }

    const onLoadFile = (file) => {
        if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg') {
            if (file.size < 5000000) {
                let formData = new FormData();
                formData.append('file', file);
                formData.append('upload_type', props.type);
                setLoader(true)
                dispatch(onUploadMedia(formData, selectImage, loader, setLoader, setPercent, props.type))

            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Max allowed size for images is 5MB!',
                    confirmButtonColor: "#618FCA"
                })
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You have Selected Invalid File Type!',
                confirmButtonColor: "#618FCA"
            })
        }
    }

    useEffect(() => {
        if (props.type === "logo") {
            setLw(props.data.src)
        }
        if (props.type === "watermark") {
            setLw(props.data.src)
        }
    }, [props])

    return (
        <>
            <div className='inp-wrap d-flex justify-content-between mt-2'>
                <label htmlFor='logo' className='text-capitalize'>{props.type}</label>
                <label className="switch">
                    <input
                        className='input solid'
                        name={props.type}
                        type="checkbox"
                        id={props.type}
                        checked={props.data.enable}
                        onChange={(e) => handleLogoWaterMark(e)}
                    />
                    <span className="sliderN round"></span>
                </label>

            </div>
            {props.data.enable ?
                <>
                    <div className='upload-box'>
                        <img style={{ height: 100, width: 100, position: "absolute", left: 5, objectFit: "contain" , borderRadius:10}} src={lw ? lw : dummyImage} alt='' />
                        <div style={{ position: 'absolute', right: 10 }}>
                            {loader.upload ?
                                <div className='percent-loader-box'>
                                    <span className='loader-percent'>{percent}%</span>
                                </div> :
                                <>
                                    <PiUpload />
                                    <p className='text-capitalize'>Upload {props.type}</p>
                                    <input
                                        type="file"
                                        onChange={(e) => onLoadFile(e.target.files[0])}
                                    />
                                </>}
                        </div>
                    </div>
                    <div className='inp-wrap mt-2'>
                        <label className='text-capitalize'> Positions</label>
                        <select
                            className='input solid'
                            value={props.data.pos}
                            onChange={(e) => logoWaterPos(e.target.value, 'logo')}
                        >
                            <option value={'top-left'}>Top Left</option>
                            <option value={'top-center'}>Top Center</option>
                            <option value={'top-right'}>Top Right</option>
                            <option value={'bottom-left'}>Bottom Left</option>
                            <option value={'bottom-center'}>Bottom Center</option>
                            <option value={'bottom-right'}>Bottom Right</option>
                        </select>
                    </div>
                </> : ""}
        </>
    );
}

export default LogoWatermark;
