import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { addSchedulerContent } from '../../../Redux/Actions/ScheduleActions';
import { useDispatch } from 'react-redux';
import { GiCheckMark } from 'react-icons/gi';
import TimezoneSelect from 'react-timezone-select';
import redditImage from "../../../assets/images/reddit_new.png";
import telegramImage from "../../../assets/images/telegram_new.png";
import vimeoImage from "../../../assets/images/vimeo_new.png";
import wordpressLogo from "../../../assets/images/wordpress-logo.png";
import instagram from "../../../assets/images/Instagram.png";
import twitter from "../../../assets/images/twitter-x.png";
import yt from "../../../assets/images/youtube.png";
import mediumImage from "../../../assets/images/medium-logo.png";
import linkedin from "../../../assets/images/LinkedIn.png";
import fb from "../../../assets/images/facebook-1.png";
import { useImmer } from 'use-immer';
import ModalStream from './ModalStream';

const ScheduleModal = (props) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [social, setSocial] = useState([])
    const auth = useSelector(state => state.auth)
    const [scheduleStream, setScheduleStream] = useImmer({
        user_id: auth.user.id,
        user_client_id: null,
        content_stream_id: null,
        social_accounts: [],
        schedule_date: "",
        schedule_time: "",
        hours: "01",
        minutes: "00",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        re_purpose: 0,
        schedule_type: ""
    });

    const [active, setActive] = useState({
        first: true,
        second: false,
        third: false,
        forth: false
    })

    const handleChange = (name, value) => {
        let valData = value
        if (name === "user_client_id") {
            valData = value.id
            setSocial(value.socialAccounts)
        }

        if (name === "socialAccounts") {
            if (scheduleStream.social_accounts.includes(valData)) {
                const index = scheduleStream.social_accounts.indexOf(valData)
                if (index !== -1) {
                    setScheduleStream((draft) => {
                        draft.social_accounts.splice(index, 1)
                    })
                }
            }
            else {
                setScheduleStream((draft) => {
                    draft.social_accounts.push(valData)
                })
            }

        } else {
            setScheduleStream((draft) => {
                draft[name] = valData
            })
        }
    }


    const handleTab = (type) => {
        if (type === 'f') {
            setActive({
                ...active,
                first: true,
                second: false,
                third: false,
                forth: false
            })
        } else if (type === 's') {
            if (scheduleStream.user_client_id) {
                setActive({
                    ...active,
                    first: false,
                    second: true,
                    third: false,
                    forth: false
                })
            }
        }
        else if (type === 't') {
            if (scheduleStream.content_stream_id) {
                setActive({
                    ...active,
                    first: false,
                    second: false,
                    third: true,
                    forth: false
                })
            }
        }
        else if (type === 'fo') {
            if (scheduleStream.social_accounts.length > 0) {
                setActive({
                    ...active,
                    first: false,
                    second: false,
                    third: false,
                    forth: true
                })
            }
        }
    }

    const handleBack = () => {
        if (active.second) {
            setActive({
                ...active,
                first: true,
                second: false,
                third: false,
                forth: false
            })
        } else if (active.third) {
            setActive({
                ...active,
                first: false,
                second: true,
                third: false,
                forth: false
            })
        } else if (active.forth) {
            setActive({
                ...active,
                first: false,
                second: false,
                third: true,
                forth: false
            })
        }
    }

    const handleSubmit = () => {
        if (active.forth) {
            let data = { ...scheduleStream }
            setLoader(true)
            dispatch(addSchedulerContent(data, props.fetchCalender, props.handleClose, setLoader, setActive, setScheduleStream, auth))
        } else {
            if (active.first && scheduleStream.user_client_id) {
                setActive({
                    ...active,
                    first: false,
                    second: true,
                    third: false,
                    forth: false
                })
            } else if (active.second && scheduleStream.content_stream_id) {
                setActive({
                    ...active,
                    first: false,
                    second: false,
                    third: true,
                    forth: false
                })
            } else if (active.third && scheduleStream.social_accounts.length > 0) {
                setActive({
                    ...active,
                    first: false,
                    second: false,
                    third: false,
                    forth: true
                })
            }
        }
    }


    useEffect(() => {
        setScheduleStream({
            ...scheduleStream,
            schedule_date: props.choosenCalender.date,
            schedule_time: props.choosenCalender.time,
            schedule_type: props.choosenCalender.schedule_type
        })
    }, [props.choosenCalender])

    return (
        <Modal className='theme-modal xl schedule_popup' show={props.show} onHide={props.handleClose} centered scrollable>
            <Modal.Body>
                <div className='modal-tab'>
                    <h3 className='pb-4'>Schedule your content stream</h3>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="tab-1">
                        <Nav variant="">
                            <Nav.Item
                                onClick={() => handleTab("f")}
                            >
                                <Nav.Link className={active.first ? "active" : ''}>Client</Nav.Link>
                            </Nav.Item>
                            <Nav.Item
                                onClick={() => handleTab("s")}
                            >
                                <Nav.Link className={active.second ? "active" : ''}>Stream</Nav.Link>
                            </Nav.Item>
                            <Nav.Item
                                onClick={() => handleTab("t")}
                            >
                                <Nav.Link className={active.third ? "active" : ''}>Account</Nav.Link>
                            </Nav.Item>
                            <Nav.Item
                                onClick={() => handleTab("fo")}
                            >
                                <Nav.Link className={active.forth ? "active" : ''}>Schedule</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content className='pt-3 schedule_modal'>
                            <Tab.Pane className={active.first ? 'show active' : ""}>
                                <div className='tab-client'>
                                    <div className='row'>
                                        {props.clients.length > 0 ?
                                            props.clients.map((curElem, index) => {
                                                return (
                                                    <div className='col-sm-6 col-lg-4' key={index} >
                                                        <div
                                                            className='tab-client-single cursor-pointer'
                                                            onClick={() => handleChange("user_client_id", curElem)}
                                                            style={{ position: 'relative', cursor: "pointer" }}
                                                        >
                                                            <div className='tab-client-img'><img alt='' src={curElem.profile} /></div>
                                                            <h6>{curElem.name}</h6>
                                                            {+scheduleStream.user_client_id === +curElem.id ?
                                                                <div className='select-tile-style' style={{ zIndex: 9999 }}>
                                                                    <GiCheckMark />
                                                                </div> : ""}
                                                        </div>

                                                    </div>
                                                )
                                            })
                                            :
                                            <div className='text-center mt-3'>
                                                You do not have any client yet! <Link to="/clients/add-client">Click Here</Link> to create!
                                            </div>
                                        }

                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane className={active.second ? 'show active' : ""}>
                                <ModalStream
                                    handleChange={handleChange}
                                    scheduleStream={scheduleStream}
                                    stream={props.stream}
                                    setScheduleStream={setScheduleStream}
                                />
                            </Tab.Pane>
                            <Tab.Pane className={active.third ? 'show active' : ""}>
                                <div className='streame-list'>
                                    <ul>
                                        {social.length > 0 ?
                                            social.map((curElem, index) => {
                                                console.log(curElem)
                                                let image = curElem.type === "youtube" ? yt :
                                                    curElem.type === "facebook" ? fb :
                                                        curElem.type === "reddit" ? redditImage :
                                                            curElem.type === "telegram" ? telegramImage :
                                                                curElem.type === "vimeo" ? vimeoImage :
                                                                    curElem.type === "wordpress" ? wordpressLogo :
                                                                        curElem.type === "medium" ? mediumImage :
                                                                            curElem.type === "linkedin" ? linkedin :
                                                                                curElem.type === "instagram" ? instagram :
                                                                                    curElem.type === "twitter" ? twitter :
                                                                                        ""
                                                return (
                                                    <li key={index} className='text-center'>
                                                        <div
                                                            className='streame-list-single'
                                                            onClick={() => handleChange("socialAccounts", curElem.id)}
                                                            style={{ position: 'relative', cursor: "pointer" }}
                                                        >
                                                            <div className='streame-list-img'><img alt='' src={image} /></div>
                                                            {scheduleStream.social_accounts.includes(curElem.id) ?
                                                                <div className='select-tile-style' style={{ zIndex: 9999 }}>
                                                                    <GiCheckMark />
                                                                </div> : ""}
                                                        </div>
                                                        <p style={{fontSize : 12, fontWeight : "bold", marginTop : 5}}>{curElem.title}</p>
                                                    </li>
                                                )
                                            })
                                            :
                                            <div className='text-center mt-3' style={{ width: "100%" }}>
                                                <span>
                                                    You do not have any social account connected for this client. Please connect social account for this client to continue!
                                                </span>
                                            </div>
                                        }
                                    </ul>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane className={active.forth ? 'show active' : ""}>
                                <div className='row pt-3'>
                                    <div className='col-sm-6 col-lg-6'>
                                        <div className='common-input-wrap mt-0'>
                                            <label htmlFor="">Hours</label>
                                            <input
                                                type='number'
                                                className='common-input'
                                                min={1}
                                                max={24}
                                                name='hours'
                                                value={scheduleStream.hours}
                                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-sm-6 col-lg-6'>
                                        <div className='common-input-wrap mt-0'>
                                            <label htmlFor="">Minutes</label>
                                            <input
                                                type='number'
                                                className='common-input'
                                                min={0}
                                                max={59}
                                                name='minutes'
                                                value={scheduleStream.minutes}
                                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Timezone</label>
                                    <TimezoneSelect
                                        value={scheduleStream.timezone}
                                        onChange={(e) => handleChange("timezone", e.value)}
                                        className='common-input'
                                    />
                                    {/* <input type="text" value="Image" /> */}
                                </div>
                                <div className="alert alert-primary mt-2" role="alert">
                                    Hit "repurpose" to have AiSocials repost the content monthly without having to reschedule again. AiSocials will select your best content from that particular content stream to post each time without scheduling again.
                                </div>
                                <div className='inp-wrap mt-2'>
                                    <label htmlFor='re_purpose' className='text-capitalize'>Repurpose</label>
                                    <label className="switch">
                                        <input
                                            className='input solid'
                                            name="re_purpose"
                                            type="checkbox"
                                            id="re_purpose"
                                            checked={+scheduleStream.re_purpose === 0 ? false : true}
                                            onChange={(e) => handleChange("re_purpose", e.target.checked ? 1 : 0)}
                                        />
                                        <span className="sliderN round"></span>
                                    </label>

                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <button className='site-link bdr' onClick={active.first ? props.handleClose : handleBack}><span>{active.first ? "Cancel" : "Back"}</span></button>
                <button className='site-link' onClick={handleSubmit}><span>{active.forth ? loader ? <>Scheduling <i className="fa fa-spinner fa-spin mx-1" /></> : "Schedule" : "Next"}</span></button>
            </Modal.Footer>
        </Modal>
    )
}

export default ScheduleModal