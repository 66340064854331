import React from 'react'
import { Modal } from 'react-bootstrap'
import { HiArrowSmUp } from "react-icons/hi"
const SweetAlert = (props) => {

    const style1 = {
        width: "116px",
        height: "85px",
        background: "#e2dfdf73",
        borderRadius: "19px",
        position: "relative"
    }

    const style2 = {
        position: "absolute",
        top: "-55%",
        left: "12%",
        fontSize: "90px",
        color: "#5680b5"
    }


    return (
        <Modal className='theme-modal xl' show={props.show} onHide={props.handleClose} centered scrollable>
            <Modal.Body>
                <div className='info-tab'>
                    <div className="col-12 text-center" style={{marginBottom:50}}>
                        <div className="upload_wrapper mt-2 d-flex justify-content-center " style={{ width: '100%' }}>
                            <div className="box_wrap" style={style1}>
                                <HiArrowSmUp className="arrow" style={style2} />
                            </div>

                        </div>
                    </div>
                    <p className='text-center mt-2' style={{ color: "black", fontSize: 16, fontWeight: 'bold' }}>{props.message}</p>
                </div>
                <div className='row mt-5'>
                    <div className='col-6'>
                        <button
                            className='site-link full bdr'
                            onClick={props.handleClose}
                            style={{
                                background: props.cancelButtonColor
                            }}
                        >
                            <span>{props.cancelButtonName}</span>
                        </button>
                    </div>
                    <div className='col-6'>
                        <button
                            className='site-link full'
                            onClick={props.performDelete}
                            style={{
                                background: props.confirmButtonColor
                            }}
                        >
                            <span>{props.confirmButtonName}</span>
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default SweetAlert