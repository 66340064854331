import React, { useEffect, useState } from 'react';
import TitleBar from '../../title-bar/TitleBar';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import MemberRows from './MemberRows';
import { useDispatch } from 'react-redux';
import { addAccData } from '../../../Redux/Actions/AccountManageAction';
import { fetchData } from '../../../Redux/Actions/CommonActions';

function AccountManagement() {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        create: false,
        fetch: false
    })
    const [teamMember, setTeamMember] = useState([])
    const [user, setUser] = useState({
        type: 'team',
        email: '',
        name: '',
        password: ''
    })
    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setUser({
            ...user,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{6,}$/;
        if (user.password) {
            if (pattern.test(user.password)) {
                setLoader({
                    ...loader,
                    create: true
                });
                dispatch(addAccData(user, fetchMembers, loader, setLoader, setUser))
                setPasswordMsg({ ...passwordMsg, validation: false, msg: "" })
            } else {
                setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.", validation: true })
            }
        }
    }

    const fetchMembers = () => {
        let data = {}
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchData("fetch-team-account", data, setTeamMember, loader, setLoader))
    }

    useEffect(() => {
        fetchMembers()
    }, [])

    return (
        <>
            <TitleBar title="Account Management" />
            <Header />

            <section className='page-wrap'>
                <div className='container'>
                    <h1 className='page-title'>Add Account</h1>

                    <div className='cont-box'>
                        <h3>Personal Info</h3>
                        <form className='row' onSubmit={handleSubmit}>
                            <div className='col-sm-6'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Account Type</label>
                                    <input
                                        className='common-input'
                                        type="text"
                                        name='type'
                                        value={"Team"}
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Account Name</label>
                                    <input
                                        className='common-input'
                                        type="text"
                                        name='name'
                                        value={user.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Email</label>
                                    <input
                                        className='common-input'
                                        type="email"
                                        value={user.email}
                                        onChange={handleChange}
                                        name='email'
                                        required
                                    />
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Password</label>
                                    <input
                                        className='common-input'
                                        type="password"
                                        name='password'
                                        value={user.password}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <p style={{ fontSize: 13, margin: "5px", color: "#DC143C" }}>{passwordMsg.validation ? passwordMsg.msg : ""}</p>
                            </div>
                            <div className='client-btn text-end'>
                                <button type='submit' className='site-link'><span>{loader.create ? <>Creating <i className="fa fa-spinner fa-spin mx-1" /></> : "Create"}</span></button>
                            </div>
                        </form>
                    </div>


                    <h2 className='pt-5'>Team Members Management</h2>
                    <div className='cont-box'>
                        <div className='table-responsive'>
                            <table className='table theme-table'>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email Address</th>
                                        <th>Created</th>
                                        <th className='text-center'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {teamMember.length > 0 ?
                                        teamMember.map((curElem, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <MemberRows
                                                        curElem={curElem}
                                                        fetchMembers={fetchMembers}
                                                    />
                                                </React.Fragment>
                                            )
                                        })
                                        :
                                        <tr className='text-center'>
                                            <td colSpan={4}>
                                                {
                                                    loader.fetch ?
                                                        <i className="fa fa-spinner fa-spin" style={{ fontSize: 20, color: "#618fca" }} />
                                                        : "You have not created any team members yet!"
                                                }
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )

}

export default AccountManagement;