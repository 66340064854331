import React, { useState } from 'react'
import { AiOutlineDelete } from 'react-icons/ai'
import { FiEdit } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { onDeleteCampaign } from '../../../Redux/Actions/CommonActions'
import SweetAlert from '../../CommonComponent/SweetAlert'

const ContentStreamRows = (props) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
    })

    const handleDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
            id: props.id
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete"
        })
    }

    const performDelete = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting..."
        })
        dispatch(onDeleteCampaign("delete-content-stream", data, props.fetchData, setSweet))
    }
    return (
        <tr>
            <td>{props.index + 1}</td>
            <td>
                <span className='thumb-image'><img alt='' src={props.image} /></span>
            </td>
            <td>{props.type[0].toUpperCase() + props.type.slice(1)}</td>
            <td>{props.title.length > 20 ? props.title.slice(0, 20) + '...' : props.title}</td>
            <td>{props.description.length > 50 ? props.description.slice(0, 50) + '...' : props.description}</td>
            <td>{props.created}</td>
            <td>
                {
                    props.tags.split(',').map((value) => (
                        <span className='table-tag me-1'>  {value.trim()}</span>
                    ))
                }
            </td>
            <td className='text-end'>
                {+auth.user.is_client_account === 1 ? "" :
                    <>
                        <span className='smBtn' onClick={handleDelete}><AiOutlineDelete /></span>
                        <Link className='smBtn edit' to={`/content-stream/edit-stream?id=${props.id}`}><FiEdit /></Link>

                    </>}
            </td>

            <SweetAlert
                show={sweet.enable}
                message={"Are you sure you want to delete it?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
            />
        </tr>
    )
}

export default ContentStreamRows