import React, { useEffect, useState } from 'react';
import TitleBar from '../../title-bar/TitleBar';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { changeProfile, onUpdatePassword, onUpdateUser } from '../../../Redux/Actions/AuthActions';
import Swal from 'sweetalert2';
import { BsPlusLg } from 'react-icons/bs'

const MyAccount = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [loader, setLoader] = useState({
        user: false,
        password: false,
        image: false
    })
    const [membershipName, setMembershipName] = useState("")
    const [user, setUser] = useState({
        name: "",
        email: "",
    })

    const [password, setPassword] = useState({
        password: '',
        new_password: '',
        confirm_password: '',
    })

    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    })


    const handleChange = (e) => {
        const { name, value } = e.target
        setUser({
            ...user,
            [name]: value
        })
    }

    const handlePassword = (e) => {
        const { name, value } = e.target
        setPassword({
            ...password,
            [name]: value
        })
    }


    const onImageChange = (e) => {
        if (e.target.files[0].type === 'image/png' || e.target.files[0].type === 'image/jpg' || e.target.files[0].type === 'image/jpeg') {
            if (e.target.files[0].size < 5000000) {
                const formData = new FormData()
                formData.append('user_id', auth.user.id);
                formData.append('file', e.target.files[0]);
                setLoader({
                    ...loader,
                    image: true
                })
                dispatch(changeProfile(formData, loader, setLoader))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Max allowed size is 5MB!',
                    confirmButtonColor: "#618FCA"
                })
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You have selected invalid file type!',
                confirmButtonColor: "#618FCA"
            })
        }
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            user: true
        })
        delete user.email
        dispatch(onUpdateUser(user, loader, setLoader))
    }
    const updatePassword = (e) => {
        e.preventDefault()
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{6,}$/;
        if (password.new_password) {
            if (pattern.test(password.new_password)) {
                if (password.new_password === password.confirm_password) {
                    setLoader({
                        ...loader,
                        password: true
                    })
                    dispatch(onUpdatePassword(password, loader, setLoader))
                } else {
                    setPasswordMsg({ ...passwordMsg, msg: "Password is not matched!", validation: true })
                }

            } else {
                setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.", validation: true })
            }
        }
    }

    useEffect(() => {
        if (auth.user) {
            setUser({
                ...user,
                name: auth.user.name,
                email: auth.user.email,
            })
            if(auth.user.membership){
                let strMem = auth.user.membership.split("__")[auth.user.membership.split("__").length - 1]
                if(strMem === "premium"){
                    strMem = "elite"
                }
                setMembershipName(strMem)
            }
        }
    }, [auth])

    return (
        <>
            <TitleBar title="My Account" />
            <Header />
            <section className='page-wrap'>
                <div className='container'>
                    <h1 className='page-title'>My Account</h1>

                    <div className='cont-box'>
                        <div className='profilePic'>
                            {loader.image ?
                                <div className='profileLoader'>
                                    <i className="fa fa-spinner fa-spin mx-1" style={{ fontSize: "23px", color: "#618fca" }} />
                                </div>
                                :
                                <>
                                    <img alt={auth.user.name} src={auth.user.profile} />
                                    <div className='profilePic-up '>
                                        <BsPlusLg />
                                        <input
                                            type='file'
                                            className="custom-file-input"
                                            onChange={(e) => onImageChange(e)}
                                        />
                                    </div>

                                </>
                            }
                        </div>
                        <h3 className='text-center pt-3'>{auth.user.name}</h3>

                        <div className='text-center mt-4'><a className='site-link text-capitalize'><span>{membershipName}</span></a></div>

                        <div className='row'>
                            <form className='col-sm-12' onSubmit={handleSubmit}>
                                <h3 className='pt-5'>Personal Info</h3>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Name</label>
                                    <input
                                        className='common-input'
                                        type="text"
                                        name="name"
                                        value={user.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Email</label>
                                    <input
                                        className='common-input'
                                        type="email"
                                        name='email'
                                        value={user.email}
                                        onChange={handleChange}
                                        readOnly
                                    />
                                </div>

                                <div className='client-btn text-end'>
                                    <button type='submit' className='site-link'><span>{loader.user ? <>Updating <i className="fa fa-spinner fa-spin mx-1" /></> : "Update"}</span></button>
                                </div>
                            </form>
                            <form className='col-sm-12' onSubmit={updatePassword}>
                                <h3 className='pt-5'>Change Password</h3>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Current Password</label>
                                    <input
                                        className='common-input'
                                        type="password"
                                        name='password'
                                        onChange={handlePassword}
                                        required
                                    />
                                </div>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">New Password</label>
                                    <input
                                        className='common-input'
                                        type="password"
                                        name='new_password'
                                        onChange={handlePassword}
                                        required
                                    />
                                </div>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Confirm Password</label>
                                    <input
                                        className='common-input'
                                        type="password"
                                        name='confirm_password'
                                        onChange={handlePassword}
                                        required
                                    />
                                    {passwordMsg.validation ?
                                        <p style={{ fontSize: 13, marginLeft: 5, color: "#DC143C" }}>{passwordMsg.msg}</p> : ""}
                                </div>
                                <div className='client-btn text-end'>
                                    <button className='site-link' type='submit'><span>{loader.password ? <>Updating <i className="fa fa-spinner fa-spin mx-1" /></> : "Update"}</span></button>
                                </div>
                            </form>
                        </div>



                    </div>

                </div>
            </section>

            <Footer></Footer>
        </>
    )

}

export default MyAccount;