import React, { useEffect, useState } from 'react';
import TitleBar from '../../title-bar/TitleBar';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import { Link } from 'react-router-dom';
import { fetchData } from '../../../Redux/Actions/CommonActions';
import { useDispatch } from 'react-redux';
import VideoCard from '../../CommonComponent/VideoCard';
import ImageCard from '../../CommonComponent/ImageCard';
import { onFetchStreamList } from '../../../Redux/Actions/MyContentAction';

const MyContent = () => {
    const dispatch = useDispatch()
    const [images, setImages] = useState([])
    const [quotes, setQuotes] = useState([])
    const [texts, setText] = useState([])
    const [memes, setMemes] = useState([])
    const [videos, setVideos] = useState([])
    const [loader, setLoader] = useState({
        fetch: false
    })
    const [contentStream, setContentStream] = useState([]);


    const fetchImages = () => {
        let data = {}
        dispatch(fetchData("fetch-image-campaign", data, setImages))
    }

    const fetchQuotes = () => {
        let data = {}
        dispatch(fetchData("fetch-quotes-campaign", data, setQuotes))
    }
    const fetchText = () => {
        let data = {}
        dispatch(fetchData("fetch-text-campaign", data, setText))
    }

    const fetchMemes = () => {
        let data = {}
        dispatch(fetchData("fetch-memes-campaign", data, setMemes))
    }

    const fetchVideos = () => {
        let data = {}
        dispatch(fetchData("fetch-video-campaign", data, setVideos, loader, setLoader))
    }

    useEffect(() => {
        setLoader({
            ...loader,
            fetch: true
        })
        fetchImages()
        fetchVideos()
        fetchQuotes()
        fetchMemes()
        fetchText()
    }, [])

    const fetchList = () => {
        let data = {}
        dispatch(onFetchStreamList(data, setContentStream))
    }

    useEffect(() => {
        fetchList()
    }, [])

    return (
        <>
            <TitleBar title="My Content" />
            <Header />

            <section className='page-wrap'>

                <div className='container'>
                    <h1 className='page-title'>Created Videos</h1>


                    <section className='recent-video mt-0'>
                        <div className='video-listing'>
                            <div className='row'>
                                {videos.length > 0 ?
                                    videos.slice(0, 6).map((curElem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <VideoCard
                                                    curElem={curElem}
                                                    fetchVideos={fetchVideos}
                                                    contentStream={contentStream}
                                                />
                                            </React.Fragment>
                                        )
                                    })
                                    :
                                    <div className='fetch-loader-block'>
                                        {loader.fetch ?
                                            <i className="fa fa-spinner fa-spin fetch-loader" />
                                            :
                                            <span style={{ fontSize: 15 }}>
                                                You have not created any videos yet!
                                            </span>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        {videos.length > 0 ?
                            <div className='load-more'>
                                <Link to={'/my-content/content?type=videos'} className='site-link cursor-pointer' ><span>View All</span></Link>
                            </div> : ""}
                    </section>

                    <section className='recent-video pb-5 mb-5'>
                        <div className='sec-intro mw-100 text-start'>
                            <h2>Created Posts</h2>
                        </div>


                        <div className='video-listing'>
                            <div className='row'>
                                {images.length > 0 ?
                                    images.slice(0, 6).map((curElem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <ImageCard
                                                    curElem={curElem}
                                                    fetchImages={fetchImages}
                                                    type="image"
                                                    contentStream={contentStream}
                                                />
                                            </React.Fragment>
                                        )
                                    })
                                    :
                                    <div className='fetch-loader-block'>
                                        {loader.fetch ?
                                            <i className="fa fa-spinner fa-spin fetch-loader" />
                                            :
                                            <span style={{ fontSize: 15, color: "#00000" }}>
                                                You have not created any post yet!
                                            </span>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        {images.length > 0 ?
                            <div className='load-more'>
                                <Link to={'/my-content/content?type=images'} className='site-link cursor-pointer' ><span>View All</span></Link>
                            </div> : ""}
                    </section>

                    <section className='recent-video pb-5 mb-5'>
                        <div className='sec-intro mw-100 text-start'>
                            <h2>Created Texts</h2>
                        </div>


                        <div className='video-listing'>
                            <div className='row'>
                                {texts.length > 0 ?
                                    texts.slice(0, 6).map((curElem, index) => {
                                        console.log()
                                        return (
                                            <React.Fragment key={index}>
                                                <ImageCard
                                                    curElem={curElem}
                                                    fetchImages={fetchText}
                                                    type="text"
                                                    contentStream={contentStream}
                                                />
                                            </React.Fragment>
                                        )
                                    })
                                    :
                                    <div className='fetch-loader-block'>
                                        {loader.fetch ?
                                            <i className="fa fa-spinner fa-spin fetch-loader" />
                                            :
                                            <span style={{ fontSize: 15, color: "#00000" }}>
                                                You have not created any text yet!
                                            </span>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        {texts.length > 0 ?
                            <div className='load-more'>
                                <Link to={'/my-content/content?type=text'} className='site-link cursor-pointer' ><span>View All</span></Link>
                            </div> : ""}
                    </section>


                    <section className='recent-video pb-5 mb-5'>
                        <div className='sec-intro mw-100 text-start'>
                            <h2>Created Quotes</h2>
                        </div>


                        <div className='video-listing'>
                            <div className='row'>
                                {quotes.length > 0 ?
                                    quotes.slice(0, 6).map((curElem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <ImageCard
                                                    curElem={curElem}
                                                    fetchImages={fetchQuotes}
                                                    type="image"
                                                    contentStream={contentStream}
                                                />
                                            </React.Fragment>
                                        )
                                    })
                                    :
                                    <div className='fetch-loader-block'>
                                        {loader.fetch ?
                                            <i className="fa fa-spinner fa-spin fetch-loader" />
                                            :
                                            <span style={{ fontSize: 15, color: "#00000" }}>
                                                You have not created any quotes yet!
                                            </span>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        {quotes.length > 0 ?
                            <div className='load-more'>
                                <Link to={'/my-content/content?type=quotes'} className='site-link cursor-pointer' ><span>View All</span></Link>
                            </div> : ""}
                    </section>

                    <section className='recent-video pb-5 mb-5'>
                        <div className='sec-intro mw-100 text-start'>
                            <h2> Created Memes</h2>
                        </div>

                        <div className='video-listing'>
                            <div className='row'>
                                {memes.length > 0 ?
                                    memes.slice(0, 6).map((curElem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <ImageCard
                                                    curElem={curElem}
                                                    fetchImages={fetchMemes}
                                                    type="image"
                                                    contentStream={contentStream}
                                                />
                                            </React.Fragment>
                                        )
                                    })
                                    :
                                    <div className='fetch-loader-block'>
                                        {loader.fetch ?
                                            <i className="fa fa-spinner fa-spin fetch-loader" />
                                            :
                                            <span style={{ fontSize: 15, color: "#00000" }}>
                                                You have not created any memes yet!
                                            </span>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        {memes.length > 0 ?
                            <div className='load-more'>
                                <Link to={'/my-content/content?type=memes'} className='site-link cursor-pointer' ><span>View All</span></Link>
                            </div> : ""}
                    </section>

                </div>


            </section>

            <Footer />
        </>
    )

}

export default MyContent;